import React, { Component } from "react";
import "../Upload/dropezone/dropzone.css";

class Dropzone extends Component {
  constructor(props) {
    super(props);
    this.state = { hightlight: false };
    this.fileInputRef = React.createRef();

    this.openFileDialog = this.openFileDialog.bind(this);
    this.onAddFiles = this.onAddFiles.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  openFileDialog() {
    this.fileInputRef.current.click();
  }

  async onAddFiles(e) {
    const files = e.target.files;
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files);
      await this.props.onFilesAdded(array);
    }
  }

  onDragOver(event) {
    event.preventDefault();
    this.setState({ hightlight: true });
  }

  onDragLeave(event) {
    this.setState({ hightlight: false });
  }

  async onDrop(event) {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files);
      await this.props.onFilesAdded(array);
      // this.props.uploadFiles()
    }
    this.setState({ hightlight: false });
  }

  fileListToArray(list) {
    const array = [];
    for (let i = 0; i < list.length; i++) {
      array.push(list.item(i));
    }
    return array;
  }



  render() {
    return (
      <div
        className={`dropZone ${this.state.hightlight ? "Highlight" : ""}`}
        onDragOver={this.onDragOver}
        onDragLeave={this.onDragLeave}
        onDrop={this.onDrop}
        onClick={this.openFileDialog}
        style={{ cursor: this.props.disabled ? "default" : "pointer" }}
      >
        <span className="">
          <img alt='upload' className='Icon' src='/svg/baseline-cloud_upload-24px.svg' />
        </span>
        <p style={{ color: '#140046', fontWeight: '700' }} className='d-flex justify-content-center'>Drag & Drop or click to Upload Files</p>
        <input ref={this.fileInputRef} className='FileInput' type='file' multiple onChange={this.onAddFiles} />
        {/* <button className='btn btn-clear'>Or Select Files to Upload</button> */}
      </div >
    );
  }
}

export default Dropzone;
