import React, { useState, useEffect } from 'react';
import { Route, Switch, useLocation, Redirect } from 'react-router-dom';

// import Header from './layout/Header';
import HeaderSimple from './layout/HeaderSimple';
import Footer from './layout/Footer';
import config from '../config';
import axios from 'axios';
// import homeContainer from './layout/Home';

/* Funding Process Components Imports*/
import GenerateOfferContainer from './funding/generateOffer/GenerateOfferContainer';
import ContractReviewContainer from './funding/contractReview/ContractReviewContainer';
import SignContractContainer from './funding/signContract/SignContractContainer';
import VerifyContractContainer from './funding/verifyContract/VerifyContractContainer';
import VerifyContractDoneComponent from './funding/verifyContract/VerifyContractDoneComponent';
import BankVerificationContainer from './funding/bankVerification/BankVerificationContainer';
import BankVerificationContainerbyOpp from './funding/bankVerificationbyOpp/BankVerificationContainer';
import UploadDocsContainer from './funding/uploadDocs/UploadDocsContainer';
import UploadDocsContainerbyOpp from './funding/uploadDocsbyOpp/UploadDocsContainer';
import ProcessDoneComponent from './funding/common/ProcessDoneComponent';


/* Onboarding-Application Process Components Imports*/
import MerchantProvider from "./onboarding/application/context/MerchantStateContext";
import Funding from "./onboarding/application/components/Funding";
import BusinessType from "./onboarding/application/components/BusinessType";
import BusinessLocation from "./onboarding/application/components/BusinessLocation";
import Personal from "./onboarding/application/components/Personal";
import PersonalInformation from "./onboarding/application/components/PersonalInformation";
import Esign from "./onboarding/application/components/Esign/Esign";
// import ReviewSignature from "./onboarding/application/components/ReviewSignature";
import ReviewSignature from './onboarding/application/components/signatureReview/ReviewSignature ';
import Upload from "./onboarding/application/components/Upload/Upload";
import AppAdditions from "./onboarding/application/components/AppAdditions";
import GenericUpload from "./onboarding/application/components/GenericUploadComponent/GenericUpload";
import GenericUploadbyOpp from "./onboarding/application/components/GenericUploadComponentbyOpp/GenericUpload";
import ThankYou from "./onboarding/application/components/ThankYou";
import LinkExpired from "./onboarding/application/components/LinkExpired";
import PageNotFound from "./onboarding/application/components/404Page";
import Home from './Home';

/* Onboarding-Syndicator Process Components Imports*/
import StateContextProvider from './onboarding/syndicator/context/StateContext'
import Account from './onboarding/syndicator/components/Account';
import Contact from './onboarding/syndicator/components/Contact';
import SThankYou from './onboarding/syndicator/components/ThankYou';

/* StandAlone Signature Process */
import SignatureStateContext from './funding/documentSignature/documentSignatureContext';
import ContractContainer from './funding/documentSignature/components/ContractContainer';
import ContractCancelledComponent from './funding/documentSignature/components/ContractCancelledComponent';
import ContractSuccessComponent from './funding/documentSignature/components/ContractSuccessComponent';


import './App.css';
import SubmissionDone from './onboarding/application/components/SubmissionDone';

import TagManager from 'react-gtm-module';

import PickVariation from './onboarding/application/components/PickVariation';

import RedirectComponent from './redirect/redirect';

export default function App(props) {
	const location = useLocation();
	const [internalIp, setInternalIp] = useState();
	const [showHeader, setShowHeader] = useState();
	const pathNameArray = ['/onboarding/merchant/landing'];

	useEffect(() => {
		if (pathNameArray.includes(location.pathname)) {
			setShowHeader(false)
		} else {
			setShowHeader(true)
		};
	}, [pathNameArray, location.pathname]);

	// -------- GTM -------------------------------------
	useEffect(() => { checkInternalIp() }, []);

	const checkInternalIp = () => {
		axios.defaults.withCredentials = true;
		axios.get(`${config.apiUrl}/api/uplyftOnboarding/checkHost`)
			.then(res => {
				if (res.data.error) {
					console.error(res.data.error);
				} else {
					setInternalIp(res.data.isInternalIp);
				}
			});
	};

	setTimeout(() => {
		if (internalIp === false) {
			const tagManagerArgs = {
				gtmId: 'GTM-M7PQSTF',
				dataLayer: {
					userId: '001',
					userProject: 'project'
				}
			}
			TagManager.initialize(tagManagerArgs)
		};
	}, 1500);
	// ---------------------------------------------------

	return (
		<>
			<div className='content'>
				{showHeader && <HeaderSimple />}
				<Switch>

					<Route exact path="/" component={Home} />
					{/* /internal => create FB custom audience based on internal traffic, than exclude them from ads */}
					<Route exact path="/funding/generate-offer/:submissionUuid" component={GenerateOfferContainer} />
					<Route exact path="/funding/contract-review/:submissionUuid/:contactUuid" component={ContractReviewContainer} />
					<Route exact path="/funding/sign-contract/:submissionUuid/:contactUuid" component={SignContractContainer} />
					<Route exact path="/funding/verify-contract/:submissionUuid/:verifierUuid" component={VerifyContractContainer} />
					<Route exact path="/funding/verify-contract-done/:submissionUuid/:verifierUuid" component={VerifyContractDoneComponent} />
					<Route exact path="/funding/bank-verification/:submissionUuid/:contactUuid" component={BankVerificationContainer} />
					<Route exact path="/funding/bank-verification-1/:opportunityUuid/:contactUuid" component={BankVerificationContainerbyOpp} />
					<Route exact path="/funding/upload-docs/:submissionUuid/:contactUuid" component={UploadDocsContainer} />
					<Route exact path="/funding/upload-docs-1/:opportunityUuid" component={UploadDocsContainerbyOpp} />
					<Route exact path='/funding/upload-docs-2/:opportunityUuid' render={props => <GenericUploadbyOpp {...props} />} />
					<Route exact path="/funding/process-done" component={ProcessDoneComponent} />

					{/* Onboarding Process */}
					<Route exact path='/onboarding/merchant/:accountUuid/upload/:contactUuid/:opportunityUuid' render={props => <Upload {...props} />} />
					<Route exact path='/onboarding/merchant/:accountUuid/genericUpload/:contactUuid/:opportunityUuid' render={props => <GenericUpload {...props} />} />
					<Route exact path='/onboarding/merchant/finished' render={props => <ThankYou {...props} />} />
					<Route exact path='/onboarding/merchant/expiredlink' render={props => <LinkExpired {...props} />} />
					<Route exact path="/onboarding/merchant/process-done" render={props => <SubmissionDone {...props} />} />
					<Route exact path='/onboarding/merchant/:accountUuid/reviewsignature/:contactUuid/:opportunityUuid' render={props => <ReviewSignature {...props} internalIp={internalIp} />} />

					<Route exact path='/onboarding/merchant/' render={props => <MerchantProvider><Funding {...props} /></MerchantProvider>} />
					<Route exact path='/onboarding/merchant/landing' render={() => <MerchantProvider><PickVariation {...props} /></MerchantProvider>} />
					<Route exact path='/onboarding/merchant/business' render={props => <MerchantProvider><BusinessType {...props} /></MerchantProvider>} />
					<Route exact path='/onboarding/merchant/address' render={props => <MerchantProvider><BusinessLocation {...props} /></MerchantProvider>} />
					<Route exact path='/onboarding/merchant/personal' render={props => <MerchantProvider><Personal {...props} /></MerchantProvider>} />
					<Route exact path='/onboarding/merchant/:accountUuid/personalinfo/:contactUuid/:opportunityUuid' render={props => <MerchantProvider><PersonalInformation {...props} /></MerchantProvider>} />
					<Route exact path='/onboarding/merchant/:accountUuid/esign/:contactUuid/:opportunityUuid' render={props => <MerchantProvider><Esign {...props} /></MerchantProvider>} />
					<Route exact path='/onboarding/merchant/:accountUuid/additions/:contactUuid/:opportunityUuid' render={props => <MerchantProvider><AppAdditions {...props} /></MerchantProvider>} />

					<Route exact path='/onboarding/syndicator/contact' render={props => <StateContextProvider><Contact  {...props} /></StateContextProvider>} />
					<Route exact path='/onboarding/syndicator/account' render={props => <StateContextProvider><Account  {...props} /></StateContextProvider>} />
					<Route exact path='/onboarding/syndicator/thank-you' render={props => <StateContextProvider><SThankYou {...props} /></StateContextProvider>} />

					{/* Signature Process */}
					{/* <Route exact path='/sign/test/' render={props => <SignatureStateContext><Funding {...props} /></SignatureStateContext>} /> */}
					<Route exact path='/sign/test/' render={props => <SignatureStateContext><ContractContainer {...props} /></SignatureStateContext>} />
					<Route exact path='/sign/document/:documentUuid/:signerUuid' render={props => <SignatureStateContext><ContractContainer {...props} /></SignatureStateContext>} />
					<Route exact path='/sign/document/success' component={ContractSuccessComponent} />
					<Route exact path='/sign/document/cancelled' component={ContractCancelledComponent} />
					{/* Passing :submissionUuid allows us to get all data, included Opp and Sub -> ACH fee */}
					{/* <Route exact path='/sign/test/:contactUuid/:submissionUuid' render={props => <SignatureStateContext><ContractContainer {...props} /></SignatureStateContext>} /> */}

					<Route exact path='/redirect' component={RedirectComponent} />
					<Route exact path='/404' render={() => <PageNotFound />} />
					<Route render={() => <Redirect to='/404' />} />

				</Switch>
			</div>
			<MerchantProvider><Footer  {...props} /></MerchantProvider>
		</>
	);
} 