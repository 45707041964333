import { createReducer } from 'reduxsauce'
import Types from './types'

// the initial state of this reducer
export const INITIAL_STATE = {
	allRequiredDocsUploaded: false 
	, requiredDocs: []
	, submissionId: ''
    , account: {}
	, opportunity: {}
}

// Get Initial Data
export const getUploadDocsInitialData = (state = INITIAL_STATE, action) => {
    return {
        ...state
        , submissionId: action.initialData.submissionId
        , account: action.initialData.account
        , opportunity: action.initialData.opportunity
    }
}

// Initialize Required Docs
export const initializeRequiredDocs = (state = INITIAL_STATE, action) => {
    return {
        ...state
		, requiredDocs: action.requiredDocs.requiredDocs
    }
}

// Update Required Docs State
export const updateRequiredDocsState = (state = INITIAL_STATE, action) => {
    let actionType = action.requiredDocsData.actionType;
    let docKey = action.requiredDocsData.docKey;
    let requiredDocs = state.requiredDocs;
    
    let updatedRequiredDocs = requiredDocs.map( requiredDoc => {
        let standardName = requiredDoc.name.replace(/[^\w\\.]+/g, "-");
        if(docKey.indexOf(standardName) !== -1){
            switch (actionType){
                case 'loaded': 
                    requiredDoc.uploaded = 1;
                    break;
                case 'removed':
                    requiredDoc.uploaded = 0;
                    break;
                default: console.log('No actionType chosen') ;
            }
        }
        return requiredDoc;
    });
    let allRequiredDocsUploaded = updatedRequiredDocs.reduce((sum, requiredDoc) => sum + requiredDoc.uploaded, 0) === updatedRequiredDocs.length;
    return {
        ...state
        , requiredDocs: updatedRequiredDocs
        , allRequiredDocsUploaded: allRequiredDocsUploaded
    }
}

// map our action types to our reducer functions
export const HANDLERS = {
    [Types.GET_UPLOAD_DOCS_INITIAL_DATA]: getUploadDocsInitialData,
    [Types.INITIALIZE_REQUIRED_DOCS]: initializeRequiredDocs,
    [Types.UPDATE_REQUIRED_DOCS_STATE]: updateRequiredDocsState,
}
  
export default createReducer(INITIAL_STATE, HANDLERS)
