import { createReducer } from 'reduxsauce'
import Types from './types'

// The initial state of this reducer
export const INITIAL_STATE = {
	contactSigningStatus: {}
    , contactsSigningStatus: []
}

// Prepare Contract
export const prepareContract = (state = INITIAL_STATE, action) => {
    return {
        ...state
        , contactSigningStatus: action.statuses.contactSigningStatus
        , contactsSigningStatus: action.statuses.contactsSigningStatus
    }
}

// On Signing Event
export const onSigningEvent = (state = INITIAL_STATE, action) => {
    return {
        ...state
        , contactSigningStatus: action.contactsSigningStatus.contactSigningStatus
        , contactsSigningStatus: action.contactsSigningStatus.contactsSigningStatus
    }
}

// On Signing Event
export const handleSignContractError = (state = INITIAL_STATE, action) => {
    let contactSigningStatusUpdated = state.contactSigningStatus;
    contactSigningStatusUpdated.eventListener = '';
    return {
        ...state
        , contactSigningStatus: contactSigningStatusUpdated
    }
}

// map our action types to our reducer functions
export const HANDLERS = {
    [Types.PREPARE_CONTRACT]: prepareContract,
    [Types.ON_SIGNING_EVENT]: onSigningEvent,
    [Types.HANDLE_SIGN_CONTRACT_ERROR]: handleSignContractError,
  }
  
  export default createReducer(INITIAL_STATE, HANDLERS)