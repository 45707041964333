// To Currency Format
export const toCurrencyFormat = (num) => {
    if (num === undefined || num === '') {
        return '';
    } else {
        let result = (parseFloat(num)).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
        });
        return result;
    }
}

export const currencyFormatReverse = (num) => {
    if (num === undefined || num === '') {
        return '';
    } else {
        return parseFloat(num.toString().replace(/(,)|(\$)/g,""));
    }
}

// this takes a string witht the Lang found in the state, and simply returns true
export const returnTrueIsSpanishIsPrefered = ( stateVar, original, translation ) => {
   if ( stateVar === 'Spanish' ){
       return true
   }else{
       return false
   }
}
// this takes a string witht the Lang found in the state, and 2 strings 
//one for the original english copy and one for the translation to spanish
export const translateIfPreferedLanguageIsSpanish = ( stateVar, original, translation ) => {
   if ( stateVar === 'Spanish' ){
       return translation || ''
   }else{
       return original || ''
   }
}
