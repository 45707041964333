import React, { useState, useEffect, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import Select from 'react-select';
import Layout from "../LandingLayout";
import Cleave from 'cleave.js/react';
import { MerchantStateContext } from '../../application/context/MerchantStateContext';
//npm pack => https://www.npmjs.com/package/query-string
import QueryString from 'query-string'
import PreApproval from "./PreApproval";
import PersonalLoansPartners from "./PersonalLoansPartners";

import { translateIfPreferedLanguageIsSpanish } from '../../../../lib/util';

export default function Landing({ variant = '' }) {
	const [overlay, setOverlay] = useState({ offer: false, spinner: true });
	const [disqualified, setDisqualified] = useState({ isDisqualified: false, spinner: true });
	const [formError, setFormError] = useState({});
	const { setMainState, setData, mainState, data, handleChange, prePopulateMainState, natureOfBusiness, populateCampaignVariation, populateCampaignRecordId, setLang, lang } = useContext(MerchantStateContext);
	const { opportunity__requestedAmount, opportunity__revenue, account__natureOfBusiness, contact__creditScoreRatio, account__startDate, contact__debt, opportunity__bankAccountType } = mainState
	const { is_spanish } = lang ;
	const location = useLocation();
	const history = useHistory();





	const referrerCampaign = (location) => {

		const params = QueryString.parse(location.search);
		if (variant) setData({ ...data, campaignVariation: variant })

		if (params) {
			// handleDataChange(params)

			populateCampaignVariation(params);
			populateCampaignRecordId(params);

			console.log(params)
			if (params.ac && params.co && params.op) {
				prePopulateMainState(params)
			} else if (params.campaign && params.irclickid) {
				console.log('c', params.campaign)
				console.log('cri', params.campaignRecordId)
				setData({ ...data, campaign: params.campaign,  campaignRecordId: params.irclickid })
			} else if (params.campaign && params.gclid) {
				setData({ ...data, campaign: params.campaign, campaignRecordId: params.gclid})
			} else if (params.campaign) {
				setData({ ...data, campaign: params.campaign  })
			}

			// setData({ ...data, campaign: params.campaign,  campaignRecordId: params.campaignRecordId })
		};

		// check for lang in params and update
		if(params.lang === 'es'){ 
			setLang({...lang, is_spanish: true})
			setMainState({
				contact__preferred__language: "Spanish"
			})
		}else{
			setMainState({
				contact__preferred__language: "English"
		   })
		}

		const campaignVariation = params.utm_campaignVariation || params.campaignVariation || variant;
		sessionStorage.setItem("data", JSON.stringify({ ...data, campaign: params.campaign, campaignVariation: campaignVariation, campaignRecordId: params.irclickid || params.gclid }));
	};

	useEffect(() => { referrerCampaign(location); }, [])

	useEffect(() => {
		if (sessionStorage['mainState']) {
			let tempState = JSON.parse(sessionStorage.getItem("mainState"));
			setMainState({
				...tempState
				, opportunity__requestedAmount: tempState.opportunity__requestedAmount
				, opportunity__revenue: tempState.opportunity__revenue
				, opportunity__purposeOfFunding: tempState.opportunity__purposeOfFunding
			})
		}

	}, [])


	useEffect(() => {

		if(sessionStorage['data']) {
			let tempData = JSON.parse(sessionStorage.getItem("data"));
			console.log('data', tempData)
			setData({
				...tempData
				, campaign: tempData.campaign
				, campaignId: tempData.campaignId
			})
		}
	}, [])
	const formValidation = (e) => {
		let form = e.target
		let isValid = form.checkValidity()
		let formData = new FormData(form)
		let validationMessages = Array.from(formData.keys()).reduce((acc, key) => {
			acc[key] = form.elements[key].validationMessage
			return acc
		}, {})
		setFormError(validationMessages)
		return isValid
	}

	const disqualifiedLead = () => {
		let isDisqualified = (clean(opportunity__revenue) < 10000 || opportunity__bankAccountType === 'Personal')
		// console.log('this lead is disqualified: ', isDisqualified)
		if(isDisqualified)
		{
			setDisqualified({ isDisqualified: true, spinner: true });
		}
		// return isQualified
	}

	const onInput = (e) => { setFormError({ ...formError, [e.target.name]: e.target.validationMessage }) }
	const onInputChange = ({ action }) => {
		switch (action) {
			case 'set-value':
				setFormError({ ...formError, 'account__natureOfBusiness': '' });
				return;
			default:
				return;
		};
	};

	const getError = (field) => formError[field];

	const clean = (str) => {
		str = parseFloat(str.replace(/\$|,/g, ''))
		return str
	}

	const clickSubmit = e => {
		e.preventDefault();


		// we first check for this partner to be a valid partner params, for example has a business bank account a rev of over 8000
		let today = new Date();
		let userDate = new Date(account__startDate);
		let isValid = formValidation(e);
		let isDisqualified = disqualifiedLead()
		if (isValid) {
			if ((account__startDate !== "" && userDate > today) || account__natureOfBusiness === '' || parseInt(account__startDate.substring(6, 10)) < 1900) {
				account__startDate !== "" && userDate > today && setFormError({ ...formError, 'account__startDate': 'Please enter valid date' });
				parseInt(account__startDate.substring(6, 10)) < 1900 && setFormError({ ...formError, 'account__startDate': 'Date can\'t be before 1900' });
				account__natureOfBusiness === '' && setFormError({ ...formError, 'account__natureOfBusiness': 'Please fill out the type of business you own' });

			} else {

				setOverlay({ offer: true, spinner: true });
			};
		}

	};

	const customStyles = {
		option: (provider) => ({
			...provider,
			borderStyle: 'none',
			fontSize: '0.8rem',
			color: '#140046'
		}),
		control: (provider) => ({
			...provider,
			borderStyle: 'none',
			borderColor: 'none',
			fontSize: '1.2rem',
		})
	};

	const theme = (theme) => ({
		...theme,
		colors: {
			...theme.colors,
			primary25: 'rgba(230, 227, 252, 1)',
			primary: '#6610f2',
		},
	});

	const debtArr = [
		{ name: 'None', value: 1.5 }
		, { name: 'Low', value: 1.25 }
		, { name: 'Medium', value: 1.00 }
		, { name: 'High', value: 0.75 }
	];
	const creditScoreArr = [
		{ name: 'Excellent', value: 1.5 }
		, { name: 'Good', value: 1.25 }
		, { name: 'Average', value: 1.00 }
		, { name: 'Poor', value: 0.75 }
	];
	const bankAccountType = [
		"Business",
		"Personal"
	];

	let langPref = mainState.contact__preferred__language  // check prefered language

	const fundingForm = () => (
		<div id='funding-LP' className='p-2'>
			<div className='row'>
				<div className='form-group col-12 col-lg-6'>

    
					<label>
					{translateIfPreferedLanguageIsSpanish(langPref, 'Desired Amount','Monto Deseado')}
					</label>
					<Cleave
						onInput={onInput}
						name='opportunity__requestedAmount'
						type='text'
						maxLength='10'
						placeholder='Amount ($5-$500k)'
						options={{ numeral: true, numeralThousandsGroupStyle: 'thousand', noImmediatePrefix: true, prefix: '$' }}
						onChange={handleChange("opportunity__requestedAmount")}
						className='form-control form-elegant text-muted'
						value={opportunity__requestedAmount}
						required
					/>
					<small className='text-danger'>{getError('opportunity__requestedAmount')}</small>
				</div>
				<div className='form-group col-12 col-lg-6'>

			
					<label>
					{translateIfPreferedLanguageIsSpanish(langPref, 'What does the business do?','Sector del Negocio')}
					</label>
					
					<Select
						name='account__natureOfBusiness'
						onInputChange={onInputChange}
						options={natureOfBusiness}
						theme={theme}
						value={{ label: account__natureOfBusiness }}
						styles={customStyles}
						className='form-elegant text-muted mt-2'
						onChange={handleChange("account__natureOfBusinessId")}
						placeholder="select an option"
					/>
					<small className='text-danger'>{getError('account__natureOfBusiness')}</small>
				</div>
			</div>
			<div className='row'>
				<div className='form-group col-12 col-lg-4'>
					<label>
						{translateIfPreferedLanguageIsSpanish(langPref, 'Business Start date','Fecha de Inicio')}
					</label>
					<Cleave
						onInput={onInput}
						onChange={handleChange("account__startDate")}
						options={{ date: true, delimiter: '/', datePattern: ['m', 'd', 'Y'] }}
						name='account__startDate'
						className='form-control form-elegant text-muted'
						placeholder='mm/dd/yyyy'
						value={account__startDate}
						required
					/>
					<small className='text-danger'>{getError('account__startDate')}</small>
				</div>
				<div className='form-group col-6 col-lg-4'>

					<label>
						{translateIfPreferedLanguageIsSpanish(langPref, 'Credit Score','Puntaje de Crédito')}
					</label>
					<select
						onChange={onInput}
						onInput={handleChange("contact__creditScoreRatio")}
						name='contact__creditScoreRatio'
						value={contact__creditScoreRatio}
						className='form-control form-elegant text-muted'
						required
					>
						<option value='' defaultValue className='text-muted'>
							{translateIfPreferedLanguageIsSpanish(langPref, 'Select','Seleccione')}
						</option>

						{creditScoreArr.map((score, i) => (
							<option key={i} value={score.value}>{score.name}</option>
						))}
					</select>
					<small className='text-danger'>{getError('contact__creditScoreRatio')}</small>
				</div>
				<div className='form-group col-6 col-lg-4'>
					<label>
						{translateIfPreferedLanguageIsSpanish(langPref, 'Business Debt','Deuda del Negocio')}
					</label>

					<select
						onChange={onInput}
						onInput={handleChange("contact__debt")}
						name='contact__debt'
						value={contact__debt}
						className='form-control form-elegant text-muted'
						required
					>
						<option value='' defaultValue className='text-muted'>
							{translateIfPreferedLanguageIsSpanish(langPref, 'Select','Seleccione')}
						</option>
						
						{debtArr.map((debt, i) => (
							<option key={i} value={debt.value}>{debt.name}</option>
						))}
					</select>
					<small className='text-danger'>{getError('contact__debt')}</small>
				</div>
			</div>
			<div className='row'>
				<div className='form-group col-6 col-lg-6'>
					<label>
						{translateIfPreferedLanguageIsSpanish(langPref, 'Bank Account Type','Tipo de Cuenta Bancaria?')}
					</label>
 					<select
						onChange={onInput}
						name='opportunity__bankAccountType'
						value={opportunity__bankAccountType}
						type='text'
						onInput={handleChange("opportunity__bankAccountType")}
						className='form-control form-elegant text-muted'
						required
					>
						<option value='' defaultValue className='text-muted'>
							{translateIfPreferedLanguageIsSpanish(langPref, 'Select','Seleccione')}
						</option>
						
						{bankAccountType.map((funding, i) => (
							<option key={i}>{funding}</option>
						))}
					</select>
					<small className='text-danger'>{getError('opportunity__bankAccountType')}</small>
				</div>
				{/* Ask for monthly revenue only if BAT is Business */}
				{mainState.opportunity__bankAccountType === 'Business' ?
					<div className='form-group col-12 col-lg-6'>
					
						<label className='pb-2'>
							{translateIfPreferedLanguageIsSpanish(langPref, 'Monthly $ Deposits','Depósitos mensuales $')}
						</label>
						<Cleave
							onInput={onInput}
							name='opportunity__revenue'
							placeholder='Monthly Revenue'
							maxLength='10'
							options={{ numeral: true, numeralThousandsGroupStyle: 'thousand', noImmediatePrefix: true, prefix: '$' }}
							onChange={handleChange("opportunity__revenue")}
							className='form-control form-elegant text-muted'
							value={opportunity__revenue}
							required
						/>
						<small className='text-danger'>{getError('opportunity__revenue')}</small>
					</div>
					: <div className='form-group col-12 col-lg-6'></div>
				}
			</div>
			<div className='row pt-3'>
				<div className='form-group col-12 col-lg-5 d-flex align-items-end'>
					<button id="btnPrimaryLanding" type="submit" className="btn btn-primary inside-form d-flex justify-content-center" >
							{translateIfPreferedLanguageIsSpanish(langPref, 'Check Eligibility','Verifique Elegibilidad')}
						<span className="mobile-none">
							<i className="fa fa-paper-plane btn-add-on"></i>
						</span>
					</button>
				</div>
			</div>
		</div>
	);

	const pickView = () => (
		disqualified.isDisqualified ? <PersonalLoansPartners />
		:overlay.offer ? <PreApproval />
		:fundingForm()
	);

	return (
		<Layout
			title=''
			htmlTitle='Funding'
			preRoute=''
			showPreButton='hidden'
			linkBtnDisplay='none'
			preRouteClass=''
			className=''
			invokeFunction={(e) => clickSubmit(e)}
			btnContinueId='fundingCBtn'
		>
			{pickView()}
		</Layout>
	);
}
