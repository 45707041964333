import {combineReducers} from 'redux';
import generateOfferReducer from './app/funding/generateOffer/duck/index';
import contractReviewReducer from './app/funding/contractReview/duck/index';
import signContractReducer from './app/funding/signContract/duck/index';
import verifyContractReducer from './app/funding/verifyContract/duck/index';
import bankVerificationReducer from './app/funding/bankVerification/duck/index';
import uploadDocsReducer from './app/funding/uploadDocs/duck/index';

export default combineReducers({
	generateOffer: generateOfferReducer
	, contractReview: contractReviewReducer
	, signContract: signContractReducer
	, verifyContract: verifyContractReducer
	, bankVerification: bankVerificationReducer
	, uploadDocs: uploadDocsReducer
});
