import React, { Component, useState, useEffect, useContext  } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import axios from 'axios';
import config from '../../../../config';
import { DocumentSignatureStateContext } from '../documentSignatureContext';
import HelloSignEmbeddedComponent from './HelloSignEmbeddedComponentForDocuments'
import ContractDoneComponent from '../components/ContractDoneComponent'

// const _ = require('lodash');
export default function ContractContainer(props) {
    // const { setMainState, mainState, handleChange } = useContext(MerchantStateContext);
    // class ContractContainer extends Component {
      
        const { documentData, setDocumentData } = useContext(DocumentSignatureStateContext);
        const [isLoading, setLoading] = useState(true);



    const onSigningEvent = (params) => async dispatch => {
        
        // let contactUuid = params.contactUuid;
        // const response = await axios.post(config.apiUrl + '/fundingPlatformPublic/sign/onSigningEvent', params);
        // let contactSigningStatus = _.keyBy(response.data.contactsSigningStatus, 'contactUuid')[contactUuid];
        // contactSigningStatus.eventListener = response.data.event
        // console.log('res event', response.data.event)
        console.log('res params', params)

    }
    const [documentSigners, setDocumentSigners] = useState({})
    
    // we need the contractUuid to fetch the initial data from that document 
    console.log('Props', props)
    let contractParam = props.match.params.documentUuid
    let signerParam = props.match.params.signerUuid
    

    useEffect((props) => {

        const getContract = async (props) => {
            let response = await axios.get(`${config.apiUrl}/fundingPlatformPublic/sign/prepareContractData/${contractParam}/${signerParam}`)
            // let response = await axios.get(`${config.apiUrl}/fundingPlatformPublic/sign/prepareContractData/${this.props.match.params.documentUuid}`)

            if (response.data) {
                console.log('data', response.data);
                setDocumentData({

                    contract: response.data.data.contract,
                    clientId: response.data.data.clientId,
                    offerData: response.data.data.offerData,
                    response: response.data.data.response,
                    responseURL: response.data.data.response.embedded.sign_url,
                    signatureId: response.data.data.signatureId,
                    signerCompleted: response.data.data.signerCompleted,
                    nextRoute: '/onboarding/merchant/landing' //not in use yet

                })

                setDocumentSigners({
                    signers: response.data.data.signers

                })
                setLoading(false);
                //  return res.data
            }
            else // display error type somehow
            {
                console.error('we got no data');
            }

        }
        getContract()

    }, [])


    const contexthistory = useHistory();
    if (isLoading) {
     return <div >
            <div className='spinner'>
            Loading...
             {/* docS: {documentData.signerCompleted} */}
             
            </div>
        </div>;
    } else if (documentData.signerCompleted) {
        return <div >
            <div className='spinner'>
                <div className='row'>
                    <div className='col-12 col-lg-6'>
                        <img className='hi5' src='/gif/uplyft_em_high5_v04_hi5.gif' alt='done' style={{ opacity: 1, width: '250px' }} />
                    </div>
                    <div className='col-12 col-lg-6'>
                        <h2 className='pb-3 pt-3 mt-4 title page-title'> Looks like you have already signed this document...</h2>
                        <p className='text-muted'>
                        your job here is done

                        </p>
                    </div>

                </div>
                {/* docS: {documentData.signerCompleted} */}

            </div>
        </div>;
    }else{

        return (
            <>
                <Helmet>
                    <title>Uplyft Capital Funding | Sign-Contract</title>
                </Helmet>
                <div className='container pt-2 space'>
    
                    <div 
                    // id='helloSign' //commented to see if affects workflow or creates conflict
                    className="layoutFading space">
                        {props.eventListener ?
                        <>
                         <p> this</p>
                         <div id="signContractDone">
                          <ContractDoneComponent 
                         submissionUuid={this.state.submissionUuid}
                         handleSignContractError={this.props.handleSignContractError} />
                         </div>
                        </>
                            :
                        <div className='shadow p-5 mb-2 bg-white roundCorners'>
                            <div 
                            // id="signContract" //commented to see if affects workflow or creates conflict
                            >
                        
                                <HelloSignEmbeddedComponent
                                    // clientId='9183d1d584e752ef983e8faf7a338666'
                                    clientId={documentData.clientId}
                                    // signatureId={this.state.contactSigningStatus.signatureId}
                                    // signatureId='49af3bbecf1378f465620e105516f22e'
                                    signatureId={documentData.signatureId}
                                    // url={this.state.contactSigningStatus.signURL.url}
                                    // url={documentData.response.embedded.sign_url}
                                    // url='https://app.hellosign.com/editor/embeddedSign?signature_id=49af3bbecf1378f465620e105516f22e&token=bf5df0884de544ea7729f3a6d49b25d1'
                                    url={documentData.responseURL}
                                    onSigningEvent={onSigningEvent}
                                    // signingEventParams={{ submissionUuid: this.state.submissionUuid, contactUuid: this.state.contactUuid }}
                                    uxVersion={2}
                                    inContainer={true}
                                    redirectUrl={documentData.nextRoute}
                        
                            />
                            </div>
                        </div>
                        }
                        {/* <FundingProgressBarComponent dots={[{ name: "Contract Review", status: 1 }, { name: "Sign Contract", status: 1 }, { name: "Bank Verification", status: 0 }, { name: "Upload Docs", status: 0 }, { name: "Done", status: 0 }]} /> */}
                        {/* this */}
                        {/* {dummyData} */}
                        {/* {documentData.dummyData} */}
                        {/* cliendId: {documentData.clientId}  */}
                        <br />
                        {/* URL: {documentData.responseURL} */}
                        <br />
                        {/* sigID: {documentData.signatureId} */}
                        {/* docS: {documentData.documentStatus} */}
                        <br />
                        {/* cliendId: {documentData.response.embedded.sign_ur} */}
                        {/* {this.state.dummyData} */}
                    </div>
                </div>
            </>
        );
    }

     
}

