import axios from 'axios';
import actions from './actions';
import config from '../../../../config/index';

const prepareContractAction = actions.Creators.prepareContract;
const onVerificationEventAction = actions.Creators.onVerificationEvent;

const prepareContract = (submissionUuid) => async dispatch => {
	let params = {};
	params.submissionUuid = submissionUuid;
	const response = await axios.post(config.apiUrl + '/fundingPlatformPublic/verifyContract/prepareContract', params);
	let verifyContractStatus = response.data.verifyContractStatus;
	verifyContractStatus.eventListener = '';
	try {
        dispatch(prepareContractAction({verifyContractStatus}));
	} catch(error) {
		dispatch(error);
	}
    return;
}

const onVerificationEvent = (params) => async dispatch => {
	const response = await axios.post(config.apiUrl + '/fundingPlatformPublic/verifyContract/onVerificationEvent', params);
	let verifyContractStatus = response.data.verifyContractStatus;
	verifyContractStatus.eventListener = response.data.event
	try {
        dispatch(onVerificationEventAction({verifyContractStatus}));
	} catch(error) {
		dispatch(error);
	}
    return;
}

export default {
    prepareContract,
	onVerificationEvent,
  }