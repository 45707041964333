import React, { Component } from "react";
import { history } from '../../store';

export class RedirectComponent extends Component {
  constructor( props ){
    super();
    this.state = { ...props };
  }
  componentWillMount(){
  }
  componentDidMount(){
    //let url = this.props.match.params.url;
    //console.log(url);
    //window.location.href = "https://google.com/contact";
    //window.location.href = url;

    const query = new URLSearchParams(this.props.location.search);
    let nexturl = query.get('url');
    if(nexturl) 
    {
        //console.log(nexturl);
        if(nexturl.includes("http"))
        {
            window.location.href = nexturl;
        }
        else history.push(nexturl);
    }
    //else history.goBack();
  }
  render(){
    return (<section>Redirecting...</section>);
  }
}

export default RedirectComponent;