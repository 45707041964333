import React, { Component } from "react";
import "./dropzone.css";
import { returnTrueIsSpanishIsPrefered , translateIfPreferedLanguageIsSpanish } from '../../../../../../lib/util';

class Dropzone extends Component {
  constructor(props) {
    super(props);
    this.state = { hightlight: false };
    this.pref = 'Span'
    this.fileInputRef = React.createRef();

    this.openFileDialog = this.openFileDialog.bind(this);
    this.onAddFiles = this.onAddFiles.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  openFileDialog() {
    this.fileInputRef.current.click();
  }

  async onAddFiles(e) {
    const files = e.target.files;
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files);
      await this.props.onFilesAdded(array);
    }
  }

  onDragOver(event) {
    event.preventDefault();
    this.setState({ hightlight: true });
  }

  onDragLeave(event) {
    this.setState({ hightlight: false });
  }

  async onDrop(event) {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files);
      await this.props.onFilesAdded(array);
    }
    this.setState({ hightlight: false });
  }

  fileListToArray(list) {
    const array = [];
    for (var i = 0; i < list.length; i++) {
      array.push(list.item(i));
    }
    return array;
  }


  render() {
    return (
      <div
        className={`dropZone ${this.state.hightlight ? "Highlight" : ""}`}
        onDragOver={this.onDragOver}
        onDragLeave={this.onDragLeave}
        onDrop={this.onDrop}
        onClick={this.openFileDialog}
        style={{ cursor: this.props.disabled ? "default" : "pointer" }}
      >
        <span className="">
          <img alt='upload' className='Icon' src='/svg/baseline-cloud_upload-24px.svg' />
        </span>
        <p style={{ color: '#140046', fontWeight: '700' }} className='d-flex justify-content-center'>
          {translateIfPreferedLanguageIsSpanish(this.props.pref, 'Drag & Drop or click to Upload Files', 'Arrastre y suelte aca o haga clic para cargar archivos')}
        </p>
        <input ref={this.fileInputRef} className='FileInput' type='file' multiple onChange={this.onAddFiles} />
        {/* <button className='btn btn-clear'>Or Select Files to Upload</button> */}
      </div >
    );
  }
}

export default Dropzone;


// Upload Instructions
// • Drag & Drop up to 12 files into this box
// (or)
// • Click inside this box, then select files to upload
// • Click on the "Submit Files" button below