import axios from 'axios';
import actions from './actions';
// import { async } from 'q';

import config from '../../../../config/index';

const getUploadDocsInitialDataAction = actions.Creators.getUploadDocsInitialData;
const initializeRequiredDocsAction = actions.Creators.initializeRequiredDocs;
const updateRequiredDocsStateAction = actions.Creators.updateRequiredDocsState;
const getUploadDocsInitialData = (submissionUuid) => async dispatch => {
	const response = await axios.get(config.apiUrl + '/fundingPlatformPublic/uploadDocs/getInitialData/'+ submissionUuid);

	let account = response.data.account;
    let opportunity = response.data.opportunity;
    try {
    dispatch(getUploadDocsInitialDataAction({account, opportunity, submissionUuid}));
	}
	catch(error) {
		dispatch(error);
	}
	return;
}

const initializeRequiredDocs = (itemsProperties) => async dispatch => {
	let requiredDocs = [];
	itemsProperties.forEach(item => {
		if(item.required){
			let requiredDoc = {};
			requiredDoc.name = item.name;
			requiredDoc.uploaded = 0;
			requiredDocs.push(requiredDoc);
		}
    });
    try {
        dispatch(initializeRequiredDocsAction({requiredDocs: requiredDocs}));
    }
    catch(error) {
        dispatch(error);
    }
	return;
}

const sendVerifyContractNotification = (submissionUuid) => async dispatch => {
	try {
	    await axios.get(config.apiUrl + '/fundingPlatformPublic/uploadDocs/sendVerifyContractNotification/'+ submissionUuid);
	}
	catch(error) {
		console.error(error);
	}
	return;
}

const sendUploadDocsNotification = (submissionUuid, contactUuid) => async dispatch => {
	try {
	    await axios.get(config.apiUrl + '/fundingPlatformPublic/uploadDocs/sendUploadDocsNotification/'+ submissionUuid + '/' + contactUuid);
	}
	catch(error) {
		console.error(error);
	}
	return;
}

// actionType: loaded, removed
const updateRequiredDocsState = (docKey, actionType) => async dispatch => {
    try {
        dispatch(updateRequiredDocsStateAction({docKey: docKey, actionType: actionType }));
    }
    catch(error) {
        dispatch(error);
    }
	return;
}

const removeFile = async (fileKey) => {
	try {
	    await axios.post(config.apiUrl + '/fundingPlatformPublic/uploadDocs/removeFile', {fileKey: fileKey});
	}
	catch(error) {
		console.error(error);
	}
	return;
}

export default {
	getUploadDocsInitialData,
	sendVerifyContractNotification,
	sendUploadDocsNotification,
    initializeRequiredDocs,
    updateRequiredDocsState,
    removeFile
  }