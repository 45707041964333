import React from 'react'

export default function UplyftInfoLanding() {
    return (
        <div>
            <div className="row mt-8rem">
                <div className="col-12 col-lg-6">
                    <h1> 
                    Select the option that is best for your business
                    </h1>
                    <h2 className="purple">
                        $300m to over 4,500 business
                    </h2>
                </div>
            </div>
            <div className="row" >
                <div className="" style={{position:"absolute", right:"0px", marginTop:"-24rem", zIndex:"-9998"}}>
                <img src="/png/side-wave_001.png" alt="information icon" style={{height:"780px"}}/>
            </div>

            </div>
            <br />
            <div className="row">
                <div className="col-12 col-lg-4 mt-4">
                    <div className="form__info js-is-seen is-seen row mx-0">
                        <div className="col-3 p-0 d-flex">
                            <img style ={{height:'70px'}} src="/svg/svg_landing/decisionPoints/business_loans.svg" alt="information icon" className=""/>
                        </div>       
                        <div className="col-9">
                            <h5 className="item-title"><span>Business Loans</span></h5>
                            <p className="item-text">Get the money you need to keep the business running and growing.</p>
                        </div>   
                    </div>
                </div>
                <div className="col-12 col-lg-4 mt-4">
                    <div className="form__info js-is-seen is-seen row m-0">
                        <div className="col-3 p-0 d-flex">
                            <img style ={{height:'70px'}} src="/svg/svg_landing/decisionPoints/personal_loan.svg" alt="information icon" className=""/>
                        </div>       
                        <div className="col-9">
                            <h5 className="item-title"><span>MCA</span></h5>
                            <p className="item-text">Fast, convenient funding based on your monthly business revenue.</p>
                        </div>   
                    </div>
                </div>
                <div className="col-12 col-lg-4 mt-4">
                    <div className="form__info js-is-seen is-seen row m-0">
                        <div className="col-3 p-0 d-flex">
                            <img style ={{height:'70px'}} src="/svg/svg_landing/decisionPoints/line_of_credit.svg" alt="information icon" className=""/>
                        </div>       
                        <div className="col-9">
                            <h5 className="item-title"><span>Line of credit</span></h5>
                            <p className="item-text">Receive a revolving line of credit. Pay it off and use it again.</p>
                        </div>   
                    </div>
                </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
        </div>
        
    );
};
