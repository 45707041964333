import React from 'react'

export default function UplyftPhoneAppInfo() {
    return (
        <div className="row" style={{ overflow:"hidden"}}>
            <div className="col-12 col-lg-6 phone-ui mobile-none">
                <img src="/png/phones_001.png" alt="information icon" className="h-24" />
            </div>
            <div className="col-12 col-lg-6 first-in-mobile">
                <h1 className="text-right mobile-text-center">Over $300M Funded</h1>
                <h2 className="purple text-right mobile-text-center">to over 4,500 business</h2>
                <br />
                <div className=" my-2 mr-0 pb-3" >
                    <div className="row">
                        <div className="col-3">
                            <img style ={{height:'70px'}} src="/svg/svg_landing/decisionPoints/easy_sign_up.svg" alt="information icon"/>
                        </div>       
                        <div className="col-9">
                            <h5 className="item-title"><span >Easy Sign Up</span></h5>
                            <p className="item-text">Simple online application completed within minutes.</p>
                        </div>       
                    </div> 
                </div>
                <div className=" my-2 mr-0" data-anime="{&quot;from&quot;:{&quot;opacity&quot;:0,&quot;translateY&quot;:&quot;70px&quot;},&quot;to&quot;:{&quot;opacity&quot;:1,&quot;translateY&quot;:0,&quot;delay&quot;:400,&quot;duration&quot;:1500,&quot;easing&quot;:&quot;easeOutQuart&quot;}}" style={{ opacity: "1", transform: "translateY(0px)" }}>
                    <div className="row">
                        <div className="col-3">
                            <img style ={{height:'70px'}} src="/svg/svg_landing/decisionPoints/same_day_decision.svg" alt="information icon"/>
                        </div>       
                        <div className="col-9">
                            <h5 className="item-title"><span >Same-Day Decision</span></h5>
                            <p  className="item-text">Easy and fast so you can focus on running your business. No need to compromise today; grab our proposal and explore your options, completely free of charge.</p>
                        </div>       
                    </div> 
                </div>
                <div className=" my-2 mr-0" data-anime="{&quot;from&quot;:{&quot;opacity&quot;:0,&quot;translateY&quot;:&quot;70px&quot;},&quot;to&quot;:{&quot;opacity&quot;:1,&quot;translateY&quot;:0,&quot;delay&quot;:800,&quot;duration&quot;:1500,&quot;easing&quot;:&quot;easeOutQuart&quot;}}" style={{ opacity: "1", transform: "translateY(0px)" }}>
                    <div className="row">
                        <div className="col-3">
                            <img style ={{height:'70px'}} src="/svg/svg_landing/decisionPoints/fast_funding.svg" alt="information icon"/>
                        </div>       
                        <div className="col-9">
                            <h5 className="item-title"><span >Fast Funding</span></h5>
                            <p  className="item-text">Funds automatically deposited into your business account the moment you decide to move forward.</p>
                        </div>       
                    </div> 
                </div>
            </div>
        </div>
    );
};
