import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
// import PropTypes from 'prop-types'
import { Helmet } from "react-helmet";

import { history } from '../../../store';
import { toCurrencyFormat } from '../../../lib/util';
import FundingProgressBarComponent from '../common/FundingProgressBarComponent';
import OfferSummaryComponent from '../common/OfferSummaryComponent';
import ContactSupportComponent from '../common/ContactSupportComponent';
import MaskedInput from 'react-text-mask';
import config from '../../../config/index'

import contractReviewOperations from './duck/operations'


class ContractReviewContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            submissionUuid: ''
            , contactUuid: ''
            , businessName: this.props.account.name
            , contacts: this.props.contacts
        };
    }

    async componentDidMount() {
        let contactUuid = this.props.match.params.contactUuid;
        let submissionUuid = this.props.match.params.submissionUuid;
        this.setState({
            submissionUuid: submissionUuid
            , contactUuid: contactUuid
        });
        await this.props.getContractReviewInitialData(submissionUuid, contactUuid);
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        let entities = {};
        
        //Checking for account name and address updates
        let accountName = this.props.account.name;
        let accountDBA = this.props.account.dba || '';
        let address1 = {...this.props.account.address}.address1;
        let address2 = {...this.props.account.address}.address2;
        let city = {...this.props.account.address}.city;
        let state = {...this.props.account.address}.state;
        let zip = {...this.props.account.address}.zip;
        if(accountName !== e.target.businessName.value || accountDBA !== e.target.dba.value || address1 !== e.target.address1.value || address2 !== e.target.address2.value || city !== e.target.city.value || state !== e.target.state.value || zip !== e.target.zip.value){
            entities.account = { ...this.props.account, name: e.target.businessName.value, dba: e.target.dba.value, address: { address1: e.target.address1.value, address2: e.target.address2.value, city: e.target.city.value, state: e.target.state.value, zip: e.target.zip.value} };
        }

        //Checking for contacts updates
        let editedContacts = [];
        this.props.contacts.forEach((contact, i) => {
            if (contact.firstName !== e.target['firstName' + i].value || contact.lastName !== e.target['lastName' + i].value) {
                contact.firstName = e.target['firstName' + i].value;
                contact.lastName = e.target['lastName' + i].value;
                contact.name = e.target['firstName' + i].value + ' ' + e.target['lastName' + i].value;
                editedContacts.push(contact);
            }
        });
        if (editedContacts.length > 0) {
            entities.contacts = editedContacts;
        }

        await this.props.updateEntities(entities, this.state.contactUuid);
        history.push('/funding/sign-contract/' + this.state.submissionUuid + '/' + this.state.contactUuid);
    }

    controlButtons = () => (
        <div className='container d-flex justify-content-between pt-2'>
            <button className='btn btn-primary display' style={{ visibility: "hidden" }}>
                Sign Contract
            </button>
            <button type="submit" className='btn btn-primary display' style={{ visibility: "visible" }}>
                Sign Contract
            </button>
            <button type="submit" className='btn btn-primary isDone'>
                Sign Contract
            </button>
        </div>
    );

    render() {
        if(this.props.currentContactSigned){
            return (
                <div className="layoutFading space container">
                    <div className='shadow pt-5 pb-3 pr-5 pl-5 mb-2 bg-white roundCorners'>
                        <div className="form__header pb-3">
                            <h2 className="form__title is-done" style={{opacity: 1}}>You already signed this contract</h2>
                        </div>
                        <div className='display'><ContactSupportComponent/></div>
                    </div>
                    <div className='isDone pt-3'><ContactSupportComponent/></div>
                </div>
            );
        } else{
            return (
                <>
                    <Helmet>
                        <title>Uplyft Capital Funding | Contract-Review</title>
                    </Helmet>
                    <div className="layoutFading space">
                        <FundingProgressBarComponent dots={[{ name: "Contract Review", status: 1 }, { name: "Sign Contract", status: 0 }, { name: "Bank Verification", status: 0 }, { name: "Upload Docs", status: 0 }, { name: "Done", status: 0 }]} />
                        <div id="contractReview" className='container pt-3 space'>
                            <form onSubmit={(e) => this.handleSubmit(e)}>
                                <div>
                                    <h2 className='pb-3 page-title'> Review Contract Details </h2>
                                </div>
                                <div className='shadow p-5 mb-2 bg-white roundCorners'>
                                    <div className='row'>
                                        <div className='form-group col-12 col-lg-6'>
                                            <label className='pb-2'>Business Legal Name</label>
                                            <input type="text" className="form-control form-elegant" name="businessName" defaultValue={this.props.account.name} required readOnly={this.props.contractStatus === 'Signed' ? "readonly" : ""} />
                                        </div>
                                        <div className='form-group col-12 col-lg-6'>
                                            <label className='pb-2'>DBA</label>
                                            <input type="text" className="form-control form-elegant" name="dba" defaultValue={this.props.account.dba || ''} required readOnly={this.props.contractStatus === 'Signed' ? "readonly" : ""} />
                                        </div>
                                    </div>
                                    <div className='row'>    
                                        <div className='form-group col-12 col-lg-4'>
                                            <label className='pb-2'>Business Address</label>
                                            <input
                                                type='text'
                                                className='form-control form-elegant text-muted'
                                                name="address1"
                                                defaultValue={{...this.props.account.address}.address1}
                                                readOnly={this.props.contractStatus === 'Signed' ? "readonly" : ""}
                                                required
                                            />
                                        </div>
                                        <div className='form-group col-12 col-lg-2'>
                                            <label className='pb-2'>Suite/Apt#</label>
                                            <input
                                                type='text'
                                                className='form-control form-elegant text-muted'
                                                name="address2"
                                                defaultValue={{...this.props.account.address}.address2}
                                                readOnly={this.props.contractStatus === 'Signed' ? "readonly" : ""}
                                            />
                                        </div>
                                    {/* </div>
                                    <div className='row'> */}
                                        <div className='form-group col-4 col-lg-2'>
                                            <label className='pb-2'>City</label>
                                            <input
                                                type='text'
                                                className='form-control form-elegant text-muted'
                                                name="city"
                                                defaultValue={{...this.props.account.address}.city}
                                                readOnly={this.props.contractStatus === 'Signed' ? "readonly" : ""}
                                                required
                                            />    
                                        </div>
                                        <div className='form-group col-4 col-lg-2'>
                                            <label className='pb-2'>State</label>
                                            <select
                                                className='form-control form-elegant text-muted'
                                                name="state"
                                                readOnly={this.props.contractStatus === 'Signed' ? "readonly" : ""}
                                                required
                                            >
                                                {config.usStatesList.map((state, i) => (
                                                    <option key={i} value={state.abbreviation} selected={{...this.props.account.address}.state === state.abbreviation}>{state.abbreviation}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className='form-group col-4 col-lg-2'>
                                            <label className='pb-2'>Zip Code</label>
                                            <MaskedInput
                                                mask={[/[0-9]/, /\d/, /\d/, /\d/, /\d/]}
                                                guide={true}
                                                type='text'
                                                className='form-control form-elegant text-muted'
                                                name="zip"
                                                pattern='^\d{5}$' title='Zip code should contain 5 digits'
                                                defaultValue={{...this.props.account.address}.zip || ''}
                                                readOnly={this.props.contractStatus === 'Signed' ? "readonly" : ""}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12 col-lg-12'>
                                             <div className='row'>
                                                {this.props.contacts.map((contact, i) => {
                                                    return (
                                                        <Fragment key={i}>
                                                            <div className='form-group col-6 col-lg-6'>
                                                                {i === 0 ? <label className='pb-2'>First Name</label> : ''}
                                                                <input type="text" className="form-control form-elegant" name={"firstName" + i} defaultValue={contact.firstName} readOnly={this.props.contractStatus === 'Signed' ? "readonly" : ""} />
                                                            </div>
                                                            <div className='form-group col-6 col-lg-6'>
                                                                {i === 0 ? <label className='pb-2'>Last Name</label> : ''}
                                                                <input type="text" className="form-control form-elegant" name={"lastName" + i} defaultValue={contact.lastName} readOnly={this.props.contractStatus === 'Signed' ? "readonly" : ""} />
                                                            </div>
                                                        </Fragment>
                                                    )
                                                })}
                                             </div>
                                        </div> 
                                    </div>
                                    <div className='row'>
                                        <div className='form-group col pt-3'>
                                            <label className='pb-2'> Offer Details</label>
                                            <OfferSummaryComponent items={[
                                                { fai: <i className="fa fa-usd" aria-hidden="true"></i>, name: "Funding Amount", content: toCurrencyFormat(this.props.offer.fundingAmount), contentStyle:{ fontSize: '14px', color: '#9a56ff'} }
                                                , { fai: <i className="fa fa-usd" aria-hidden="true"></i>, name: "Payment Amount", content: toCurrencyFormat(this.props.offer.paymentAmount), contentStyle:{ fontSize: '14px', color: '#9a56ff'} }
                                                , { fai: <i className="fa fa-calendar" aria-hidden="true"></i>, name: "Payments", content: this.props.offer.paymentsCount + " " + this.props.offer.paymentFrequency, contentStyle:{ fontSize: '14px', color: '#9a56ff'} }
                                                , { fai: <i className="fa fa-usd" aria-hidden="true"></i>, name: "Payback Amount", content: toCurrencyFormat(this.props.offer.paybackAmount), contentStyle:{ fontSize: '14px', color: '#9a56ff'} }]}
                                            />
                                        </div>
                                    </div>
                                    <div className='display'><ContactSupportComponent /></div>
                                </div>

                                {this.controlButtons()}
                                <div className='isDone pt-3'><ContactSupportComponent /></div>

                            </form>
                        </div>
                    </div>
                </>
            );
        }
    }
}

//PropTypes
ContractReviewContainer.propTypes = {
}

const mapStateToProps = state => ({
    // businessName: state.contractReview.account.name
    account: state.contractReview.account
    , contacts: state.contractReview.contacts
    , offer: state.contractReview.offer
    , contractStatus: state.contractReview.contractStatus
    , currentContactSigned: state.contractReview.currentContactSigned
});

const mapDispatchToProps = dispatch => {
    const getContractReviewInitialData = (submissionUuid, contactUuid) => dispatch(contractReviewOperations.getContractReviewInitialData(submissionUuid, contactUuid));
    const updateEntities = (entities, contactUuid) => contractReviewOperations.updateEntities(entities, contactUuid);
    return {
        getContractReviewInitialData,
        updateEntities,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractReviewContainer);