import React from 'react';
import PropTypes from 'prop-types';

const PLAID_CONTAINER_ID = "plaid-embedded";

class PlaidEmbedded extends React.Component {

    constructor(props) {
        super(props);
        this.getPlaidSettings = this.getPlaidSettings.bind(this);
    }

    componentDidMount() {
            const script = document.createElement("script");
            script.src = "https://cdn.plaid.com/link/v2/stable/link-initialize.js";
            script.async = true;
            script.type = 'text/javascript';

            // Get the settings and add the container if needed
            var settings = this.getPlaidSettings();
            script.onload = () => {
                let handle = window.Plaid.create(settings);
                handle.open();
            };
            document.body.appendChild(script);
       
    }

    getPlaidSettings() {
        var plaid_settings = {};
        
        // Required or default settings
        plaid_settings.apiVersion = this.props.apiVersion;
        plaid_settings.clientName= this.props.clientName;
        plaid_settings.countryCodes= this.props.countryCodes;
        plaid_settings.language= this.props.language;
        plaid_settings.env= this.props.env;
        plaid_settings.key= this.props.publicKey;
        plaid_settings.onEvent= this.props.onEvent;
        plaid_settings.onExit= this.props.onExit;
        plaid_settings.onLoad= this.handleLinkOnLoad;
        plaid_settings.onSuccess= this.props.onSuccess;
        plaid_settings.product= this.props.product;
        plaid_settings.selectAccount= this.props.selectAccount;
        plaid_settings.token= this.props.token;
        plaid_settings.user= this.props.user;
        plaid_settings.webhook= this.props.webhook;
        plaid_settings.isWebview = true;
		
        return plaid_settings;
    }

    render() {
        // console.log(this.props.inContainer);
        const container = this.props.inContainer ? <div id={PLAID_CONTAINER_ID}></div> : "Document";
        return <div>{container}</div>;
    }
}

PlaidEmbedded.propTypes = {
    // ApiVersion flag to use new version of Plaid API
    apiVersion: PropTypes.string,

    // Displayed once a user has successfully linked their account
    clientName: PropTypes.string.isRequired,

    // List of countries to initialize Link with
    countryCodes: PropTypes.array,

    // The Plaid API environment on which to create user accounts.
    // For development and testing, use tartan. For production, use production
    env: PropTypes.oneOf(['tartan', 'sandbox', 'development', 'production']).isRequired,

    // The public_key associated with your account; available from
    // the Plaid dashboard (https://dashboard.plaid.com)
    publicKey: PropTypes.string.isRequired,

    // The Plaid products you wish to use, an array containing some of connect,
    // auth, identity, income, transactions, assets, liabilities
    product: PropTypes.arrayOf(
      PropTypes.oneOf([
        'connect',  // legacy product name
        'info',     // legacy product name
        'auth',
        'identity',
        'income',
        'transactions',
        'assets',
        'liabilities',
        'investments',
      ])
    ).isRequired,

    // List of countries to initialize Link with
    language: PropTypes.string,

    // Specify an existing user's public token to launch Link in update mode.
    // This will cause Link to open directly to the authentication step for
    // that user's institution.
    token: PropTypes.string,

    // Specify a user object to enable all Auth features. Reach out to your
    // account manager or integrations@plaid.com to get enabled. See the Auth
    // [https://plaid.com/docs#auth] docs for integration details.
    user: PropTypes.shape({
      // Your user's legal first and last name
      legalName: PropTypes.string,
      // Your user's associated email address
      emailAddress: PropTypes.string,
    }),

    // Set to true to launch Link with the 'Select Account' pane enabled.
    // Allows users to select an individual account once they've authenticated
    selectAccount: PropTypes.bool,

    // Specify a webhook to associate with a user.
    webhook: PropTypes.string,

    // A function that is called when a user has successfully onboarded their
    // account. The function should expect two arguments, the public_key and a
    // metadata object
    onSuccess: PropTypes.func.isRequired,

    // A function that is called when a user has specifically exited Link flow
    onExit: PropTypes.func,

    // A function that is called when the Link module has finished loading.
    // Calls to plaidLinkHandler.open() prior to the onLoad callback will be
    // delayed until the module is fully loaded.
    onLoad: PropTypes.func,

    // A function that is called during a user's flow in Link.
    // See
    onEvent: PropTypes.func,

    // Button Styles as an Object
    style: PropTypes.object,

    // Button Class names as a String
    className: PropTypes.string,
};

PlaidEmbedded.defaultProps = {
    selectAccount: false,
    token: null,
    inContainer: true,
};

export default PlaidEmbedded;