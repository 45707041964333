import React, { useState, useContext } from "react";
import {  useHistory } from "react-router-dom";
import { MerchantStateContext } from '../../application/context/MerchantStateContext';


export default function PreApproval() {
    // const [redirect, setRedirect] = useState(false);
    const history = useHistory();
    const [overlay, setOverlay] = useState({ offer:false, spinner: true });
    const { mainState } = useContext(MerchantStateContext);
	const { opportunity__requestedAmount, opportunity__revenue, contact__creditScoreRatio, account__startDate, contact__debt} = mainState

    let preApproved = {};
    preApproved.message = 'Congratulations!'; //
    preApproved.amount = 'You qualify for our personal loan program.'

    let parsedRequestedAmount = parseInt(opportunity__requestedAmount.replace('$','').replace(/,/g,''));
    let parsedMonthlyRevenue = parseInt(opportunity__revenue.replace('$','').replace(/,/g,''));
    // calculate Time in Business greater than 6 months
    let today = new Date()
    let diff = (today.getTime() - new Date(account__startDate).getTime()) / 1000;
    diff /= (60 * 60 * 24 * 7 * 4); 
    let tib =  ( Math.abs(Math.round(diff)) > 6 ? true : false); //tib = time in business

    setTimeout(() => { setOverlay({ ...overlay, spinner: false }) }, 500 );

    if (tib){
        if (parsedRequestedAmount <= parsedMonthlyRevenue * 1.5 ){
            let approvedAmount = (parsedMonthlyRevenue * ( parseFloat(contact__creditScoreRatio) + parseFloat(contact__debt)))/2;
            preApproved.amount = `Pre-approval amount: $${approvedAmount.toLocaleString()}`;
        };
    };

    const submitPreApproval = e => {
		e.preventDefault();
        sessionStorage.setItem("mainState", JSON.stringify(mainState));
        history.push('/onboarding/merchant/business');
    };
    
    return (
        <>
            {!overlay.spinner ? 
                <div id='preApproved' className='p-2'>
                    <div className='preApproved_header'>
                        {/* <i className="fa fa-check-circle-o" aria-hidden="true"></i> */}
                        <img src="/png/checkmark_darker_001.png" alt="information icon" />
                        <h4 className='pb-5 mb-5'><b>{preApproved.message}</b></h4>
                    </div>
                    <div className='row'>
                        <h2 className='col-12'>{preApproved.amount}</h2>
                        <div className='col-12 mt-5'>
                            <a id="btnPrimaryPreApprove" target="_blank" href="https://figure-unsecured-loan.sjv.io/a1W21N" className="btn w-60 btn-primary inside-form d-flex" >
                                Click Here to get Pre-approved Today
                                <span className="mobile-none">
                                    <i className="fa fa-paper-plane btn-add-on"></i>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
                :
                <>
                    {/* <h4>We are want to give you the best offer..</h4> 	 */}
                    <div style={{position:'relative'}} className='spinner'>
                        <div className='loader'>Loading...</div>
                    </div>
                </>
            }
        </>
    );
};
