import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams  } from "react-router-dom";
import Cleave from 'cleave.js/react';
import config from '../../../../config';
import { Helmet } from "react-helmet";
import axios from "axios";
import ServerError from "./ServerError";
// import { MerchantStateContext } from '../context/MerchantStateContext';

import { returnTrueIsSpanishIsPrefered , translateIfPreferedLanguageIsSpanish } from '../../../../lib/util'

export default function AppAdditions(props) {
	const [loading, setLoading] = useState(false);
	const [serverError, setServerError] = useState(false);
    const [formError, setFormError] = useState({});
    const [additions, setAdditions] = useState({
        urgency: 'a week'
        , minimum_requested_amount: ''
        , use_of_proceeds_description: ''
        , requested_preference: 'Requested Amount'
        , preferred_contact_time: ''
        , preferred_contact_timeZone: ''
        , preferred_contact_method: ''
        , url: ''
        , online_presence_instagram: ''
        , online_presence_facebook: ''
        , online_presence_yelp: ''
    });

	const history = useHistory();
	const params = useParams();
	const clearHistoryAndPush = (path) => {
		history.entries = [];
		history.index = -1;
		history.push(path);
	};

	useEffect(() => {
			setLoading(true)
			let url = `${config.apiUrl}/api/uplyftOnboarding/${params.accountUuid}/accountValidate/${params.contactUuid}/${params.opportunityUuid}`
				axios.get(url)
				.then((res) => setLoading(false) )
				.catch( error => {
						clearHistoryAndPush('/404');
						console.error(error);
					});
	},[]);

	useEffect(() => {
        if (sessionStorage['mainState']){
            let tempState = JSON.parse(sessionStorage.getItem("mainState"));
            setAdditions({
                ...additions
                , requestedAmount: tempState.opportunity__requestedAmount
                , purposeOfFunding: tempState.opportunity__purposeOfFunding
                , urgency: tempState.urgency || 'a week'
                , minimum_requested_amount: tempState.minimum_requested_amount
                , use_of_proceeds_description: tempState.use_of_proceeds_description
                , requested_preference: tempState.requested_preference || 'Requested Amount'
                , preferred_contact_time: tempState.preferred_contact_time
                , preferred_contact_timeZone: tempState.preferred_contact_timeZone
                , preferred_contact_method: tempState.preferred_contact_method
                , preferred__language: tempState.contact__preferred__language || 'English'
                , url: tempState.url
                , online_presence_instagram: tempState.online_presence_instagram
                , online_presence_facebook: tempState.online_presence_facebook
                , online_presence_yelp: tempState.online_presence_yelp
            });
        };
        //if(!sessionStorage['data'] ) clearHistoryAndPush('/404');
	},[]);

    const urgencyOptionsArr = ['ASAP', 'A day or two', 'A Week', 'A Month', 'Just Inquiring'];
    const preferredContactMethodArr = ['Call', 'Email', 'Text'];
    const preferredContactTimeArr = ['7AM','8AM','9AM','10AM','11AM','12PM','1PM', '2PM', '3PM', '4PM', '5PM', '6PM', '7PM'];
    const preferredContactTimeZoneArr = ['Eastern','Central','Mountain','Pacific', 'Alaska', 'Hawaii‑Aleutian'];

	const formValidation = (e) => {
		let form = e.target
		let isValid = form.checkValidity()
		let formData = new FormData(form)
		let validationMessages = Array.from(formData.keys()).reduce((acc, key) => {
			acc[key] = form.elements[key].validationMessage
			return acc
		}, {})

		setFormError(validationMessages)
		return isValid
	};

	const onInput = (e) => {
		const input = e.target
		setFormError({ ...formError, [input.name]: input.validationMessage })
	};

	const getError = (field) => formError[field];

	const clickSubmit = e => {
		e.preventDefault();
        let isValid = formValidation(e);
        if (!isValid) return;
        sendPostRequest();
	};

    const handleChange = name => event => {
        // console.log(event.target.value)
        let eventTarget = event.target.value;
        setAdditions({ ...additions, [name]: eventTarget });
    };

	const sendPostRequest = () => {
        const data = {
            accountUuid: params.accountUuid
            , contactUuid: params.contactUuid
            , opportunityUuid: params.opportunityUuid
        };
		axios.post(`${config.apiUrl}/api/uplyftOnboarding/index`, { additions, data })
			.then(() => {
				history.push(`/onboarding/merchant/finished`);
			})
			.catch(error => {
				console.error('error', error);
			});
	 };

	const additionalInfo = () => (
		<div id='additionalInfo' className='p-2'>
			<div className='row'>
				<div className='form-group col-12 col-lg-5'>
                    <label className=''>
                        {additions.requestedAmount 
                        ? translateIfPreferedLanguageIsSpanish(additions.preferred__language,'How soon did you need the ', '¿Qué tan pronto necesitabas los ') + `${additions.requestedAmount.toLocaleString('en-US',{currency: 'USD'})}` + `?`
                        : translateIfPreferedLanguageIsSpanish(additions.preferred__language,'How soon did you need the requested funds', '¿Qué tan pronto necesita los fondos solicitados?')
                        }
                    </label>

                    <select
						onChange={onInput}
						onInput={handleChange("urgency")}
						name='urgency'
						className='form-control form-elegant text-muted'
                        value={additions.urgency}
						required
					>
						<option value='' defaultValue className='text-muted'>
                            {translateIfPreferedLanguageIsSpanish(additions.preferred__language,'Select', 'Seleccionar') }
							
						</option>
						{urgencyOptionsArr.map((ele, i) => (
							<option key={i} value={ele}>{ele}</option>
						))}
					</select>
					<small className='text-danger'>{getError('urgency')}</small>
				</div>
				<div className='form-group col-12 col-lg-7 mt-3 mt-lg-0'>
                    <label className=''>
                        {additions.purposeOfFunding 
                        ? translateIfPreferedLanguageIsSpanish(additions.preferred__language,'What is the minimum amount that would help with', '¿Cuál es la cantidad mínima que ayudaría con') + `${additions.purposeOfFunding.toLowerCase()}?`
                        : translateIfPreferedLanguageIsSpanish(additions.preferred__language,'What is the minimum that would meet your business needs?', '¿Cuál es el mínimo que cubriría las necesidades de su negocio?') }
                    </label>
                    <Cleave
						onInput={onInput}
						name='minimum_requested_amount'
						type='text'
						maxLength='10'
						placeholder='Amount ($5-$500k)'
						options={{ numeral: true, numeralThousandsGroupStyle: 'thousand', noImmediatePrefix: true, prefix: '$' }}
						onChange={handleChange("minimum_requested_amount")}
						className='form-control form-elegant text-muted'
						value={additions.minimum_requested_amount}
						required
					/>
					<small className='text-danger'>{getError('minimum_requested_amount')}</small>
				</div>
			</div>
            <div className='row'>
                <div className='form-group col-12 mt-3 mt-lg-0'>
                    <label className=''>
                        {additions.purposeOfFunding 
                        ? translateIfPreferedLanguageIsSpanish(additions.preferred__language,'Tell us more about how you will use the funds for', 'Cuéntenos más sobre cómo utilizará los fondos para') + `${additions.purposeOfFunding.toLowerCase()}?`
                        : translateIfPreferedLanguageIsSpanish(additions.preferred__language,'Tell us more about how your business will use the funds', 'Cuéntenos más sobre cómo su empresa utilizará los fondos.') }
                    </label>
                    <textarea
                        onChange={handleChange("use_of_proceeds_description")}
                        type='text'
                        name='use_of_proceeds_description'
                        className='form-control form-elegant text-muted'
                        rows="3" cols="33"
                        placeholder= {translateIfPreferedLanguageIsSpanish(additions.preferred__language,'How will your business use the funds?', '¿Cómo utilizará su empresa los fondos?')}
                        value={additions.use_of_proceeds_description}
                        required
                    >
                    </textarea>
                    <small className='text-danger'>{getError('use_of_proceeds_description')}</small>
                </div>
                <div className='form-group col-12 mt-3 mt-lg-0'>
                    <label className=''>
                        {translateIfPreferedLanguageIsSpanish(additions.preferred__language,'What are you most interested in?', '¿Qué es lo que más te interesa?')}
                    </label>
                    <div className='row'>
                        <div className='col-12 col-lg-4'>
                            <input
                                onChange={handleChange("requested_preference")}
                                type="radio"
                                id="requestedAmount"
                                name="requested_preference"
                                value="Requested Amount"
                                defaultChecked
  
                            />
                            <small htmlFor="requestedAmount">&nbsp;
                            {translateIfPreferedLanguageIsSpanish(additions.preferred__language,'Getting ', 'Conseguir ')}
                            {additions.requestedAmount ? `${additions.requestedAmount.toLocaleString('en-US',
                           { currency: 'USD'}
                        )}` : 'maximum approval'}</small>
                        </div>
                        <div className='col-12 col-lg-6'>
                            <input
                                onChange={handleChange("requested_preference")}
                                type="radio"
                                id="requestedUrgency"
                                name="requested_preference"
                                value="Requested Urgency"
                                checked = {additions.requested_preference === 'Requested Urgency' ? true : false}
                            />
                            <small htmlFor="requestedUrgency">&nbsp;{additions.urgency && additions.urgency.includes("A ") ?   translateIfPreferedLanguageIsSpanish(additions.preferred__language,'Getting funds within', 'Obtener fondos dentro') + ` ${additions.urgency.toLowerCase()}` : translateIfPreferedLanguageIsSpanish(additions.preferred__language,'Getting funded as quickly as possible', 'Obtener financiación lo más rápido posible')} </small>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='form-group col-12 col-lg-5'>
                    <label className=''>
                        {translateIfPreferedLanguageIsSpanish(additions.preferred__language,'What is the best time of day to contact you?', '¿Cuál es el mejor momento del día para contactarlo?')}
                    </label>
                    <div className='row'>
                        <div className='col-5'>
                            <select
                                onChange={onInput}
                                onInput={handleChange("preferred_contact_time")}
                                name='preferred_contact_time'
                                className='form-control form-elegant text-muted'
                                value={additions.preferred_contact_time}
                                required
                            >
                                <option value='' defaultValue className='text-muted'>
                                    {translateIfPreferedLanguageIsSpanish(additions.preferred__language,'Hour', 'Hora') }
                                </option>
                                {preferredContactTimeArr.map((ele, i) => (
                                    <option key={i} value={ele}>{ele}</option>
                                ))}
                            </select>
                        </div>
                        <div className='col-7'>
                            <select
                                onChange={onInput}
                                onInput={handleChange("preferred_contact_timeZone")}
                                name='preferred_contact_timeZone'
                                className='form-control form-elegant text-muted'
                                value={additions.preferred_contact_timeZone}
                                required
                            >
                                <option value='' defaultValue className='text-muted'>
                                      {translateIfPreferedLanguageIsSpanish(additions.preferred__language,'Time Zone', 'Zona horaria') }
                                </option>
                                {preferredContactTimeZoneArr.map((ele, i) => (
                                    <option key={i} value={ele}>{ele}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <small className='text-danger'>{getError('preferred_contact_timeZone')}</small>
                </div>
                <div className='form-group col-12 col-lg-7 mt-3 mt-lg-0'>
					<label>
                        {translateIfPreferedLanguageIsSpanish(additions.preferred__language,'What is the best way to reach you with any updates?', '¿Cuál es la mejor manera de comunicarnos con usted con actualizaciones?')}
                    </label>
                    <select
                        onChange={onInput}
                        onInput={handleChange("preferred_contact_method")}
                        name='preferred_contact_method'
                        className='form-control form-elegant text-muted'
                        value={additions.preferred_contact_method}
                        required
                    >
                        <option value='' defaultValue className='text-muted'>
                            {translateIfPreferedLanguageIsSpanish(additions.preferred__language,'Select', 'Seleccionar') }
                        </option>
                        {preferredContactMethodArr.map((ele, i) => (
                            <option key={i} value={ele}>{ele}</option>
                        ))}
                    </select>
                    <small className='text-danger'>{getError('preferred_contact_method')}</small>
			    </div>
			</div>
            <label className=''>
                {translateIfPreferedLanguageIsSpanish(additions.preferred__language,' Your company\'s online presence also improves your chances of getting a better offer by 21%', 'La presencia online de su empresa también mejora sus posibilidades de obtener una mejor oferta en un 21%')}
            </label>
            <div className='row'>
                <div className="form-group input-group col-12 col-lg-6 mt-2">
                    <div className="input-group-prepend">
                        <div className="input-group-text"><i className="fa fa-link"></i></div>
                    </div>
                    <input type="url" name='url' className="form-control" placeholder="https://your-website-address.com" value={additions.url} onChange={handleChange('url')} />
                    <small className='text-danger'>{getError('url')}</small>
                </div>

                <div className="form-group input-group col-12 col-lg-6 mt-2">
                    <div className="input-group-prepend">
                        <div className="input-group-text"><i className="fa fa-instagram"></i></div>
                    </div>
                    <input type="url" name='online_presence_instagram' className="form-control" placeholder="https://instagram.com/your-insta" value={additions.online_presence_instagram} onChange={handleChange('online_presence_instagram')} />
                    <small className='text-danger'>{getError('online_presence_instagram')}</small>
                </div>

            </div>
            <div className='row'>
                <div className="form-group input-group col-12 col-lg-6 mt-2">
                    <div className="input-group-prepend">
                        <div className="input-group-text"><i className="fa fa-facebook-square"></i></div>
                    </div>
                    <input type="url" name='online_presence_facebook' className="form-control" placeholder="https://facebook.com/your.facebook/" value={additions.online_presence_facebook} onChange={handleChange('online_presence_facebook')}  />
                    <small className='text-danger'>{getError('online_presence_facebook')}</small>
                </div>

                <div className="form-group input-group col-12 col-lg-6 mt-2">
                    <div className="input-group-prepend">
                        <div className="input-group-text"><i className="fa fa-yelp"></i></div>
                    </div>
                    <input type="url" name='online_presence_yelp' className="form-control" placeholder="Yelp/Google Reviews Link" value={additions.online_presence_yelp} onChange={handleChange('online_presence_yelp')}  />
                    <small className='text-danger'>{getError('online_presence_yelp')}</small>
                </div>
		    </div>
        </div>
	);

	return (
		serverError ? <ServerError error={serverError} /> :
        <>
            { loading && <div className='spinner'><div className='loader'>submitting...</div></div> }
            <Helmet>
                <title>{`Uplyft Capital Onboarding Merchant | Additions`}</title>
            </Helmet>
            <main className='container content layoutFading'>
                <div className='mt-5'>
                    <form noValidate onSubmit={(e) => clickSubmit(e)} className=' '>
                        <div>
                            <h2 className='pb-3'>
                            {translateIfPreferedLanguageIsSpanish(additions.preferred__language,'And Finally...', 'Y por ultimo...')}
                                </h2>
                        </div>
                        <div className='shadow p-5 mb-2 bg-white roundCorners'>
                            
                            <p>
                            {translateIfPreferedLanguageIsSpanish(additions.preferred__language,'The final details below help us stay in touch and get you funded quicker.', 'Los detalles finales que nos ayudan a mantenernos en contacto y conseguirle financiación más rápido..')}
                                 </p>
                            {additionalInfo()}
                        </div>
                        <div className= 'container d-flex justify-content-end pt-4'>
                            <button id='additionsSubmit' type='submit' className='btn btn-primary'>
                                
                                {translateIfPreferedLanguageIsSpanish(additions.preferred__language,'Finish', 'Finalizar')}
                            </button>
                        </div>
                    </form>
                </div>
            </main>
        </>
	);
};
