import React, { Component } from 'react';
// import FundingProgressBarComponent from './FundingProgressBarComponent';
import ContactSupportComponent from '../../common/ContactSupportComponent';

class ContractSuccessComponent extends Component {
    render() {
        return (
            <div className="layoutFading space">
                {/* <FundingProgressBarComponent dots={[{ name: "Contract Review", status: 1 }, { name: "Sign Contract", status: 1 }, { name: "Bank Verification", status: 1 }, { name: "Upload Docs", status: 1 }, { name: "Done", status: 1 }]} /> */}
                <div id="processDone" className='container'>
                    <div className='mb-5 relativePos'>
                        <div className='shadow pt-5 pb-3 pr-5 pl-5 mb-2 bg-white roundCorners'>
                            <div className="form__header pb-3">
                                <img className='hi5' src='/gif/uplyft_em_high5_v04_hi5.gif' alt='done' style={{ opacity: 1, width: '250px' }} />
                            </div>
                            <div className="form__header pb-3">
                                <h2 className="form__title is-done" style={{ opacity: 1 }}>Thanks!</h2>
                                <h3 className="form__subtitle is-done" style={{ opacity: 1 }}>your job here is done</h3>
                            </div>
                            <br />
                            <div className='row contact-support'>
                                <div className="container text-center">
                                    {/* <a href="tel:12396874286"><small>Click Here To Call Support 1-239-687-4286</small></a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

ContractSuccessComponent.propTypes = {
};

export default ContractSuccessComponent;