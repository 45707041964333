import React from 'react'

export default function UplyftInfoLanding() {
    return (
        <div>
            <div className="row mt-8rem">
                <div className="col-12 col-lg-6">
                    <h1>
                        Seleccione la opción que mejor se ajuste a su negocio!
                    </h1>
                    <h2 className="purple">
                        $300m a más de 4,500 negocios
                    </h2>
                </div>
            </div>
            <div className="row" >
                <div className="" style={{ position: "absolute", right: "0px", marginTop: "-24rem", zIndex: "-9998" }}>
                    <img src="/png/side-wave_001.png" alt="information icon" style={{ height: "780px" }} />
                </div>

            </div>
            <br />
            <div className="row">
                <div className="col-12 col-lg-4 mt-4">
                    <div className="form__info js-is-seen is-seen row mx-0">
                        <div className="col-3 p-0 d-flex">
                            <img style={{ height: '70px' }} src="/svg/svg_landing/decisionPoints/business_loans.svg" alt="information icon" className="" />
                        </div>
                        <div className="col-9">
                            <h5 className="item-title"><span>Préstamos de negocio</span></h5>
                            <p className="item-text">Obtén el dinero que tu negocio necesita para cualquier proyecto!</p>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-4 mt-4">
                    <div className="form__info js-is-seen is-seen row m-0">
                        <div className="col-3 p-0 d-flex">
                            <img style={{ height: '70px' }} src="/svg/svg_landing/decisionPoints/personal_loan.svg" alt="information icon" className="" />
                        </div>
                        <div className="col-9">
                            <h5 className="item-title"><span>Avance de efectivo</span></h5>
                            <p className="item-text">Depósitos realizados en menos de 24 horas a tu cuenta de negocios.</p>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-4 mt-4">
                    <div className="form__info js-is-seen is-seen row m-0">
                        <div className="col-3 p-0 d-flex">
                            <img style={{ height: '70px' }} src="/svg/svg_landing/decisionPoints/line_of_credit.svg" alt="information icon" className="" />
                        </div>
                        <div className="col-9">
                            <h5 className="item-title"><span>Lineas de crédito</span></h5>
                            <p className="item-text">Recibe una linea de crédito que puedas utilizar una y otra vez!</p>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
        </div>

    );
};
