import React from 'react'

export default function Home() {
    return (
       
        <div className='space'>
            <div className='container'>
                <div className='mb-5'>
                    <div className='shadow p-5 mb-2 bg-white roundCorners'>
                        <div className="form__header">
                            <h3 className="form__title is-done" style={{opacity: 1}}>Sorry</h3>
                            <h4 className="form__subtitle is-done pt-3" style={{opacity: 1}}>Please click <em><a href= "https://uplyftcapital.com/" style={{color:"#927dff"}}>here</a> </em>to return home</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
