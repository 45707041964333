import React, { Component, } from "react";
import { Redirect, Link } from "react-router-dom";
import axios from 'axios'
import Dropzone from "./Dropzone";
import "../Upload/upload.css";
import Progress from "./Progress";
import config from '../../../../../config';
import { Helmet } from "react-helmet";
import AlertMessage from "../AlertMessage";
import ServerError from "../ServerError";




class Upload extends Component {

	constructor(props) {
		super(props);
		this.state = {
			files: [],
			uploading: false,
			uploadProgress: {},
			successfullyUploaded: false,
			redirect: false,
			opportunity: '',
			error: false,
			unSupportedFiles: [],
			display: false,
			message: ''
			
			
		};
	};

	dots = [
		{ name: "Funding", status: 1 },
		{ name: "Business", status: 1 },
		{ name: "", status: 1 },
		{ name: "Personal", status: 1 },
		{ name: "", status: 1 },
		{ name: "Signature", status: 1 },
		{ name: "Upload", status: 1 }
	];



	componentDidMount(){
		
		//params taken from url
		const { accountUuid, contactUuid, opportunityUuid } = this.props.match.params;

		this.setState({uploading:true})
		let url = `${config.apiUrl}/api/uplyftOnboarding/${accountUuid}/accountValidate/${contactUuid}/${opportunityUuid}`
		axios.get(url)
			.then((res) => this.setState({uploading:false}))
			.catch( error => {
				this.setState({
					error:{
						message:
							<>Link expired &nbsp;&nbsp; <i class="fa fa-unlink"></i>
								<br/>You are probably using and old link to upload files.
								<br/>Please contact one of our funding advisors to have them resend a new link<br/><br/><a href="mailto:sales@uplyftcapital.com"><p style={{color:"#6b1cf5"}}>Click here to email us: sales@uplyftcapital.com</p></a>
							</>
					}})
				console.error(error);
			});
	};

	//Check if the file have the allowed extension
	checkFilesMIME = file => {
		if (file.type === 'application/pdf' || file.type === 'image/jpeg' || file.type === 'image/png') {
			return true
		} else {
			return false
		}
	}

	dateFormat = () => {
		// Formatting date result: YYYYMMDD
		let today = new Date();
		let dateFormatConstractor = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'numeric', day: '2-digit' });
		let [{ value: month },,{ value: day },,{ value: year }] = dateFormatConstractor.formatToParts(today);
		let formatDate = `${year}-${month}-${day}`.replace(/[^\w\\.]+/g, "");
		return formatDate;
	}

	// Add file prefix (YYYYMMDD-<6-digit randome string>-fileOriginalName)
	// Set the state with the new files
	onFilesAdded = (files) => {
		let newFiles = files.map(file => {
			let todayDate = this.dateFormat();
			let randomNum = Math.random().toString(36).substring(2,8);
			let data = new File([file], `${todayDate}-${randomNum}-${file.name}`, { type: file.type });
			return data;
		});
		this.setState(prevState => ({
			files: prevState.files.concat(newFiles),
		}));
	};

	prepareFilesForUploading = async (files) => {
		let newFilesArray = [];
		let unSupportedFilesArr = [];
		files.forEach(file => {
			this.checkFilesMIME(file) ? newFilesArray.push(file) : unSupportedFilesArr.push(file);
		});
		this.setState({ ...this.state, files: newFilesArray, unSupportedFiles: unSupportedFilesArr });

		if( newFilesArray.length > 0 && unSupportedFilesArr.length > 0 ){
			this.setState({
			   display: true,
			   message: {
				   title: 'Unsupported File Type',
				   body: 'The files below are not supported and will be removed. Try to upload PDF, JPG, PNG instead'
			   }
		   });
	   } else if( newFilesArray.length === 0 && unSupportedFilesArr.length > 0) {
			this.setState({
			   display: true,
			   message: {
				   title: 'Unsupported File Type',
				   body: 'Please upload one of the supported extensions: PDF, PNG, JPG'
			   }
		   });
	   } else if( newFilesArray.length === 0 && unSupportedFilesArr.length === 0) {
			this.setState({
			   display: true,
			   message: {
				   title: 'Missing Documents',
				   body: 'No files uploaded. Please select the files requested and try your upload again'
			   }
		   });
		} else {
			this.uploadFiles();
		};
	};

	uploadFiles = async e => {
		const promises = [];
		const { accountUuid, opportunityUuid } = this.props.match.params;
		let rollQuery = window.location.search;
		await this.setState({ uploadProgress: {}, uploading: true, display: false });
		this.state.files.forEach(file => {
			promises.push(this.sendRequest(file));
		});
		try {
			let response = '';
			response = await Promise.all(promises);
			if ( response && response !== undefined ){
				let uploadedFiles = [];
				let uploadProgressArray = Object.entries(this.state.uploadProgress);
				uploadProgressArray.forEach( ele =>{
					ele[1].state === 'done' && uploadedFiles.push(`${ele[0]}`);
					ele[1].state === 'failed' && uploadedFiles.push(`${ele[0]} FAILED TO UPLOAD`);
				});

				this.setState({ successfullyUploaded: true, uploading: false });
				axios.post(`${config.apiUrl}/api/uplyftOnboarding/${accountUuid}/uploadDocsNotificationEmail/${opportunityUuid}${rollQuery}`, { files: uploadedFiles })
				setTimeout(() => {
					this.setState({ redirect: true });
				}, 1000);
			};
		} catch (err) {
			this.setState({ successfullyUploaded: false, uploading: false });
			console.error(`Unable to save record(s): ${err.message}`);
			this.setState({
				error: { message: 'Something went wrong while trying to upload your documents, please contact one of our funding advisors '}
			});
		};
	};

	sendRequest = async file => {
		const { accountUuid, opportunityUuid } = this.props.match.params;
		const formData = new FormData();
		formData.append("file", file, file.name );
		const axiosRes = await axios.post(`${config.apiUrl}/api/uplyftOnboarding/${accountUuid}/upload/${opportunityUuid}`, formData, {
			onUploadProgress: (progressEvent) => {
				const totalLength = progressEvent.lengthComputable && progressEvent.total
				if (totalLength !== null) {
					let copyUploadProgress = { ...this.state.uploadProgress };
					copyUploadProgress[file.name] = {
						state: "pending",
						percentage: Math.round((progressEvent.loaded * 100) / totalLength)
					};
					this.setState({ uploadProgress: copyUploadProgress });
				}
			},
			validateStatus: function (status) {
                return status >= 200 && status < 400;
            }
		});

		if ( axiosRes.status !== 200 ){
			let copyUploadProgress = { ...this.state.uploadProgress };
			copyUploadProgress[file.name] = { state: "failed", percentage: 0 };
			this.setState({ uploadProgress: copyUploadProgress });
		} else {
			let copyUploadProgress = { ...this.state.uploadProgress };
			copyUploadProgress[file.name] = { state: "done", percentage: 100 };
			this.setState({ uploadProgress: copyUploadProgress });
			console.error(axiosRes.data.error);
		};
		return axiosRes;
	};

	removeFileFromArray = (index) => {
		const newFiles = [...this.state.files]
		newFiles.splice(index, 1);
		if (this.state.files.length === 1) {
			this.setState({ files: newFiles, successfullyUploaded: false });
		} else {
			this.setState({ files: newFiles });
		};
	};


	renderProgress = (file, index) => {
		const uploadProgress = this.state.uploadProgress[file.name];
		let progress = uploadProgress ? uploadProgress.percentage : 0 ;
		return (
			<div className='ProgressWrapper'>
				<Progress progress={ progress} />
				{ uploadProgress && uploadProgress.state === "done" ?
					<img
						className='CheckIcon' src='/svg/checkmark.svg' alt='done' style={{ opacity: uploadProgress && uploadProgress.state === "done" ? 1 : 0 }} />
					:
					this.checkFilesMIME(file) ?
						<>
							{ progress === 0 ?
								<img className='CheckIcon' src='/svg/readyForUpload.svg' alt='done' style={{ opacity: 1 }} />
								:

								<img className='CheckIcon' src='/svg/uploading.svg' alt='done' style={{ opacity: 1 }} />
							}
						</>
						:
						<img className='CheckIcon'	src='/svg/redXmark.svg'	alt='wrong-mime' style={{ opacity: 1 }}	/>
				}
				<img onClick={() => this.removeFileFromArray(index)} className='cta__button' src='/svg/Deleted-icon.svg' alt='delete-icon' style={{	opacity: uploadProgress && uploadProgress.state === "done" ? 0 : 1	}}	/>
			</div>
		);
	};

	renderActions = () => {
		if ( this.state.files.length > 1) {
			return <button type='button' className='upBtn' onClick={() => this.setState({ files: [], successfullyUploaded: false })}>
				Clear all
			</button>
		};
	};

	toggleAlertMessage = () =>{
		this.setState({ display: !this.state.display, message:'', unSupportedFiles: [] });
	}

	uploadForm = () => (
		<main id='uploadFiles' className='container layoutFading mt-5 space'>
			<div className='mb-2'>
				<div className='shadow p-5 mb-2 bg-white roundCorners'>
					<div>
						<h2 className='pb-3'>We are almost there...</h2>
					</div>
					<span>Securely upload the requested files below</span>
					<div className='Upload'>
						<div className='Content'>
							<Dropzone onFilesAdded={this.onFilesAdded} />
						</div>
						<div className={this.state.files.length ? 'Content' : 'd-none'} >
							<div className='Files'>
								<div className='Actions'>{this.renderActions()}</div>
								{this.state.files.map((file, index) => {
									let fileName = this.checkFilesMIME(file) ? file.name : <span className='text-danger flashing'> {file.name + '  -- Please upload PDF, JPG or PNG Only'}</span>
									return (
										<div key={index + file.name} className='Row'>
											<span className='Filename'>{fileName}</span>
											{this.renderProgress(file, index)}
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div id='controlBtn' className='container d-flex justify-content-between pt-2'>
				<Link to='#'></Link>
				<button type='submit' onClick={() => this.prepareFilesForUploading(this.state.files)} className='btn btn-primary'>
					Upload Files
				</button>
			</div>
		</main>
	);

	render() {
		const divStyle = () => {
			if( this.state.uploading  || this.state.display ) return { "position": 'relative', "opacity": 0.5, "zIndex": -10 }
		};

		const dataAlert = {
			alertFiles: {
				valid: this.state.files,
				unValid: this.state.unSupportedFiles,
			},
			alertMessage: this.state.message,
			alertDisplay: this.state.display,
			cb: {
				toggleAlert: this.toggleAlertMessage,
				upload: this.uploadFiles
			}
		};
		return (
			<>
				<Helmet><title>Uplyft Capital Onboarding Merchant | Upload-Documents</title></Helmet>

				{ this.state.redirect && <Redirect to='/onboarding/merchant/process-done' /> }
				{/* { this.state.error && <Redirect to='/onboarding/merchant/404' /> } */}
				{this.state.error && <ServerError error={this.state.error} />}

				{ !this.state.error && (
					this.state.uploading ?
					<>
						<div className='spinner'>
							<div className='loader'>submitting...</div>
						</div>
						<div >
							<div  style={ divStyle() } >{ this.uploadForm() }</div>
						</div>
					</>
					:
					<>
						{this.state.display && <div className ='alert_animation'><AlertMessage data = {dataAlert} /></div> }
						<div style={ divStyle() }> { this.uploadForm() }</div>
					</>
				)}
			</>
		);
	};
};

export default Upload;
