import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { Provider } from 'react-redux';
import store, { history } from './store';
import { Route, Router } from 'react-router-dom';
import './app/App.css';
import App from './app/App';
import * as serviceWorker from './serviceWorker';



ReactDOM.render((
    <Provider store={store}>
        <Router history={history}>
            <Route path="/" component={App} />
        </Router>
    </Provider>
), document.getElementById('root'));

serviceWorker.unregister();
