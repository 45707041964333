import React from "react";

export default function Copyright() {
	return (
		<div style={{ height: "auto" }}>
				<span style={{ fontSize: "10px", color: "rgb(0, 0, 0)" }}>
					Copyright &copy; {new Date().getFullYear()} All Rights Reserved by Uplyft Capital &nbsp; •
					<a href='https://uplyftcapital.com/terms-of-use/'><span style={{ fontSize: "10px", color: "rgb(0, 0, 0)", textDecoration: "underLine" }}>Terms of Use</span></a>
					&nbsp; •
					<a href='https://uplyftcapital.com/privacy-policy/'><span style={{ fontSize: "10px", color: "rgb(0, 0, 0)", textDecoration: "underLine" }}>Privacy Policy</span></a>
					&nbsp; •
					Call: 1(800) 515-7531 &nbsp; • &nbsp; 17070 Collins Ave Suite #250, Sunny Isles Beach, FL 33160-3635
				</span>
		</div>
	);
}