import React, {useEffect} from "react";
import Layout from "./Layout";

export default function ThankYou() {
    useEffect( () => {
        sessionStorage.clear()
    });

    return (
        <div style={{ marginTop: '50px' }}>
            <Layout title='' preRouteText='Back Home' preRoute='' preRouteClass='btn btn-light' btnContinueText='back home' showBtnContinue='hidden' >
                <h2 className='vCenter'>Oh no! Page not found.</h2>
            </Layout>
        </div>
    );
}
