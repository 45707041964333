import React, { useState, useEffect, useContext } from "react";
import { Redirect } from "react-router-dom";
import axios from 'axios';
import config from '../../../../config';
import Layout from "./Slayout";
// import Cleave from 'cleave.js/react'
import MaskedInput from 'react-text-mask'
import { StateContext } from '../context/StateContext'
import StepProgressBar from "../../application/components/StepProgressBar";


export default function Contact(props) {
    const [redirect, setRedirect] = useState(false);
    const [formError, setFormError] = useState({});

    const { state, handleChange } = useContext(StateContext);
    const { account__name, account__address__address1, account__address__address2, account__address__city, account__address__state, account__address__zip, account__message } = state;

    const dots = [
        { name: "Contact", status: 1 },
        { name: "Address", status: 1 },
        { name: "Done", status: 0 }
    ];

    const usStatesList = [
        { name: 'Alabama', abbreviation: 'AL' },
        { name: 'Alaska', abbreviation: 'AK' },
        { name: 'Arizona', abbreviation: 'AZ' },
        { name: 'Arkansas', abbreviation: 'AR' },
        { name: 'American Samoa', abbreviation: 'AS' },
        { name: 'California', abbreviation: 'CA' },
        { name: 'Colorado', abbreviation: 'CO' },
        { name: 'Connecticut', abbreviation: 'CT' },
        { name: 'Delaware', abbreviation: 'DE' },
        { name: 'DC', abbreviation: 'DC' },
        { name: 'Micronesia', abbreviation: 'FM' },
        { name: 'Florida', abbreviation: 'FL' },
        { name: 'Georgia', abbreviation: 'GA' },
        { name: 'Guam', abbreviation: 'GU' },
        { name: 'Hawaii', abbreviation: 'HI' },
        { name: 'Idaho', abbreviation: 'ID' },
        { name: 'Illinois', abbreviation: 'IL' },
        { name: 'Indiana', abbreviation: 'IN' },
        { name: 'Iowa', abbreviation: 'IA' },
        { name: 'Kansas', abbreviation: 'KS' },
        { name: 'Kentucky', abbreviation: 'KY' },
        { name: 'Louisiana', abbreviation: 'LA' },
        { name: 'Maine', abbreviation: 'ME' },
        { name: 'N.Marshall Islands', abbreviation: 'MH' },
        { name: 'Maryland', abbreviation: 'MD' },
        { name: 'Massachusetts', abbreviation: 'MA' },
        { name: 'Michigan', abbreviation: 'MI' },
        { name: 'Minnesota', abbreviation: 'MN' },
        { name: 'Mississippi', abbreviation: 'MS' },
        { name: 'Missouri', abbreviation: 'MO' },
        { name: 'Montana', abbreviation: 'MT' },
        { name: 'Nebraska', abbreviation: 'NE' },
        { name: 'Nevada', abbreviation: 'NV' },
        { name: 'New Hampshire', abbreviation: 'NH' },
        { name: 'New Jersey', abbreviation: 'NJ' },
        { name: 'New Mexico', abbreviation: 'NM' },
        { name: 'New York', abbreviation: 'NY' },
        { name: 'North Carolina', abbreviation: 'NC' },
        { name: 'North Dakota', abbreviation: 'ND' },
        { name: 'N.Mariana Islands', abbreviation: 'MP' },
        { name: 'Ohio', abbreviation: 'OH' },
        { name: 'Oklahoma', abbreviation: 'OK' },
        { name: 'Oregon', abbreviation: 'OR' },
        { name: 'Palau', abbreviation: 'PW' },
        { name: 'Pennsylvania', abbreviation: 'PA' },
        { name: 'Puerto Rico', abbreviation: 'PR' },
        { name: 'Rhode Island', abbreviation: 'RI' },
        { name: 'South Carolina', abbreviation: 'SC' },
        { name: 'South Dakota', abbreviation: 'SD' },
        { name: 'Tennessee', abbreviation: 'TN' },
        { name: 'Texas', abbreviation: 'TX' },
        { name: 'Utah', abbreviation: 'UT' },
        { name: 'Vermont', abbreviation: 'VT' },
        { name: 'Virginia', abbreviation: 'VA' },
        { name: 'Virgin Islands', abbreviation: 'VI' },
        { name: 'Washington', abbreviation: 'WA' },
        { name: 'West Virginia', abbreviation: 'WV' },
        { name: 'Wisconsin', abbreviation: 'WI' },
        { name: 'Wyoming', abbreviation: 'WY' },
    ]


    useEffect(() => { })

    const formValidation = (e) => {
        let form = e.target
        let isValid = form.checkValidity()
        let formData = new FormData(form)
        let validationMessages = Array.from(formData.keys()).reduce((acc, key) => {
            acc[key] = form.elements[key].validationMessage
            return acc
        }, {})

        setFormError(validationMessages)
        return isValid
    }

    const onInput = (e) => {
        const input = e.target
        // const isValid = input.checkValidity
        setFormError({ ...formError, [input.name]: input.validationMessage })
    }

    const getError = (field) => formError[field]

    const clickSubmit = e => {
        e.preventDefault();
        console.log(formError)

        let isValid = formValidation(e)
        if (isValid) {
            sendPostRequest(state)
            setRedirect({ redirect: true })
        }
    };

    const sendPostRequest = (mainState) => {
        axios.post(`${config.apiUrl}/api/uplyftOnboarding/syndicator`, { mainState })
            .then(res => {
                console.log(res.data)
            })
            .catch(error => {
                console.log('error', error)
            })
    };


    const personalForm = () => (
        <div id='syndicator-account' className=''>
            <div className='form-group pl-0 col-12 col-lg-6'>
                <label className=''>Organization Name</label>
                <input
                    onInput={onInput}
                    onChange={handleChange("account__name")}
                    type='text'
                    name='account__name'
                    className='form-control form-elegant text-muted'
                    placeholder='Organization Name'
                    value={account__name}
                    required
                />
                <small className={formError['account__name'] ? 'text-danger' : 'd-none'} >{getError('account__name')}</small>
            </div>
            <div className='row'>
                <div className='form-group col-12 col-lg-6'>
                    <label className=''>Address</label>
                    <input
                        onInput={onInput}
                        onChange={handleChange("account__address__address1")}
                        type='text'
                        name='account__address__address1'
                        className='form-control form-elegant text-muted'
                        placeholder='Address'
                        value={account__address__address1}
                        required
                    />
                    <small className={formError[account__address__address1] ? 'text-danger' : 'd-none'}>{getError('account__address__address1')}</small>
                </div>
                <div className='form-group col-12 col-lg-6'>
                    <label className=''>Address 2</label>
                    <input
                        onInput={onInput}
                        onChange={handleChange("account__address__address2")}
                        type='text'
                        name='account__address__address2'
                        className='form-control form-elegant text-muted'
                        placeholder='Suite'
                        value={account__address__address2}
                        required
                    />
                    <small className='text-danger'>{getError('account__address__address2')}</small>
                </div>
            </div>
            <div className='row'>
                <div className='form-group col-6 col-lg-4'>
                    <label className=''>City</label>
                    <input
                        onInput={onInput}
                        onChange={handleChange("account__address__city")}
                        type='text'
                        name='account__address__city'
                        className='form-control form-elegant text-muted'
                        placeholder='City'
                        value={account__address__city}
                        required
                    />
                    <small className='text-danger'>{getError('account__address__city')}</small>
                </div>
                <div className='form-group col-6 col-lg-4'>
                    <label className=''>State</label>
                    <select
                        onChange={onInput}
                        onInput={handleChange("account__address__state")}
                        name='account__address__state'
                        value={account__address__state}
                        className='form-control form-elegant text-muted'
                        required
                    >
                        <option value='' defaultValue className='text-muted'>
                            Select Your State
                        </option>
                        {config.usStatesList.map((state, i) => (
                            <option key={i} value={state.abbreviation}>{state.name}-{state.abbreviation}</option>
                        ))}
                    </select>
                    <small className='text-danger'>{getError('account__address__state')}</small>
                </div>
                <div className='form-group col-6 col-lg-4 '>
                    <label className=''>Zip Code</label>
                    <MaskedInput
                        onInput={onInput}
                        onChange={handleChange("account__address__zip")}
                        // options={{ numericOnly: true, blocks: [5] }}
                        mask={[/[0-9]/, /\d/, /\d/, /\d/, /\d/]}
                        guide={true}
                        type='text'
                        name='account__address__zip'
                        className='form-control form-elegant text-muted'
                        pattern='^\d{5}$' title='Zip code should contain 5 digits'
                        placeholder='00000'
                        minLength='5'
                        value={account__address__zip}
                        required
                    />
                    <small className='text-danger'>{getError('account__address__zip')}</small>
                </div>
                <div className='form-group col-12'>
                    <label className='pb-2'>Message</label>
                    <textarea
                        onChange={handleChange("account__message")}
                        type='text'
                        name='account__message'
                        className='form-control form-elegant text-muted'
                        rows="5" cols="33"
                        placeholder='Write your message here'
                        value={account__message}
                    >
                    </textarea>
                </div>
            </div>
        </div >
    );

    return (
        <>
            {redirect && <Redirect to='/onboarding/syndicator/thank-you' />}
            <StepProgressBar dots={dots} />

            <Layout
                title='Send us a message'
                htmlTitle='Account'
                preRoute='contact'
                showPreButton=''
                preRouteClass='btn btn-light'
                className='p-5'
                invokeFunction={clickSubmit}
            >
                {personalForm()}
            </Layout>
        </>
    );
}
