import React, { useState, useEffect } from "react";
import { Redirect, useParams, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from 'axios'
import config from '../../../../../config';

import SignPad from './Signature';
import Template from './Review';
import ServerError from "../ServerError";

import "../Esign/eSign.style.css";
import OneTimeAuthorization from "./OneTimeAuthorization";

export default function Doc({internalIp}) {
    const [serverResponse, setServerResponse] = useState({ loading: false, delayResponse: true});
    const [authorization, setAuthorization] = useState({date:'1900-01-01', time:'00:00', confirmed: false});
   
    const { status, loading, delayResponse, data } = serverResponse;
    const params = useParams();
    const history = useHistory();

    const dataFetch = async (url) => (
        await axios.get(url)
            .then( res => { 
                console.log(res)
                setServerResponse({ ...serverResponse, status: res.status, delayResponse: false, data: res.data }) 
            })
            // .then( setTimeout(() => { setServerResponse({...serverResponse, status: 200, delayResponse: false})}, 3000) )
            .catch (error => {
                error.title = "We're Sorry...";
                error.message = <>Link expired &nbsp;&nbsp; <i class="fa fa-unlink"></i>
                        <br/>Application Already Signed. 
                        <br/>Please contact one of our funding advisors to have them resend a new link<br/><br/><a href="mailto:sales@uplyftcapital.com"><p style={{color:"#6b1cf5"}}>Click here to email us: sales@uplyftcapital.com</p></a>
                    </>;
                console.error(error); 
                setServerResponse(error)
            })
    );

    useEffect(() => {
        params && dataFetch(`${config.apiUrl}/api/uplyftOnboarding/${params.accountUuid}/docsign/${params.contactUuid}/${params.opportunityUuid}`);
    }, []);

	const clearHistoryAndPush = () => {
		history.entries = [];
		history.index = -1;
		history.push(`/onboarding/merchant?ac=${params.accountUuid}&co=${params.contactUuid}&op=${params.opportunityUuid}`);
    };
    
    const handleAuthorizationChange = name => event => {
        console.log('in auth')
        let eventTarget = event.target.value;
        setAuthorization({...authorization, [name]: eventTarget });
    };

    return (
        <>
            <Helmet>
                <title>Uplyft Capital Onboarding Merchant | Update-Signature</title>
            </Helmet>
            {/* {redirect.next && <Redirect to={redirect.url} />} */}

            {
                delayResponse
                    ?
                    <div className='spinner'>
                        <div className='loader'>submitting...</div>
                    </div>
                    :
                    status !== 200 ? <ServerError error={serverResponse} />
                        :
                        <>
                            { loading && <div className='spinner'><div className='loader'>submitting...</div></div> }
                            <div style={ loading ? { position: 'relative', opacity: 0.3, zIndex: -10} : {} }>
                                <Template data={data} clearHistoryAndPush={clearHistoryAndPush} />
                                { internalIp ? authorization.confirmed ?  <SignPad data={data} authorization={authorization} /> : <OneTimeAuthorization handleAuthorizationChange={handleAuthorizationChange} authorization={authorization} setAuthorization={setAuthorization}/>
                                :
                                <SignPad data={data} />
                            }
                            </div>
                        </>
            }
        </>
    );
}
