import React, { Component } from 'react';
import { connect } from 'react-redux';
import { history } from '../../../store';
import { Helmet } from "react-helmet";
import './VerifyContract.css';
import FundingProgressBarComponent from '../common/FundingProgressBarComponent';
import ContactSupportComponent from '../common/ContactSupportComponent';

class VerifyContractDoneComponent extends Component {
    controlButtons = () => (
        <div className='container d-flex justify-content-between pt-2'>
            <button className='btn btn-primary display' style={{ visibility: "hidden" }}></button>
            { this.props.eventListener === 'Signed' ?
                '':
                <button type="button" className='btn btn-primary display' style={{ visibility: "visible" }} onClick={ (e) => this.handleSignContractError(e)}> Go Back</button>
            }
            {this.props.eventListener === 'Signed'?
                '':
                <button type="button" className='btn btn-primary isDone' style={{ visibility: "visible" }} onClick={ (e) => this.handleSignContractError(e)}> Go Back</button>
            }
        </div>
    );

    handleSignContractError = (e) => {
        e.preventDefault();
        history.push('/funding/verify-contract/' + this.props.match.params.submissionUuid + '/' + this.props.match.params.verifierUuid);
    }
    
    createMessage = (event) => {
        let message = {};
        switch (event){
            case 'Signed': {
                    message.title = 'Thank You';
                    message.description = 'The contract has been signed and verified successfully. The completed contract should be available for download in a few minutes.';
                    break;
                }
            case 'Canceled': {
                message.title = 'You Canceled the Signing process';
                message.description = 'Please complete it!';
                break;
            }  
            case 'Error': {
                message.title = 'Oops, an error occurred';
                message.description = 'Please Try again';
                
                break;
            }
            default:
                message.title = 'Oops, an error occurred';
                message.description = 'Please Try again';
        }
        return message;
    }

	render() {
		if (this.props.eventListener !== '') {
            let message = this.createMessage(this.props.eventListener);
			return (
                <>
                    <Helmet>
                    	<title>Uplyft Capital Funding | Verify-Contract-Done</title>
                	</Helmet>
                    <div className="layoutFading space">
                        <FundingProgressBarComponent dots={[{ name: "Contract Verification", status: 1 }, { name: "Done", status: 1 }]} />
                        <div className='container pt-2 space'>
                            <div className='shadow p-5 mb-2 bg-white roundCorners'>
                                <div className="form__header pb-3">
                                    <h2 className="form__title is-done" style={{opacity: 1}}>{message.title}</h2>
                                    <h3 className="form__subtitle is-done" style={{opacity: 1}}>{message.description}</h3>
                                </div>
                                <div className='display'><ContactSupportComponent/></div>
                            </div>
                            {this.controlButtons()}
                            <div className='isDone pt-3'><ContactSupportComponent/></div>
                        </div>
                    </div>
                </>
			);
		} else {
			return (
				<div className='spinner'>
					<div className='loader'>Loading...</div>
				</div>
			);
		}
	}
}

VerifyContractDoneComponent.propTypes = {
};

const mapStateToProps = state => ({
	eventListener: state.verifyContract.verifyContractStatus.eventListener,
});

export default connect(mapStateToProps)(VerifyContractDoneComponent);