import React, { Component } from 'react';

class FundingProgressBarComponent extends Component {
    render() {
        return (
            <div id='StepProgressBar' className='display'>
                <div className='container display'>
                    <div className='form__pages__index '>
                        {this.props.dots.map((dot, i) => {
                            return (
                                <a href="#!" key={i} className={dot.status ? "form__pages__link is-active" : "form__pages__link "} data-form-goto="0">
                                    <span className="form__pages__label" >{dot.name}</span>
                                </a>
                            )
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

FundingProgressBarComponent.propTypes = {
};

export default FundingProgressBarComponent
