import axios from 'axios';
import actions from './actions';
import config from '../../../../config/index';
const _ = require('lodash');

const prepareContractAction = actions.Creators.prepareContract;
const onSigningEventAction = actions.Creators.onSigningEvent;
const handleSignContractErrorAction = actions.Creators.handleSignContractError;

const prepareContract = (contactUuid, submissionUuid) => async dispatch => {
	let params = {};
	params.submissionUuid = submissionUuid;
	const response = await axios.post(config.apiUrl + '/fundingPlatformPublic/signContract/prepareContract', params);
	let contactSigningStatus = _.keyBy(response.data.contactsSigningStatus, 'contactUuid')[contactUuid];
	contactSigningStatus.eventListener = '';
	try {
        dispatch(prepareContractAction({contactsSigningStatus: response.data.contactsSigningStatus, contactSigningStatus: contactSigningStatus, verifyContractStatus: response.data.verifyContractStatus}));
	} catch(error) {
		dispatch(error);
	}
    return;
}

const onSigningEvent = (params) => async dispatch => {
	let contactUuid = params.contactUuid;
	const response = await axios.post(config.apiUrl + '/fundingPlatformPublic/signContract/onSigningEvent', params);
	let contactSigningStatus = _.keyBy(response.data.contactsSigningStatus, 'contactUuid')[contactUuid];
	contactSigningStatus.eventListener = response.data.event
	try {
        dispatch(onSigningEventAction({contactsSigningStatus: response.data.contactsSigningStatus, contactSigningStatus: contactSigningStatus}));
	} catch(error) {
		dispatch(error);
	}
    return;
}
const handleSignContractError = () => async dispatch => {
	try {
        dispatch(handleSignContractErrorAction());
	} catch(error) {
		dispatch(error);
	}
    return;
}

export default {
    prepareContract,
	onSigningEvent,
	handleSignContractError
  }