import React from 'react';
import './alertMessage.css';


export default function AlertMessage({ data }) {
   
    return (
        <div className = {`alert-wrapper`} >
            <div className = "alert" >
                <div className="close-mark" onClick={ () => data.cb.toggleAlert()}>
                    <img className='CheckIcon'	src='/svg/redXmark.svg'	alt='wrong-mime' style={{ opacity: 1 }}	/>
                </div>
                    <h5>{data.alertMessage.title}</h5>
                    <h6>{data.alertMessage.body}</h6>
                    <div className='alertFiles'>
                        <ul>
                            { data.alertFiles.unValid.map( (ele, i) => {
                                let fileName = ele.name;
                                return (
                                    <li key={i + ele.name}>
                                        <span className='Filename'>{fileName}</span>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                    <div className='pt-4'>
                        {data.alertFiles.valid.length > 0 && 
                            <button className='btn btn-warning' type='submit' onClick={() => data.cb.upload()} >
                                <b>Upload & finish</b>
                            </button>
                        }
                    </div>
            </div>
        </div>
    );
};