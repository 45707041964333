import React, { useState, useEffect, createContext } from 'react';
import config from '../../../config';
import { useHistory } from "react-router-dom";
import TagManager from 'react-gtm-module'
import axios from 'axios';
// import uuidv4 from 'uuid/v4';

export const DocumentSignatureStateContext = createContext();

export default function DocumentSignatureStateContextProvider(props) {

    const [documentData, setDocumentData] = useState({
       dummyData: 'testData',
       dummyData2: 'testData',
       isLoaded: false,

    });



    return (
        // <DocumentSignatureStateContext.Provider value={{ setMainState, setData, mainState, handleChange, prePopulateMainState, data, addPropertyState, initializeState, natureOfBusiness, corporateStructures, gtm_DataLayer, populateCampaignVariation, lang, setLang, validatePhoneNumber }}>
        <DocumentSignatureStateContext.Provider value={{ documentData, setDocumentData }}>
            {props.children}
   
        </DocumentSignatureStateContext.Provider>
    )
}
