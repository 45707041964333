import { createReducer } from 'reduxsauce'
import Types from './types'

// the initial state of this reducer
export const INITIAL_STATE = {
	submissionUuid: ''
	, account: {}
	, opportunity: {}
	, contacts: []
}

// Get Initial Data
export const getBankVerificationInitialData = (state = INITIAL_STATE, action) => {
    return {
        ...state
        , submissionUuid: action.initialData.submissionUuid
        , account: action.initialData.account
        , opportunity: action.initialData.opportunity
    }
}

// map our action types to our reducer functions
export const HANDLERS = {
    [Types.GET_BANK_VERIFICATION_INITIAL_DATA]: getBankVerificationInitialData,
  }
  
  export default createReducer(INITIAL_STATE, HANDLERS)