import React, { useState, useEffect, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import Layout from "./Layout";
import Cleave from 'cleave.js/react';
import { MerchantStateContext } from '../context/MerchantStateContext';
//npm pack => https://www.npmjs.com/package/query-string
import QueryString from 'query-string';
import ServerError from "./ServerError";

import { returnTrueIsSpanishIsPrefered , translateIfPreferedLanguageIsSpanish } from '../../../../lib/util';



export default function Funding() {
	const [formError, setFormError] = useState({})
	const { setMainState, setData, mainState, data, handleChange, prePopulateMainState, populateCampaignVariation, populateCampaignRecordId } = useContext(MerchantStateContext);
	const { opportunity__requestedAmount, opportunity__revenue, opportunity__purposeOfFunding } = mainState;
	const [serverError, setServerError] = useState(false);

	const history = useHistory();
	const location = useLocation();
	const referrerCampaign = (location) => {
		const params = QueryString.parse(location.search); // this broke.., or this lib

		if (params) {
			populateCampaignVariation(params);
			populateCampaignRecordId(params);
			//if(params.campaignVariation || params.campaignVariation) handleDataChange(params)
			if (params.ac && params.co && params.op) {
				prePopulateMainState(params)
			} else if(params.campaign || params.e){
				sessionStorage.setItem("data", JSON.stringify({ ...data, campaign: params.campaign, campaignVariation: params.e }));
				setData({...data, campaign:params.campaign, campaignVariation: params.e })
			};
		};
	};

	useEffect(() => {
		referrerCampaign(location);
	}, [location])

	useEffect(() => {
		if(sessionStorage['mainState'] ){
			let tempState = JSON.parse(sessionStorage.getItem("mainState"));
			setMainState({
				...tempState
				,opportunity__requestedAmount: tempState.opportunity__requestedAmount
				,opportunity__revenue: tempState.opportunity__revenue
				,opportunity__purposeOfFunding: tempState.opportunity__purposeOfFunding
			});
		
		};
	}, [])

	const dots = [
		
		{ name: "Funding", status: 1 },
		{ name: "Business", status: 0 },
		{ name: "", status: 0 },
		{ name: "Personal", status: 0 },
		{ name: "", status: 0 },
		{ name: "Signature", status: 0 },
		{ name: "Upload", status: 0 }
	];

	const dotsInSpanish = [
		{ name: "Financiamiento", status: 1 },
		{ name: "Negocio", status: 0 },
		{ name: "", status: 0 },
		{ name: "Personal", status: 0 },
		{ name: "", status: 0 },
		{ name: "Firma", status: 0 },
		{ name: "Documentos", status: 0 }
	];

	const formValidation = (e) => {
		let form = e.target
		let isValid = form.checkValidity()
		let formData = new FormData(form)
		let validationMessages = Array.from(formData.keys()).reduce((acc, key) => {
			acc[key] = form.elements[key].validationMessage
			return acc;
		}, {})
		setFormError(validationMessages);
		return isValid;
	}

	const onInput = (e) => {
		setFormError({ ...formError, [e.target.name]: e.target.validationMessage })
	}

	const getError = (field) => formError[field]

	const clickSubmit = e => {
		e.preventDefault();
		let isValid = formValidation(e)
		if (isValid){
			sessionStorage.setItem("mainState", JSON.stringify(mainState));
			history.push('/onboarding/merchant/business');
		}
	};

	const purposeFunding = [
		"Expansion",
		"Funding Existing Project",
		"Payroll",
		"Paying Outstanding Debt",
		"Consolidation",
		"Starting a New Venture",
		"Improving Cash Flow",
		"Adding Inventory"
	];

	const fundingForm = () => (
		<div id='funding' className='p-2'>
			<div className='form-group col-12 col-lg-6  pl-0 pr-0'>
				<label className='pb-2'> {translateIfPreferedLanguageIsSpanish(mainState.contact__preferred__language,'Requested Amount', 'Cantidad Deseada')} </label>
				<Cleave
					onInput={onInput}
					name='opportunity__requestedAmount'
					type='text'
					maxLength='10'
					placeholder='Amount ($5-$500k)'
					// mask={amountNumberMask}
					options={{ numeral: true, numeralThousandsGroupStyle: 'thousand', noImmediatePrefix: true, prefix: '$' }}
					onChange={handleChange("opportunity__requestedAmount")}
					className='form-control form-elegant text-muted'
					value={opportunity__requestedAmount}
					required
				/>
				<small className='text-danger'>{getError('opportunity__requestedAmount')}</small>
			</div>
			<div className='row'>
				<div className='form-group col-12 col-lg-6 '>
					<label className='pb-2'> {translateIfPreferedLanguageIsSpanish(mainState.contact__preferred__language,'Monthly business revenue', 'Flujo de caja mensual')}</label>
					<Cleave
						onInput={onInput}
						name='opportunity__revenue'
						placeholder='Monthly Revenue'
						// mask={amountNumberMask}
						maxLength='10'
						options={{ numeral: true, numeralThousandsGroupStyle: 'thousand', noImmediatePrefix: true, prefix: '$' }}
						onChange={handleChange("opportunity__revenue")}
						className='form-control form-elegant text-muted'
						value={opportunity__revenue}
						required
					/>
					<small className='text-danger'>{getError('opportunity__revenue')}</small>
				</div>
				<div className='form-group col-12 col-lg-6 '>
					<label className='pb-2'>{translateIfPreferedLanguageIsSpanish(mainState.contact__preferred__language,'Purpose of funding?', 'Proposito del capital')}</label>
					<select
						onChange={onInput}
						name='opportunity__purposeOfFunding'
						value={opportunity__purposeOfFunding}
						type='text'
						onInput={handleChange("opportunity__purposeOfFunding")}
						className='form-control form-elegant text-muted'
						required
					>
						<option value='' defaultValue className='text-muted'>
							Select an Option
						</option>
						{purposeFunding.map((funding, i) => (
							<option key={i}>{funding}</option>
						))}
					</select>
					<small className='text-danger'>{getError('opportunity__purposeOfFunding')}</small>
				</div>
			</div>
		</div>
	);

	return (
		serverError ? <ServerError error={serverError} /> :
		<Layout
			title= {translateIfPreferedLanguageIsSpanish(mainState.contact__preferred__language,'How much funding do you need?', '¿Cuánto capital necesita?')}
			htmlTitle='Funding'
			preRoute=''
			showPreButton= 'hidden'
			linkBtnDisplay='none'
			preRouteClass=''
			className=''
			invokeFunction={(e) => clickSubmit(e)}
			btnContinueId = 'fundingCBtn'
			dots = {returnTrueIsSpanishIsPrefered(mainState.contact__preferred__language) ? dotsInSpanish : dots}
		>
			{fundingForm()}
		</Layout>
	);
}
