import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import ServerError from "./ServerError";
import StepProgressBar from "./StepProgressBar";

export default function Layout({
	title = "Title",
	htmlTitle,
	className,
	showPreButton = "visible",
	linkBtnDisplay = 'block',
	preRoute = "",
	preRouteClass = "",
	preRouteText = "Back",
	btnContinueText = "Continue",
	showBtnContinue = "visible",
	disabled = "",
	btnContinueId = '',
	getEmail = false,
	children,
	invokeFunction,
	dots = [{ name: "Uplyft Capital Application", status: 1 }]
}) {
	const [serverError, setServerError] = useState(false);

	useEffect(() => {
		let sessionData = sessionStorage['data']? JSON.parse(sessionStorage.getItem("data")):'';
		if (sessionData.app){
			setServerError({message:<> Your signed application has already been submitted. <br/>If you need to make changes or have received this message in error, please contact one of our funding advisors at the email below.<br/><br/><a href="mailto:sales@uplyftcapital.com"><p style={{color:"#6b1cf5"}}>Click here to email us: sales@uplyftcapital.com</p></a></> })
		};
	},[]);

	const clickSubmit = e => { invokeFunction(e);};

	const controlButtons = () => (
		<div className= 'container d-flex justify-content-between pt-4'>
			<Link to={`/onboarding/merchant/${preRoute}`} style={{ visibility: `${showPreButton}` }} className={`${preRouteClass} display`}>
				{preRouteText} 
			</Link>
			<button id ={`${btnContinueId}`} type='submit' className='btn btn-primary' style={{ visibility: `${showBtnContinue}` }} disabled={`${disabled}` }>
				{btnContinueText}
			</button>
		</div>
	);

	return (
		serverError ? <ServerError error={serverError} /> :
		<>
			<StepProgressBar dots={dots} />
			<Helmet>
				<title>{`Uplyft Capital Onboarding Merchant | ${htmlTitle}`}</title>
			</Helmet>
			<main className='container content layoutFading'>
				<Link to={`/onboarding/merchant/${preRoute}`} style={{ visibility: `${showPreButton}`, display: `${linkBtnDisplay}` }} className='btn form__btn-back-mobile isDone' data-form-back=''>
					<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'>
						<g transform='translate(-16 -36)'>
							<path
								d='M10,0,8.182,1.818,15.065,8.7H0v2.6H15.065L8.182,18.182,10,20,20,10Z'
								transform='translate(36 56) rotate(180)'
								fill='#927dfe'
							></path>
						</g>
					</svg>
				</Link>
				<div className={className}>
					<form noValidate onSubmit={(e) => clickSubmit(e)} className=' '>
						<div>
							<h2 className='pb-3'> {title} </h2>
						</div>
						<div className='shadow p-5 mb-2 bg-white roundCorners'>
							{children}
						</div>
						{controlButtons()}
					</form>
				</div>
			</main>
		</>
	);
};
