import React, { useState, useEffect, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import Select from 'react-select';
import Layout from "../LandingLayout";
import Cleave from 'cleave.js/react';
import { MerchantStateContext } from '../../application/context/MerchantStateContext';
//npm pack => https://www.npmjs.com/package/query-string
import QueryString from 'query-string'
import PreApproval from "./PreApproval";
import PersonalLoansPartners from "./PersonalLoansPartners";

export default function Landing({variant =''}) {
	const [overlay, setOverlay] = useState({ offer:false, spinner: true });
	const [formError, setFormError] = useState({});
	const { setMainState, setData, mainState, data, handleChange, prePopulateMainState , natureOfBusiness, populateCampaignVariation } = useContext(MerchantStateContext);
	const { opportunity__requestedAmount, opportunity__revenue, account__natureOfBusiness, contact__creditScoreRatio, account__startDate, contact__debt, opportunity__bankAccountType } = mainState

	const location = useLocation();
	const history = useHistory();

	const referrerCampaign = (location) => {
		const params = QueryString.parse(location.search);
		if (variant) setData({...data, campaignVariation: variant  })
		if (params) {
			// handleDataChange(params)
			populateCampaignVariation(params);
			if (params.ac && params.co && params.op) {
				prePopulateMainState(params)
			} else if(params.campaign){
				setData({...data, campaign:params.campaign })
			};
		};
		sessionStorage.setItem("data", JSON.stringify({ ...data, campaign: params.campaign, campaignVariation: variant }));
	};

	useEffect(() => { referrerCampaign(location); }, [location])

	useEffect(() => {
		if(sessionStorage['mainState'] ){
			let tempState = JSON.parse(sessionStorage.getItem("mainState"));
			setMainState({
				...tempState
				,opportunity__requestedAmount: tempState.opportunity__requestedAmount
				,opportunity__revenue: tempState.opportunity__revenue
				,opportunity__purposeOfFunding: tempState.opportunity__purposeOfFunding
			})
		}
	}, [])

	const formValidation = (e) => {
		let form = e.target
		let isValid = form.checkValidity()
		let formData = new FormData(form)
		let validationMessages = Array.from(formData.keys()).reduce((acc, key) => {
			acc[key] = form.elements[key].validationMessage
			return acc
		}, {})
		setFormError(validationMessages)
		return isValid
	}

	const onInput = (e) => { setFormError({ ...formError, [e.target.name]: e.target.validationMessage }) }
	const onInputChange = ({action}) => {
		switch (action) {
			case 'set-value':
				setFormError({ ...formError, 'account__natureOfBusiness': '' });
				return;
			default:
				return;
		};
	};

	const getError = (field) => formError[field];

	const clickSubmit = e => {
		e.preventDefault();
		let today = new Date();
		let userDate = new Date(account__startDate);
		let isValid = formValidation(e);
		if (isValid){
			if ( (account__startDate !== "" && userDate > today) || account__natureOfBusiness === '' || parseInt(account__startDate.substring(6,10))  < 1900 ){
				account__startDate !== "" && userDate > today && setFormError({ ...formError, 'account__startDate': 'Please enter valid date'});
				parseInt(account__startDate.substring(6,10))  < 1900  && setFormError({ ...formError, 'account__startDate':'Date can\'t be before 1900' });
				account__natureOfBusiness === '' && setFormError({ ...formError, 'account__natureOfBusiness': 'Please fill out the type of business you own' });
			} else {
				setOverlay({ offer:true, spinner: true });
			};
		};

	};

	const customStyles = {
		option: (provider) => ({
			...provider,
			borderStyle: 'none',
				fontSize: '0.8rem',
				color:'#140046'
		}),
		control: (provider) => ({
			...provider,
			borderStyle: 'none',
			borderColor: 'none',
			fontSize: '1.2rem',
		})
	};

	const theme = (theme) => ({
		...theme,
		colors: {
			...theme.colors,
			primary25: 'rgba(230, 227, 252, 1)',
			primary: '#6610f2',
		},
	});

	const debtArr = [
		{name: 'None', value: 1.5 }
		, {name: 'Low', value: 1.25 }
		, {name: 'Medium', value: 1.00 }
		, {name: 'High', value: 0.75 }
	];
	const creditScoreArr = [
		{name: 'Excellent', value: 1.5 }
		, {name: 'Good', value: 1.25 }
		, {name: 'Average', value: 1.00 }
		, {name: 'Poor', value: 0.75 }
	];
	const bankAccountType = [
		"Business",
		"Personal"
	];


	const fundingForm = () => (
		<div id='funding-LP' className='p-2'>
			<div className='row'>
				<div className='form-group col-12 col-lg-6'>
					<label className='pb-2'>Requested Amount</label>
					<Cleave
						onInput={onInput}
						name='opportunity__requestedAmount'
						type='text'
						maxLength='10'
						placeholder='Amount ($5-$500k)'
						options={{ numeral: true, numeralThousandsGroupStyle: 'thousand', noImmediatePrefix: true, prefix: '$' }}
						onChange={handleChange("opportunity__requestedAmount")}
						className='form-control form-elegant text-muted'
						value={opportunity__requestedAmount}
						required
					/>
					<small className='text-danger'>{getError('opportunity__requestedAmount')}</small>
				</div>
				<div className='form-group col-12 col-lg-6'>
					<label className=''>What does the business do?</label>
					<Select
						name='account__natureOfBusiness'
						onInputChange={onInputChange}
						options={natureOfBusiness}
						theme={theme}
						value={{ label: account__natureOfBusiness }}
						styles={customStyles}
						className='form-elegant text-muted mt-2'
						onChange={handleChange("account__natureOfBusinessId")}
						placeholder="select an option"
					/>
					<small className='text-danger'>{getError('account__natureOfBusiness')}</small>
				</div>
			</div>
			<div className='row'>
				<div className='form-group col-12 col-lg-4'>
					<label className=''>Business Start Date</label>
					<Cleave
						onInput={onInput}
						onChange={handleChange("account__startDate")}
						options={{ date: true, delimiter: '/', datePattern: ['m','d','Y'] }}
						name='account__startDate'
						className='form-control form-elegant text-muted'
						placeholder='mm/dd/yyyy'
						value={account__startDate}
						required
					/>
					<small className='text-danger'>{getError('account__startDate')}</small>
				</div>
				<div className='form-group col-6 col-lg-4'>
					<label className=''>Credit Score</label>
					<select
						onChange={onInput}
						onInput={handleChange("contact__creditScoreRatio")}
						name='contact__creditScoreRatio'
						value={contact__creditScoreRatio}
						className='form-control form-elegant text-muted'
						required
					>
						<option value='' defaultValue className='text-muted'>
							Select
						</option>
						{creditScoreArr.map((score, i) => (
							<option key={i} value={score.value}>{score.name}</option>
						))}
					</select>
					<small className='text-danger'>{getError('contact__creditScoreRatio')}</small>
				</div>
				<div className='form-group col-6 col-lg-4'>
					<label className=''>Business Debt</label>
					<select
						onChange={onInput}
						onInput={handleChange("contact__debt")}
						name='contact__debt'
						value={contact__debt}
						className='form-control form-elegant text-muted'
						required
					>
						<option value='' defaultValue className='text-muted'>
							Select
						</option>
						{debtArr.map((debt, i) => (
							<option key={i} value={debt.value}>{debt.name}</option>
						))}
					</select>
					<small className='text-danger'>{getError('contact__debt')}</small>
				</div>
			</div>
			<div className='row'>
				<div className='form-group col-6 col-lg-6'>
					<label className='pb-2'>Bank Account type?</label>
					<select
						onChange={onInput}
						name='opportunity__bankAccountType'
						value={opportunity__bankAccountType}
						type='text'
						onInput={handleChange("opportunity__bankAccountType")}
						className='form-control form-elegant text-muted'
						required
					>
						<option value='' defaultValue className='text-muted'>
							Select an Option
						</option>
						{bankAccountType.map((funding, i) => (
							<option key={i}>{funding}</option>
						))}
					</select>
					<small className='text-danger'>{getError('opportunity__bankAccountType')}</small>
				</div>
				{/* Ask for monthly revenue only if BAT is Business */}
				{ mainState.opportunity__bankAccountType === 'Business' ?
				<div className='form-group col-12 col-lg-6'>
					<label className='pb-2'>Monthly deposited revenue</label>
					<Cleave
						onInput={onInput}
						name='opportunity__revenue'
						placeholder='Monthly Revenue'
						maxLength='10'
						options={{ numeral: true, numeralThousandsGroupStyle: 'thousand', noImmediatePrefix: true, prefix: '$' }}
						onChange={handleChange("opportunity__revenue")}
						className='form-control form-elegant text-muted'
						value={opportunity__revenue}
						required
					/>
					<small className='text-danger'>{getError('opportunity__revenue')}</small>
				</div>
					: <div className='form-group col-12 col-lg-6'></div>
				}
			</div>
			<div className='row pt-3'>
				<div className='form-group col-12 col-lg-5 d-flex align-items-end'>
					<button id="btnPrimaryLanding" type="submit" className="btn btn-primary inside-form d-flex justify-content-center" >
						Check Eligibility
						<span className="mobile-none">
							<i className="fa fa-paper-plane btn-add-on"></i>
						</span>
					</button>
				</div>
			</div>
		</div>
	);

	const pickView = () => (
		overlay.offer ? <PreApproval /> 
		// future feature where we check the bank account type and if is personal we send them to the our personal loans partners
		// : opportunity__bankAccountType === 'Personal' ? <PersonalLoansPartners />  
		: fundingForm()
	);

	return (
		<Layout
			title=''
			htmlTitle='Funding'
			preRoute=''
			showPreButton='hidden'
			linkBtnDisplay='none'
			preRouteClass=''
			className=''
			invokeFunction={(e) => clickSubmit(e)}
			btnContinueId = 'fundingCBtn'
		>
			{ pickView() }
		</Layout>
	);
}