import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet'
import UplyftInfo from './UplyftInfo'


export default function Layout({
    title = "Title",
    htmlTitle,
    className,
    linkBtnDisplay = 'block',
    showPreButton = "visible",
    preRoute = "",
    preRouteClass = "",
    preRouteText = "Back",
    btnContinueText = "Continue",
    showBtnContinue = "visible",
    children,
    invokeFunction
}) {
    const clickSubmit = e => {
        invokeFunction(e);
    };

    const controlButtons = () => (
        <div className='container d-flex justify-content-between pt-5 '>
            <Link to={`/onboarding/syndicator/${preRoute}`} style={{ visibility: `${showPreButton}` }} className={`${preRouteClass} display`}>
                {preRouteText}
            </Link>
            <button type='submit' className='btn btn-primary display' style={{ visibility: `${showBtnContinue}` }}>
                {btnContinueText}
            </button>
            <button type='submit' className='btn btn-primary isDone' style={{ visibility: `${showBtnContinue}` }}>
                {btnContinueText}
            </button>
        </div>
    );
    return (
        <>
            <Helmet>
                <title>{`Uplyft Capital Onboarding Syndicator | ${htmlTitle}`}</title>
            </Helmet>
            <main id='syndicatorLayout' >
                <section className='container content layoutFading'>

                    <Link to={`/onboarding/syndicator/${preRoute}`} style={{ visibility: `${showPreButton}`, display: `${linkBtnDisplay}` }} className='btn form__btn-back-mobile isDone' data-form-back=''>
                        <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'>
                            <g transform='translate(-16 -36)'>
                                <path
                                    d='M10,0,8.182,1.818,15.065,8.7H0v2.6H15.065L8.182,18.182,10,20,20,10Z'
                                    transform='translate(36 56) rotate(180)'
                                    fill='#927dfe'
                                ></path>
                            </g>
                        </svg>
                    </Link>

                    <div className={className}>
                        <div className='container text-center display'>
                            <h1 style={{ color: '#927dff' }}>Syndication</h1>
                            <h2>We’d love to hear from you.</h2>
                        </div>
                        <div className='mt-5 p-3 display'></div>

                        <form noValidate onSubmit={(e) => clickSubmit(e)} className='pt-5-lg'>
                            <div>
                                <h2 className='pb-3'> {title} </h2>
                            </div>
                            <div className='shadow p-5 mb-2 bg-white roundCorners'>
                                {children}
                            </div>
                            {controlButtons()}
                        </form>
                    </div>
                </section>
                <div className='mt-5'><UplyftInfo /></div>

            </main>
        </>
    );
}
