import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import Layout from "./Layout";
import MaskedInput from 'react-text-mask';
import { MerchantStateContext } from '../context/MerchantStateContext';
import config from '../../../../config/index'

import { returnTrueIsSpanishIsPrefered, translateIfPreferedLanguageIsSpanish } from '../../../../lib/util';

export default function BusinessLocation(props) {
	const [formError, setFormError] = useState({})
	const { setMainState, mainState, handleChange } = useContext(MerchantStateContext);
	const { account__address__address1, account__address__address2, account__address__city, account__address__state, account__address__zip, account__workPhone } = mainState;

	const history = useHistory();
	
	useEffect(() => {
		// Prevent from merchants to land directly on the page w/out filling the previous pages
		if (!sessionStorage['mainState']) history.push('/onboarding/merchant/');
		//persist state => pull state from session storage everything the page reload
		if (sessionStorage['mainState'] ){
			let tempState = JSON.parse(sessionStorage.getItem("mainState"));
			// incase of attempt to "jump" pages using urls in favorite or saved link etc, without going through the form workflow
			// inside the "if statement" we are 'sampling' one property from each previous component to see if exist, if one is missing, 
			// we send the merchant to the beginning of the app
			if (!tempState.opportunity__requestedAmount || !tempState.account__name) history.push('/onboarding/merchant/');
			setMainState({
				...tempState 
				,account__address__address1: tempState.account__address__address1
				,account__address__address2: tempState.account__address__address2
				,account__address__city: tempState.account__address__city
				,account__address__state: tempState.account__address__state
				,account__address__zip: tempState.account__address__zip
				,account__workPhone: tempState.account__workPhone
			})
		};
	}, [])

	const dots = [
		{ name: "Funding", status: 1 },
		{ name: "Business", status: 1 },
		{ name: "", status: 1 },
		{ name: "Personal", status: 0 },
		{ name: "", status: 0 },
		{ name: "Signature", status: 0 },
		{ name: "Upload", status: 0 }
	];

	const dotsInSpanish = [
		{ name: "Financiamiento", status: 1 },
		{ name: "Negocio", status: 1 },
		{ name: "", status: 1 },
		{ name: "Personal", status: 0 },
		{ name: "", status: 0 },
		{ name: "Firma", status: 0 },
		{ name: "Documentos", status: 0 }
	];
	
	const formValidation = (e) => {
		let form = e.target
		let isValid = form.checkValidity()
		let formData = new FormData(form)
		let validationMessages = Array.from(formData.keys()).reduce((acc, key) => {
			acc[key] = form.elements[key].validationMessage
			return acc
		}, {})

		setFormError(validationMessages)
		return isValid
	}

	const onInput = (e) => {
		const input = e.target
		// const isValid = input.checkValidity
		setFormError({ ...formError, [input.name]: input.validationMessage })
	}

	const getError = (field) => formError[field]

	const clickSubmit = e => {
		e.preventDefault();
		let isValid = formValidation(e);
		if (isValid){
			sessionStorage.setItem("mainState", JSON.stringify(mainState));
			history.push('/onboarding/merchant/personal');
		}
	};


	const addressForm = () => (
		<div id='address' className='p-2'>
			<div className='row'>
				<div className='form-group col-12 col-lg-6'>
					<label className='pb-2'>{translateIfPreferedLanguageIsSpanish(mainState.contact__preferred__language,'Address', 'Dirección')}</label>
					<input
						onInput={onInput}
						onChange={ handleChange("account__address__address1")}
						name="account__address__address1"
						type='text'
						className='form-control form-elegant text-muted'
						placeholder='100 Sunnyville Road'
						value={account__address__address1}
						required
					/>
					<small className='text-danger'>{getError('account__address__address1')}</small>
				</div>
				<div className='form-group col-12 col-lg-6'>
					<label className='pb-2'>{translateIfPreferedLanguageIsSpanish(mainState.contact__preferred__language,'Address 2 - Optional', 'Dirección 2 - Opcional')}</label>
					<input
						onInput={onInput}
						onChange={handleChange("account__address__address2")}
						type='text'
						name='account__address__address2'
						className='form-control form-elegant text-muted'
						placeholder='Suite, Floor or Bldg #'
						value={account__address__address2}
					/>
					<small className='text-danger'>{getError('account__address__address2')}</small>
				</div>
			</div>
			<div className='row'>
				<div className='form-group col-6 col-lg-3'>
					<label className='pb-2'>{translateIfPreferedLanguageIsSpanish(mainState.contact__preferred__language,'City', 'Ciudad')}</label>
					<input
						onInput={onInput}
						onChange={handleChange("account__address__city")}
						type='text'
						name='account__address__city'
						className='form-control form-elegant text-muted'
						placeholder='Miami'
						value={account__address__city}
						required
					/>
					<small className='text-danger'>{getError('account__address__city')}</small>
				</div>
				<div className='form-group col-6 col-lg-3'>
					<label className='pb-2'>{translateIfPreferedLanguageIsSpanish(mainState.contact__preferred__language,'State', 'Estado')}</label>
					<select
						onChange={onInput}
						onInput={handleChange("account__address__state")}
						name='account__address__state'
						value={account__address__state}
						className='form-control form-elegant text-muted'
						required
					>
						<option value='' defaultValue className='text-muted'>
							{translateIfPreferedLanguageIsSpanish(mainState.contact__preferred__language,'Select Your State', 'Seleccione su estado')}
						</option>
						{config.usStatesList.map((state, i) => (
							<option key={i} value={state.abbreviation}>{state.name}-{state.abbreviation}</option>
						))}
					</select>
					<small className='text-danger'>{getError('account__address__state')}</small>
				</div>
				<div className='form-group col-6 col-lg-3 '>
					<label className='pb-2'>{translateIfPreferedLanguageIsSpanish(mainState.contact__preferred__language,'Zip Code', 'Código postal')}</label>
					<MaskedInput
						// onInput={onInput}
						onChange={handleChange("account__address__zip")}
						mask={[/[0-9]/, /\d/, /\d/, /\d/, /\d/]}
						guide={true}
						type='text'
						name='account__address__zip'
						className='form-control form-elegant text-muted'
						pattern='^\d{5}$' title='Zip code should contain 5 digits'
						placeholder='00000'
						minLength='5'
						value={account__address__zip}
						required
					/>
					<small className='text-danger'>{getError('account__address__zip')}</small>
				</div>
				<div className='form-group col-6 col-lg-3'>
					<label className='pb-2'>{translateIfPreferedLanguageIsSpanish(mainState.contact__preferred__language,'Work Phone', 'Teléfono del trabajo')}</label>
					<MaskedInput
						// onInput={onInput}
						onChange={handleChange("account__workPhone")}
						name='account__workPhone'
						type='tel'
						mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
						guide={true}
						keepCharPositions={true}
						className='form-control form-elegant text-muted'
						pattern='((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}' title='Please match the requested pattern: (555) 555 -5555'
						placeholder='(555) 555 -5555'
						value={account__workPhone}
						required
					/>
					<small className='text-danger'>{getError('account__workPhone')}</small>
				</div>
			</div>
		</div>
	);

	return (
		<Layout
			title={translateIfPreferedLanguageIsSpanish(mainState.contact__preferred__language,'Where is your business located?', '¿Dónde está ubicado su negocio?')}
			preRouteText = {translateIfPreferedLanguageIsSpanish(mainState.contact__preferred__language,'Back', 'Atras')}
			btnContinueText = {translateIfPreferedLanguageIsSpanish(mainState.contact__preferred__language,'Continue', 'Continuar')}
			htmlTitle='Address??'
			preRoute='business'
			showPreButton='visible'
			preRouteClass='btn btn-light'
			className=''
			invokeFunction={clickSubmit}
			btnContinueId='addressCBtn'
			dots = {returnTrueIsSpanishIsPrefered(mainState.contact__preferred__language) ? dotsInSpanish :dots }
		>
			{addressForm()}
		</Layout>
	);
}
