// actions.js
import { createActions } from 'reduxsauce';

const { Creators, Types } = createActions({
    getGenerateOfferInitialData: ['initialData'],
    onFundingAmountChange: ['newProperties'],
    onTermChange: ['newProperties'],
    onCommissionChange: ['newProperties'],
    onPaymentAmountChange: ['newProperties'],
    resetOffer: null,
    getProgramRates: ['programRates'],
});

export default { Creators, Types };