import React from 'react'

export default function UplyftInfo() {
    return (
        <div className="form__infos js-is-seen is-seen">
            <div className="form__info is-done" data-anime="{&quot;from&quot;:{&quot;opacity&quot;:0,&quot;translateY&quot;:&quot;70px&quot;},&quot;to&quot;:{&quot;opacity&quot;:1,&quot;translateY&quot;:0,&quot;delay&quot;:0,&quot;duration&quot;:1500,&quot;easing&quot;:&quot;easeOutQuart&quot;}}" style={{ opacity: "1", transform: "translateY(0px)" }}>
                <div className="form__info__img">
                    {/* <img src="https://uplyftcapital.com/wp-content/uploads/2019/01/Group-208.svg" alt="information icon" /> */}
                    {/* <img src="/svg/svg_landing/decisionPoints/line_of_credit.svg" alt="information icon" /> */}
                    <img src="/png/phone_uc.png" alt="information icon" />
                </div>
                <div className="form__info__content"><p><span style={{ color: "#897fa2" }}>Phone: <a style={{ color: "#897fa2" }} href="tel:800-515-7531"><strong>800-515-7531</strong></a></span><br /><span style={{ color: "#897fa2" }}>Fax: <strong>305-999-5312</strong></span></p>
                </div>
            </div>
            <div className="form__info is-done" data-anime="{&quot;from&quot;:{&quot;opacity&quot;:0,&quot;translateY&quot;:&quot;70px&quot;},&quot;to&quot;:{&quot;opacity&quot;:1,&quot;translateY&quot;:0,&quot;delay&quot;:400,&quot;duration&quot;:1500,&quot;easing&quot;:&quot;easeOutQuart&quot;}}" style={{ opacity: "1", transform: "translateY(0px)" }}>
                <div className="form__info__img">
                    {/* <img src="https://uplyftcapital.com/wp-content/uploads/2019/01/Group-206.svg" alt="information icon" /> */}
                    <img src="/png/building_uc.png" alt="information icon" />
                </div>
                <div className="form__info__content"><p><span style={{ color: "#9087a8" }}>20200 West Dixie Highway</span><br /><span style={{ color: "#9087a8" }}>Unit. 802 Miami, FL 33180</span></p>
                </div>
            </div>
            <div className="form__info is-done" data-anime="{&quot;from&quot;:{&quot;opacity&quot;:0,&quot;translateY&quot;:&quot;70px&quot;},&quot;to&quot;:{&quot;opacity&quot;:1,&quot;translateY&quot;:0,&quot;delay&quot;:800,&quot;duration&quot;:1500,&quot;easing&quot;:&quot;easeOutQuart&quot;}}" style={{ opacity: "1", transform: "translateY(0px)" }}>
                <div className="form__info__img">
                    {/* <img src="https://uplyftcapital.com/wp-content/uploads/2019/01/Group-209.svg" alt="information icon" /> */}
                    <img src="/png/contact_uc.png" alt="information icon" />
                </div>
                <div className="form__info__content">
                    <p><span style={{ color: "#9087a8" }}>
                        <a style={{ color: "#9087a8" }} href="mailto:sales@uplyftcapital.com">sales@uplyftcapital.com</a>
                    </span><br />
                        <span style={{ color: "#9087a8" }}><a style={{ color: "#9087a8" }} href="mailto:support@uplyftcapital.com">support@uplyftcapital.com</a></span>
                    </p>
                </div>
            </div>
        </div>
    )
}
