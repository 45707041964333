import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import axios from 'axios'
import Dropzone from "./dropezone/Dropzone";
import "./upload.css";
import Progress from "./progress/Progress";
import StepProgressBar from "../StepProgressBar";
import config from '../../../../../config';
import { Helmet } from "react-helmet";
import AlertMessage from "../AlertMessage";
import ServerError from "../ServerError";

import { returnTrueIsSpanishIsPrefered , translateIfPreferedLanguageIsSpanish } from '../../../../../lib/util';

const dataFetch = async (url) => (
    await axios.get(url)
        .then( res => { 
            // console.log(res) //DEBUG
            return res
    
        })
        .catch (error => {
            error.title = "We're Sorry...";
            error.message = <>Link expired &nbsp;&nbsp; <i class="fa fa-unlink"></i>
                    <br/>Application Already Signed. 
                    <br/>Please contact one of our funding advisors to have them resend a new link<br/><br/><a href="mailto:sales@uplyftcapital.com"><p style={{color:"#6b1cf5"}}>Click here to email us: sales@uplyftcapital.com</p></a>
                </>;
            console.error(error); 
        })
);

const numberOfStatementsDependingOfState = async (statesArray, currentState) => {
	/*
	let statementResult
	if(statesArray.includes(currentState)){
		statementResult = '4'
	}else{
		statementResult = '3'
	}
	return statementResult
	*/
	// We now require 4 statements for ALL states
	return '4';
}
class Upload extends Component {
	constructor(props) {
		super(props);
		this.state = {
			files: [],
			uploading: false,
			uploadProgress: {},
			successfullyUploaded: false,
			redirect: false,
			opportunity: '',
			error: false,
			unSupportedFiles: [],
			display: false,
			message: '',
			numberOfStatements: '',
			preferedLanguage: 'English' //default
		};
	};

	componentDidMount(){
		//we get the params when mounted
		const { accountUuid, contactUuid, opportunityUuid } = this.props.match.params;

		// we dont have context in this comp so we need to pull it from session storage
		if(sessionStorage['mainState'] ){
			let foundSession = JSON.parse(sessionStorage.getItem("mainState"));

			//setting the lang pref
			this.setState({preferedLanguage: foundSession.contact__preferred__language})

			let statesArray = config.statesThatRequireFourMonths
			let currentState = foundSession.account__address__state
			numberOfStatementsDependingOfState(statesArray, currentState).then( res => {

				this.setState({numberOfStatements: res})
			})
			
		}else{

			// console.log('No Session Found')//DEBUG
			//prefetch account data if not session found & set the number of statements depending in the current account state
			let statesArray = config.statesThatRequireFourMonths
			dataFetch(`${config.apiUrl}/api/uplyftOnboarding/${accountUuid}/genericDataFetch/${contactUuid}/${opportunityUuid}`)
				.then( res => {
					
					let currentState = res.data?.account?.address?.state
					numberOfStatementsDependingOfState(statesArray, currentState).then( res => {

						this.setState({numberOfStatements: res})
					})
					
			});
		  
		}
		

		this.setState({uploading:true})
		let url = `${config.apiUrl}/api/uplyftOnboarding/${accountUuid}/accountValidate/${contactUuid}/${opportunityUuid}`
		axios.get(url)
			.then((res) => this.setState({uploading:false}))
			.catch( error => {
				this.setState({
					error:{
						message:
							<>Link expired &nbsp;&nbsp; <i className="fa fa-unlink"></i>
								<br/>You are probably using and old link to upload files.
								<br/>Please contact one of our funding advisors to have them resend a new link<br/><br/><a href="mailto:sales@uplyftcapital.com"><p style={{color:"#6b1cf5"}}>Click here to email us: sales@uplyftcapital.com</p></a>
							</>
					}})
				console.error(error);
			});
	};

	dots = [
		{ name: "Funding", status: 1 },
		{ name: "Business", status: 1 },
		{ name: "", status: 1 },
		{ name: "Personal", status: 1 },
		{ name: "", status: 1 },
		{ name: "Signature", status: 1 },
		{ name: "Upload", status: 1 }
	];

	dotsInSpanish = [
		{ name: "Financiamiento", status: 1 },
		{ name: "Negocio", status: 1 },
		{ name: "", status: 1 },
		{ name: "Personal", status: 1 },
		{ name: "", status: 1 },
		{ name: "Firma", status: 1 },
		{ name: "Documentos", status: 1 }
	];

	checkFilesMIME = file => {
		if (file.type === 'application/pdf' || file.type === 'image/jpeg' || file.type === 'image/png') {
			return true
		} else {
			return false
		}
	}

	dateFormat = () => {
		// Formatting date result: YYYYMMDD
		let today = new Date();
		let dateFormatConstructor = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'numeric', day: '2-digit' });
		let [{ value: month },,{ value: day },,{ value: year }] = dateFormatConstructor.formatToParts(today);
		let formatDate = `${year}-${month}-${day}`.replace(/[^\w\\.]+/g, "");
		return formatDate;
	}

	onFilesAdded = (files) => {
		let newFiles = files.map(file => {
			let todayDate = this.dateFormat();
			let randomNum = Math.random().toString(36).substring(2,8);
			let data = new File([file], `${todayDate}-${randomNum}-${file.name}`, { type: file.type });
			return data;
		});
		this.setState(prevState => ({
			files: prevState.files.concat(newFiles),
		}));
	};

	prepareFilesForUploading =  (files) => {
		let newFilesArray = [];
		let unSupportedFilesArr = [];

		files.forEach(file => {	this.checkFilesMIME(file) ? newFilesArray.push(file) : unSupportedFilesArr.push(file); });
		this.setState({ ...this.state, files: newFilesArray, unSupportedFiles: unSupportedFilesArr });

		if( newFilesArray.length > 0 && unSupportedFilesArr.length > 0 ){
			 this.setState({
				display: true,
				message: {
					title: 'Unsupported File Type',
					body: 'The files below are not supported and will be removed. Try to upload PDF, JPG, PNG instead'
				}
			});
		} else if( newFilesArray.length === 0 && unSupportedFilesArr.length > 0) {
			 this.setState({
				display: true,
				message: {
					title: 'Unsupported File Type',
					body: 'Please upload one of the supported extensions: PDF, PNG, JPG'
				}
			});
		} else if( newFilesArray.length === 0 && unSupportedFilesArr.length === 0) {
			 this.setState({
				display: true,
				message: {
					title:  translateIfPreferedLanguageIsSpanish(this.state.preferedLanguage,'Missing Documents', 'Faltan Documentos'),
					body: translateIfPreferedLanguageIsSpanish(this.state.preferedLanguage,`Please upload your last  business bank statements`, `Por favor cargue sus últimos ${this.state.numberOfStatements} extractos bancarios comerciales`)
					
				}
			});
		} else {
			this.uploadFiles();
		};
	};

	uploadFiles = async e => {
		const promises = [];
		const { accountUuid, contactUuid, opportunityUuid } = this.props.match.params;
		let rollQuery = window.location.search;
		await this.setState({ uploadProgress: {}, uploading: true, display: false });
		this.state.files.forEach(file => {
			promises.push(this.sendRequest(file));
		});
		try {
			let response = '';
			response = await Promise.all(promises);
			if ( response && response !== undefined ){
				let uploadedFiles = [];
				let uploadProgressArray = Object.entries(this.state.uploadProgress);
				// console.log('uploadProgressArray before', uploadProgressArray);
				uploadProgressArray.forEach( ele =>{
					ele[1].state === 'done' && uploadedFiles.push(`${ele[0]}`);
					ele[1].state === 'failed' && uploadedFiles.push(`${ele[0]} FAILED TO UPLOAD`);
				});
				// console.log('uploadedFiles after', uploadedFiles);

				this.setState({ successfullyUploaded: true, uploading: false });
				axios.post(`${config.apiUrl}/api/uplyftOnboarding/${accountUuid}/uploadDocsNotificationEmail/${opportunityUuid}${rollQuery}`, { files: uploadedFiles })
				setTimeout(() => {
					// sessionStorage.clear();
					this.props.history.push(`/onboarding/merchant/${accountUuid}/additions/${contactUuid}/${opportunityUuid}`)
					// this.setState({ redirect: true });
				}, 1000);
			};
		} catch (err) {
			this.setState({ successfullyUploaded: false, uploading: false });
			console.error(`Unable to save record(s): ${err.message}`);
			this.setState({
				error: { message: 'Something went wrong while trying to upload your documents, please contact one of our funding advisors '}
			});
		};
	};

	sendRequest = async file => {
		const { accountUuid, opportunityUuid } = this.props.match.params;
		const formData = new FormData();
		formData.append("file", file, file.name );
		const axiosRes = await axios.post(`${config.apiUrl}/api/uplyftOnboarding/${accountUuid}/upload/${opportunityUuid}`, formData, {
			onUploadProgress: (progressEvent) => {
				const totalLength = progressEvent.lengthComputable && progressEvent.total
				if (totalLength !== null) {
					let copyUploadProgress = { ...this.state.uploadProgress };
					copyUploadProgress[file.name] = {
						state: "pending",
						percentage: Math.round((progressEvent.loaded * 100) / totalLength)
					};
					this.setState({ uploadProgress: copyUploadProgress });
				}
			},
			validateStatus: function (status) {
                return status >= 200 && status < 400;
            }
		});

		if ( axiosRes.status !== 200 ){
			let copyUploadProgress = { ...this.state.uploadProgress };
			copyUploadProgress[file.name] = { state: "failed", percentage: 0 };
			this.setState({ uploadProgress: copyUploadProgress });
		} else {
			let copyUploadProgress = { ...this.state.uploadProgress };
			copyUploadProgress[file.name] = { state: "done", percentage: 100 };
			this.setState({ uploadProgress: copyUploadProgress });
			console.error(axiosRes.data.error);
		};
		return axiosRes;
	};

	renderProgress = (file, index) => {
		const uploadProgress = this.state.uploadProgress[file.name];
		let progress = uploadProgress ? uploadProgress.percentage : 0 ;
		return (
			<div className='ProgressWrapper'>
				<Progress progress={ progress} />
				{ uploadProgress && uploadProgress.state === "done" ?
					<img
						className='CheckIcon' src='/svg/checkmark.svg' alt='done' style={{ opacity: uploadProgress && uploadProgress.state === "done" ? 1 : 0 }} />
					:
					this.checkFilesMIME(file) ?
						<>
							{ progress === 0 ?
								<img className='CheckIcon' src='/svg/readyForUpload.svg' alt='done' style={{ opacity: 1 }} />
								:

								<img className='CheckIcon' src='/svg/uploading.svg' alt='done' style={{ opacity: 1 }} />
							}
						</>
						:
						<img className='CheckIcon'	src='/svg/redXmark.svg'	alt='wrong-mime' style={{ opacity: 1 }}	/>
				}
				<img onClick={() => this.removeFileFromArray(index)} className='cta__button' src='/svg/Deleted-icon.svg' alt='delete-icon' style={{	opacity: uploadProgress && uploadProgress.state === "done" ? 0 : 1	}}	/>
			</div>
		);
	};

	renderActions = () => {
		if (this.state.files.length > 1) {
			return <button type='button' className='upBtn' onClick={() => this.setState({ files: [] })}>
				Clear all
			</button>
		}
	};

	removeFileFromArray = (index) => {
		const newFiles = [...this.state.files]
		newFiles.splice(index, 1);
		if (this.state.files.length === 1) {
			this.setState({ files: newFiles, successfullyUploaded: false });
		} else {
			this.setState({ files: newFiles });
		};
	};

	uploadFilesLater = (props) => {
		const { accountUuid, contactUuid, opportunityUuid } = this.props.match.params;
		if (sessionStorage['data'] ){
			let data = JSON.parse(sessionStorage.getItem("data"));
			let [account, contact,opportunity] = [{}, {}, {}];
			account.uuid = accountUuid;
			contact.uuid = contactUuid;
			opportunity.uuid = opportunityUuid;
			contact.name = data.contactName;
			contact.firstName = data.contactFirstName;
			contact.lastName = data.contactLastName;
			contact.email = data.contactEmail;
			axios.post(`${config.apiUrl}/api/uplyftOnboarding/uploadFiles/later`, { account, contact,opportunity })
			.then(res => {
				console.log(res.data.emailStatus);
			})
			.catch(error => {
				console.log('error', error);
			})
			this.props.history.push(`/onboarding/merchant/${accountUuid}/additions/${contactUuid}/${opportunityUuid}`)
		} else {
			this.props.history.push(`/onboarding/merchant/${accountUuid}/additions/${contactUuid}/${opportunityUuid}`)
		};
	};

	toggleAlertMessage = () =>{
		this.setState({ display: !this.state.display, message:'', unSupportedFiles: [] });
	};

	uploadForm = () => (
		<main id='uploadFiles' className='container layoutFading mt-5'>
			<div className='mb-2'>
				<div className='shadow p-5 mb-2 bg-white roundCorners'>
					<div>
						<h2 className='pb-3'>
							{translateIfPreferedLanguageIsSpanish(this.state.preferedLanguage,'We are almost there...', 'Estamos casi alli...')}
						</h2>
					</div>
					<p>
					{translateIfPreferedLanguageIsSpanish(this.state.preferedLanguage,'Thank you. We\'ve received your signed application successfully.', 'Gracias. Hemos recibido su solicitud firmada con éxito.')}
						</p>
						<p>
							{returnTrueIsSpanishIsPrefered(this.state.preferedLanguage)
								? `Por favor cargue los ${this.state.numberOfStatements} más recientes estados bancarios comerciales y su asesor de financiación de Uplyft se comunicara con usted en breve para explicarle los próximos pasos. `
								:  `Please upload the ${this.state.numberOfStatements} most recent Business Bank Statements and your Uplyft funding advisor will contact you shortly to explain the next steps.`
							}
						</p>
					<div className='Upload'>
						<div className='Content'>
							<Dropzone onFilesAdded={this.onFilesAdded} pref={this.state.preferedLanguage} />
						</div>
						<div className={this.state.files.length ? 'Content' : 'd-none'}>
							<div className='Files'>
								<div className='Actions'>{this.renderActions()}</div>
								{this.state.files.map((file, index) => {
									let fileName = this.checkFilesMIME(file) ? file.name : <span className='text-danger flashing'> {file.name + '  -- Please upload PDF, JPG or PNG Only'}</span>
									return (
										<div className='Row' key={index + file.name}>
											<span className='Filename'>{fileName}</span>
											{this.renderProgress(file, index)}
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div id='controlBtn' className='container d-flex justify-content-between pt-2'>
				<Link to='#'></Link>
				{/* <button id='uploadFBtn' type='submit' onClick={() => this.uploadFiles()} className='btn btn-primary'> */}
				<button id='uploadFBtn' type='submit' onClick={() => this.prepareFilesForUploading(this.state.files)} className='btn btn-primary'>
					{translateIfPreferedLanguageIsSpanish(this.state.preferedLanguage, 'Upload Files', 'Subir archivos')}
				</button>
			</div>
			<div className={this.state.files.length ? 'none' : ''} >
				<div id='uploadLater' className='container d-flex justify-content-center justify-content-lg-end pt-2'>
					<button className='btn btn-clear fadingOut' onClick={this.uploadFilesLater} >
						{translateIfPreferedLanguageIsSpanish(this.state.preferedLanguage, 'Upload later', 'Subir más tarde')}
					</button>
				</div>
			</div>
		</main>
	);

	render() {
		const divStyle = () => {
			if( this.state.uploading  || this.state.display ) return { "position": 'relative', "opacity": 0.5, "zIndex": -10 }
			else return;
		};

		const dataAlert = {
			alertFiles: {
				valid: this.state.files,
				unValid: this.state.unSupportedFiles,
			},
			alertMessage: this.state.message,
			alertDisplay: this.state.display,
			cb: {
				toggleAlert: this.toggleAlertMessage,
				upload: this.uploadFiles
			}
		};

		return (
			<>
				<Helmet>
					<title>Uplyft Capital Onboarding Merchant | Upload-Documents</title>
				</Helmet>
				{/* {this.state.redirect && <Redirect to='/onboarding/merchant/finished' />} */}
				{this.state.redirect && <Redirect to='/onboarding/merchant/finished' />}
				{this.state.error && <ServerError error={this.state.error} />}

				{ !this.state.error && (
					this.state.uploading ?
					<>
						<div className='spinner'>
							<div className='loader'>submitting...</div>
						</div>
						<div >
							<StepProgressBar 
							dots = {returnTrueIsSpanishIsPrefered('Spanish') ? this.dotsInSpanish : this.dots}
							/>
							{/* <div style={{ position: 'relative', opacity: 0.5, zIndex: -10 }} > */}
							<div style={ divStyle() } >	{ this.uploadForm() }</div>
						</div>
					</>
					:
					<>
						{this.state.display && 	<div className ='alert_animation'><AlertMessage data = {dataAlert} /> </div> }
						<div style={ divStyle() }> <StepProgressBar 
						dots = {returnTrueIsSpanishIsPrefered('Spanish') ? this.dotsInSpanish : this.dots}
						
						/>{ this.uploadForm() }</div>
					</>
				)}
			</>
		);
	}
}

export default Upload;
