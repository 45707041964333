import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
import CurrencyInput from 'react-currency-input';
import { toCurrencyFormat } from '../../../lib/util';
import { currencyFormatReverse } from '../../../lib/util';
import Slider, { createSliderWithTooltip } from 'rc-slider';
import './GenerateOffer.css';
const SliderWithTooltip = createSliderWithTooltip(Slider);


class PaymentAmountComponent extends Component {

	constructor(props) {
		super(props);

		this.state = {
			paymentAmount: this.props.paymentAmount
			, amount: this.props.amount
			, term: this.props.term
			, commission: this.props.commission
			, minFundingAmount: 5000
			, minPaymentAmountValue: 0
			, maxPaymentAmountValue: 0
		};
	}

	async componentDidMount() {
		let paymentAmountRange = await this.props.calculatePaymentAmountRange(this.state.minFundingAmount, this.props.offer.fundingAmount, this.props.newOffer.commissionRate, this.props.newOffer.buyRate, this.props.newOffer.buyRateAdjustment, this.props.newOffer.paymentsCount);
		this.setState({
			minPaymentAmountValue: paymentAmountRange.minPaymentAmount
			, maxPaymentAmountValue: paymentAmountRange.maxPaymentAmount
		});
	}

	async componentDidUpdate() {
		if (this.state.amount !== this.props.amount) {
			this.setState({
				paymentAmount: this.props.paymentAmount
				, amount: this.props.amount
			});
		}
		if (this.state.term !== this.props.term || this.state.commission !== this.props.commission) {

			let paymentAmountRange = await this.props.calculatePaymentAmountRange(this.state.minFundingAmount, this.props.offer.fundingAmount, this.props.commission, this.props.newOffer.buyRate, this.props.newOffer.buyRateAdjustment, this.props.newOffer.paymentsCount);

			this.setState({
				paymentAmount: this.props.paymentAmount
				, amount: this.props.amount
				, term: this.props.term
				, commission: this.props.commission
				, minPaymentAmountValue: paymentAmountRange.minPaymentAmount
				, maxPaymentAmountValue: paymentAmountRange.maxPaymentAmount
			});
		}
	}

	onChange = (e) => {
		let newPaymentAmount = currencyFormatReverse(e.target.value || 0);
		this.setState({
			paymentAmount: newPaymentAmount
		});
	}

	onBlur = (e, newOffer, paymentAmount) => {
		let { min, max } = e.target;
		let newpaymentAmount = Math.max(Number(min), Math.min(Number(max), Number(paymentAmount)));
		this.setState({
			paymentAmount: parseFloat(newpaymentAmount || 0)
		});
		this.props.onPaymentAmountChange(newpaymentAmount, newOffer);
	}

	createMarks = () => {
		let marks = {};
		marks[this.state.minPaymentAmountValue] = <strong>{toCurrencyFormat(this.state.minPaymentAmountValue)}</strong>;
		marks[this.state.maxPaymentAmountValue] = <strong>{toCurrencyFormat(this.state.maxPaymentAmountValue)}</strong>;
		return marks;
	}

	render() {
		let these = this;
		return (
			<div className='container mb-5  mb-md-0'>
				<div className="row">
					<div className="form-group col-12 col-lg-3">
						<label> Payment Amount</label>
						<div className='d-none d-lg-block'>
							<div className="input-group">
								<div className="input-group-prepend">
									<span className="input-group-text "><i class="fa fa-usd" aria-hidden="true"></i></span>
								</div>
								<CurrencyInput type="text" min={this.state.minPaymentAmountValue} max={this.state.maxPaymentAmountValue} precision="2" className="form-control" name="paymentAmount" value={this.state.paymentAmount} onChangeEvent={(e) => this.onChange(e)} onBlur={(e) => this.onBlur(e, this.props.newOffer, this.state.paymentAmount)} />
							</div>
						</div>
					</div>
					<div className="col-12 col-lg-9 d-flex align-items-center">
						<SliderWithTooltip
							min={this.state.minPaymentAmountValue}
							max={this.state.maxPaymentAmountValue}
							defaultValue={this.state.paymentAmount}
							value={this.state.paymentAmount}
							step={this.state.step}
							marks={this.createMarks()}
							allowCross={true}
							dots={false}
							trackStyle={{ backgroundColor: '#7d4fee' }}
							handleStyle={{ borderColor: '#e6e6ff', backgroundColor: '#927dfe', color: '#f2f' }}
							railStyle={{ backgroundColor: '#e6e6ff' }}
							dotStyle={{ borderColor: '#927dfe', backgroundColor: '#927dfe' }}
							tipFormatter={value => <div style={{ marginLeft: "5px", marginRight: "5px", color: "#fff" }}>{toCurrencyFormat(value)}</div>}
							tipProps={{ visible: false }}
							onChange={function (value) {
								let paymentAmount = currencyFormatReverse(value);
								these.setState({
									paymentAmount: paymentAmount
								});
							}}
							onAfterChange={function () {
								these.props.onPaymentAmountChange(these.state.paymentAmount, these.props.newOffer)
							}}
						/>
					</div>
				</div>
			</div>
		);
	}
}

//PropTypes
PaymentAmountComponent.propTypes = {
	paymentAmount: PropTypes.number.isRequired,
}

const mapStateToProps = state => ({
	offer: state.generateOffer.offer
	, newOffer: state.generateOffer.newOffer
	, paymentAmount: state.generateOffer.paymentAmount
	, amount: state.generateOffer.amount
	, term: state.generateOffer.term
	, commission: state.generateOffer.commission
});

export default connect(mapStateToProps, {})(PaymentAmountComponent);