import axios from 'axios';
import actions from './actions';
import config from '../../../../config/index';

const getBankVerificationInitialDataAction = actions.Creators.getBankVerificationInitialData;

const getBankVerificationInitialData = (opportunityUuid) => async dispatch => {
	let retObj = 0;
	try {
	const response = await axios.get(config.apiUrl + '/fundingPlatformPublic/bankVerification/getInitialDatabyOpportunity/'+ opportunityUuid);
	let account = response.data.account;
    let opportunity = response.data.opportunity;
	let submissions = response.data.submissions;
	retObj = {account,opportunity,submissions}
    dispatch(getBankVerificationInitialDataAction({account, opportunity, submissions, opportunityUuid}));
	}
	catch(error) {
		dispatch(error);
	}
	return retObj;
}

const getTransactions = async (token, metadata) => {
	try {
	await axios.post(config.apiUrl + '/fundingPlatformPublic/bankVerification/getTransactions', { token: token, metadata: metadata});
	}
	catch(error) {
		console.error(error);
	}
	return;
}

const getBankData = async (linkedBanks, accountUuid, opportunityUuid) => {
	let linkedBanksArray = linkedBanks.map( linkedBank => {
		return { publicToken: linkedBank.token, institutionName: linkedBank.metadata.institution.name};
	});
	try {
	await axios.post(config.apiUrl + '/fundingPlatformPublic/bankVerification/getBankData', {linkedBanks: linkedBanksArray, accountUuid, opportunityUuid});
	}
	catch(error) {
		console.error(error);
	}
	return;
}

export default {
    getBankVerificationInitialData,
    getTransactions,
    getBankData
}