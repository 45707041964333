import React, {useEffect} from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function LinkExpired() {
	let history = useHistory();

	useEffect(()=> {
		sessionStorage.clear();
		history.entries = [];
		history.index = -1;
		history.push('/onboarding/merchant/expiredlink');
	},[]);

	return (
		<>
			<Helmet>
				<title> Uplyft Capital Onboarding Merchant | Error </title>
			</Helmet>
			<main className="container content layoutFading" role="main"> 
					<div className='mt-5'>
						<div>
							<h2 className='pb-3'> { "" || "We're Sorry..."} </h2>
						</div>
						<div className='shadow p-5 mb-2 bg-white roundCorners'>
							<div>
								Link expired &nbsp;&nbsp; <i className="fa fa-unlink"></i>
								<br/>You are probably using an old link to update an application.
								<br/>Please contact one of our funding advisors to have them resend a new link<br/><br/><a href="mailto:sales@uplyftcapital.com"><p style={{color:"#6b1cf5"}}>Click here to email us: sales@uplyftcapital.com</p></a>
							</div>
						</div>
					</div>
			</main>
		</>
	);
}
