import React from 'react';
// import SignPad from './SignPad';
import './docSignature.css';

export default function Template({data, clearHistoryAndPush}) {
    const { account, contact, opportunity } = data
    let opportunityMonthlyRev = parseInt(opportunity.monthlyRevenue).toLocaleString("en-US", { style: "currency", currency: "USD"});
  
    const html = () => (
        <div className='shadow p-5 mb-2 bg-white roundCorners'>
            <table>
                <tbody>
                    <tr>
                        <td colSpan='3'><h5>BUSINESS INFORMATION</h5></td>
                        <td className="d-flex justify-content-end"><span className='btn btn-sm btn-clear' onClick={() => clearHistoryAndPush()}>Update</span></td>
                    </tr>
                </tbody>
            </table>
            <hr></hr>

            <table>
                <tbody>
                    <tr>
                        <td colSpan='2'><p>Business Name</p><strong>{account.name}&nbsp;</strong></td>
                        <td colSpan='2'><p>DBA (doing business as)</p> <strong>{account.db}&nbsp;</strong></td>
                    </tr>
                    <tr>
                        <td colSpan='2'><p>Address</ p><strong> {account.address.address1} {account.address.address2}&nbsp;</strong></td>
                        <td colSpan='2'><p>Business Cellphone</p> <strong>{account.phone}&nbsp;</strong></td>
                    </tr>
                    <tr>
                        <td><p>City</p> <strong>{account.address.city}&nbsp;</strong></td>
                        <td><p>State</p> <strong>{account.address.state}&nbsp;</strong></td>
                        <td><p>Zip</p> <strong>{account.address.zip}&nbsp;</strong></td>
                    </tr>
                    <tr>
                        <td><p>Fed Tax ID #</p> <strong>{account.taxId}&nbsp;</strong></td>
                        <td><p>Business Start Date</p> <strong>{account.startDate}&nbsp;</strong></td>
                        <td><p>Industry Type</p> <strong>{account.natureOfBusiness}&nbsp;</strong></td>
                    </tr>
                </tbody>
            </table>
            <br></br>

            <table>
                <tbody>
                    <tr>
                        <td colSpan='3'><h5>PRIMARY OWNER INFORMATION</h5></td>
                        <td className="d-flex justify-content-end"><span className='btn btn-sm btn-clear' onClick={() => clearHistoryAndPush()}>Update</span></td>
                    </tr>
                </tbody>
            </table>
            <hr></hr>

            <table>
                <tbody>
                    <tr>
                        <td colSpan='2'><p>Full Name</p> <strong>{contact.firstName} {contact.lastName}&nbsp;</strong></td>
                        <td colSpan='2'><p>E-mail Address</p> <strong>{contact.email}&nbsp;</strong></td>
                    </tr>
                    <tr>
                        <td colSpan='2'><p>Home Address</p> <strong>{contact.address.address1}&nbsp;</strong></td>
                        <td colSpan='2'><p>Cell Phone</p> <strong>{contact.mobilePhone}&nbsp;</strong></td>

                    </tr>
                    <tr>
                        <td><p>City</p> <strong>{contact.address.city}&nbsp;</strong></td>
                        <td><p>State</p> <strong>{contact.address.state}&nbsp;</strong></td>
                        <td><p>Zip</p> <strong>{contact.address.zip}&nbsp;</strong></td>
                    </tr>
                    <tr>
                        <td><p>Date of Birth</p> <strong>{contact.birthDate}&nbsp;</strong></td>
                        <td><p>Social Security #</p> <strong>{contact.socialSecurityNumber}&nbsp;</strong></td>
                        <td><p>Ownership %</p> <strong>{contact.ownership}%&nbsp;</strong></td>
                    </tr>
                </tbody>
            </table>
            <br></br>

            <table>
                <tbody>
                    <tr>
                        <td colSpan='3'><h5>FINANCIAL INFORMATION</h5></td>
                        <td className="d-flex justify-content-end"><span className='btn btn-sm btn-clear' onClick={() => clearHistoryAndPush()}>Update</span></td>
                    </tr>
                </tbody>
            </table>
            <hr></hr>

            <table>
                <tbody>
                    <tr>
                        <td><p>MONTHLY GROSS SALES:</p><strong>{opportunityMonthlyRev }</strong></td>
                        <td><p>AVG. MONTHLY CREDIT SALES:</p><strong>&nbsp;</strong></td>
                        <td><p>BALANCE:</p><strong>&nbsp;</strong></td>
                    </tr>
                </tbody>
            </table>
            <br></br>
        </div>
    );

    return (
        <div className='container mt-5'> {html()} </div>
    );
};
