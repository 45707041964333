import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from "react-helmet";

import './GenerateOffer.css';
import FundingAmountComponent from './FundingAmountComponent';
import TermComponent from './TermComponent';
import CommissionComponent from './CommissionComponent';
import PaymentAmountComponent from './PaymentAmountComponent';
import OfferSummaryComponent from '../common/OfferSummaryComponent';
// import FundingProgressBarComponent from '../common/FundingProgressBarComponent';
import GenerateOfferDoneComponent from './GenerateOfferDoneComponent';
import generateOfferOperations from './duck/operations'
import { toCurrencyFormat } from '../../../lib/util';

class GenerateOfferContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            initialDataReady: false,
            offerStatus: ''
        };
    }

    async componentDidMount() {
        let submissionUuid = this.props.match.params.submissionUuid;
        await this.props.getGenerateOfferInitialData(submissionUuid);
        await this.props.getProgramRates(this.props.offer.fundingProgramName, this.props.offer.buyRateAdjustment);
        this.setState({
            initialDataReady: true,
            offerStatus: this.props.newOffer.offerStatus || 'unlocked'
        });
    }

    onEnterKeyPress(e) {
        if (e.which === 13 ) {
          e.preventDefault();
        }
    }

    onClick = async (e) => {
        e.preventDefault();
        let submissionUuid = this.props.match.params.submissionUuid;
        await this.props.getGenerateOfferInitialData(submissionUuid);
        await this.props.getProgramRates(this.props.offer.fundingProgramName, this.props.offer.buyRateAdjustment);
    }

    onSubmit = async (e) => {
        e.preventDefault();
        const newOffer = {
            ...this.props.newOffer
        };
        let submissionUuid = this.props.match.params.submissionUuid;
        await this.props.selectOffer(newOffer, submissionUuid);
        this.props.sendMerchantsContract(this.props.account, newOffer, submissionUuid);
        this.setState({
            offerStatus: 'locked'
        });
    }

    setDots = (offerStatus) => {
        let dots = [];
        dots.push({ name: "Review Offer", status: 1 });
        if (offerStatus === 'unlocked') {
            dots.push({ name: "Done", status: 0 });
        } else {
            dots.push({ name: "Done", status: 1 });
        }
        return dots;
    }

    controlButtons = () => (
        <>
            <div className='container d-flex justify-content-between pt-2 display'>
                <button type="button" className='btn btn-primary' onClick={this.onClick}>
                    Reset
                </button>
                <button type="submit" className='btn btn-primary'>
                    Request Contract
                </button>
            </div>
            <div className='container isDone'>
                <button type="submit" className='btn btn-primary mb-3'>
                    Request Contract
                </button>
                <button type="button" className='btn btn-clear ' onClick={this.onClick}>
                    Reset
                </button>

            </div>
        </>
    );

    render() {
        if (this.state.initialDataReady && this.state.offerStatus === 'unlocked') {
            return (
                <>
                    <Helmet>
                        <title>Uplyft Capital Funding | Generate-Offer</title>
                    </Helmet>
                    <div className="layoutFading space">
                        <div className='container'>
                            <div className='' > 
                                <form onKeyPress={this.onEnterKeyPress} onSubmit={this.onSubmit} >
                                    <div className="pb-3" style={{ display: "flex", justifyContent: "space-between" }}>
                                        <h2>{this.props.account.name} </h2>
                                    </div>
                                    <div id='generalOfferContainer' className='shadow p-5 mb-2 bg-white roundCorners'>
                                        <FundingAmountComponent onFundingAmountChange={this.props.onFundingAmountChange} />
                                        <TermComponent onTermChange={this.props.onTermChange} />
                                        <CommissionComponent onCommissionChange={this.props.onCommissionChange} />
                                        <PaymentAmountComponent onPaymentAmountChange={this.props.onPaymentAmountChange} calculatePaymentAmountRange={this.props.calculatePaymentAmountRange} />
                                        <div className="container">
                                            <div className='row'>
                                                <div className='form-group col mt-3'>
                                                    <label className='pb-2'>{this.props.offer.fundingProgramName} Offer Details</label>
                                                    <OfferSummaryComponent items={[
                                                          { fai: <i className="fa fa-usd" aria-hidden="true"></i>, name: "Funding", content: toCurrencyFormat(this.props.newOffer.fundingAmount), contentStyle:{ fontSize: '14px', color: '#9a56ff'} }
                                                        , { fai: <i className="fa fa-money" aria-hidden="true"></i>, name: "Payment", content: toCurrencyFormat(this.props.newOffer.paymentAmount), contentStyle:{ fontSize: '14px', color: '#9a56ff'} }
                                                        , { fai: <i className="fa fa-calendar" aria-hidden="true"></i>, name: "Payments", content: this.props.newOffer.paymentsCount.toFixed(0) + " " + this.props.newOffer.paymentFrequency, contentStyle:{ fontSize: '14px', color: '#9a56ff'} }
                                                        , { fai: <i className="fa fa-usd" aria-hidden="true"></i>, name: "Payback", content: toCurrencyFormat(this.props.newOffer.paybackAmount), contentStyle:{ fontSize: '14px', color: '#9a56ff'} }
                                                        , { fai: <i className="fa fa-university" aria-hidden="true"></i>, name: "Factor Rate", content: this.props.newOffer.factorRate.toFixed(2), contentStyle:{ fontSize: '14px', color: '#9a56ff'}}
                                                        , { fai: <i className="fa fa-usd" aria-hidden="true"></i>, name: "Commission", content: toCurrencyFormat(this.props.newOffer.commissionAmount), contentStyle:{ fontSize: '14px', color: '#9a56ff'} }
                                                    ]}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {this.controlButtons()}
                                </form>
                            </div>
                        </div>
                    </div>
                </>
            );
        } else if (this.state.initialDataReady && this.state.offerStatus === 'locked') {
            return (<GenerateOfferDoneComponent dots={this.setDots(this.state.offerStatus)} />);
        } else {
            return (
                <div className='spinner'>
                    <div className='loader'>Loading...</div>
                </div>
            );
        }
    }
}

GenerateOfferContainer.propTypes = {
    getGenerateOfferInitialData: PropTypes.func.isRequired,
    offer: PropTypes.object
};

const mapStateToProps = state => ({
    account: state.generateOffer.account,
    contacts: state.generateOffer.contacts,
    offer: state.generateOffer.offer,
    newOffer: state.generateOffer.newOffer,
    programRates: state.generateOffer.programRates,

    fundingAmount: state.generateOffer.newOffer ? state.generateOffer.newOffer.fundingAmount : '',
    paymentAmount: state.generateOffer.newOffer ? state.generateOffer.newOffer.paymentAmount : '',
    paymentsCount: state.generateOffer.newOffer ? state.generateOffer.newOffer.paymentsCount : '',
    paybackAmount: state.generateOffer.newOffer ? state.generateOffer.newOffer.paybackAmount : '',
    factorRate: state.generateOffer.newOffer ? state.generateOffer.newOffer.factorRate : '',

});

const mapDispatchToProps = dispatch => {
    const getGenerateOfferInitialData = (submissionUuid) => dispatch(generateOfferOperations.getGenerateOfferInitialData(submissionUuid));
    const getProgramRates = (fundingProgramName) => dispatch(generateOfferOperations.getProgramRates(fundingProgramName));
    const onFundingAmountChange = (newAmount, newOffer) => dispatch(generateOfferOperations.onFundingAmountChange(newAmount, newOffer));
    const onTermChange = (newTerm, newOffer, programRates) => dispatch(generateOfferOperations.onTermChange(newTerm, newOffer, programRates));
    const onCommissionChange = (newCommission, newOffer) => dispatch(generateOfferOperations.onCommissionChange(newCommission, newOffer));
    const onPaymentAmountChange = (newPaymentAmount, newOffer) => dispatch(generateOfferOperations.onPaymentAmountChange(newPaymentAmount, newOffer));
    const resetOffer = () => dispatch(generateOfferOperations.resetOffer());

    const calculatePaymentAmountRange = (minFundingAmount, maxFundingAmount, commission, buyRate, buyRateAdjustment, paymentsCount) => generateOfferOperations.calculatePaymentAmountRange(minFundingAmount, maxFundingAmount, commission, buyRate, buyRateAdjustment, paymentsCount);
    const selectOffer = (newOffer, submissionUuid) => generateOfferOperations.selectOffer(newOffer, submissionUuid);
    const sendMerchantsContract = (account, offer, submissionUuid) => generateOfferOperations.sendMerchantsContract(account, offer, submissionUuid);
    return {
        getGenerateOfferInitialData,
        getProgramRates,
        onFundingAmountChange,
        onTermChange,
        onCommissionChange,
        onPaymentAmountChange,
        resetOffer,
        calculatePaymentAmountRange,
        selectOffer,
        sendMerchantsContract
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(GenerateOfferContainer);
