import React, { useState } from 'react';


export default function OneTimeAuthorization({handleAuthorizationChange, authorization, setAuthorization}) {
    const [formError, setFormError] = useState({});

    const formValidation = (e) => {
		let form = e.target
		let isValid = form.checkValidity()
		let formData = new FormData(form)
		let validationMessages = Array.from(formData.keys()).reduce((acc, key) => {
			acc[key] = form.elements[key].validationMessage
			return acc
		}, {})

		setFormError(validationMessages)
		return isValid
	};
    
    const getError = (field) => formError[field];
    
    const clickSubmit = e => {
        e.preventDefault();
        let isValid = formValidation(e);
        if (!isValid) return;
        setAuthorization({...authorization, confirmed:true})
    };
    
    return (
        <div className='container mt-5'>
            <form noValidate onSubmit={(e) => clickSubmit(e)} className='shadow p-5 mb-3 bg-white roundCorners'>
            <p className='text-danger'>You are attempting to sign by the merchant name, it's against Uplyft Capital policy and it's prohibited!.<br/>If you were authorized by the merchant, please provide the date and time below.</p>
                <div className='row'>
                    <div className='form-group col-12 col-lg-4'>
                        <label className=''>Authorization Date</label>
                        <input
                            onChange={handleAuthorizationChange("date")}
                            type='date'
                            name='date'
                            className='form-control form-elegant text-muted'
                            placeholder='mm / dd / yyyy'
                            required
                        />
                        <small className='text-danger'>{getError('date')}</small>
                    </div>
                    <div className='form-group col-12 col-lg-4'>
                        <label className=''>Authorization Time</label>
                        <input
                            onChange={handleAuthorizationChange("time")}
                            type='time'
                            name='time'
                            className='form-control form-elegant text-muted'
                            placeholder='09:00 AM'
                            required
                        />
                        <small className='text-danger'>{getError('time')}</small>
                    </div>
                    <div className='container d-flex justify-content-between pt-4'>
                        <button type='submit' className='btn btn-primary '>Confirm</button>
                    </div>
                </div>
            </form>
        </div>
    )
}
