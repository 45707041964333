import React from "react";
import { Link } from "react-router-dom";
 //import "./stepProgressBar.css";

export default function StepProgressBar({ dots }) {
	return (
		<div id='StepProgressBar' className='space'>
			<div className='container display'>
				<div className='form__pages__index '>
					{/* <div className='d-flex justify-content-between'> */}
					{dots.map((dot, i) => {
						return (
							<Link key={i} to='#' className={dot.status ? "form__pages__link is-active" : "form__pages__link "} data-form-goto='0'>
								<span className='form__pages__label'> {dot.name} </span>
							</Link>
						);
					})}
				</div>
			</div>
		</div>
	);
}
