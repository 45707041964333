import { createReducer } from 'reduxsauce'
import Types from './types'

// the initial state of this reducer
export const INITIAL_STATE = {
    submissionUuid: ''
	, account: {}
	// , contacts: []
	, offer: {}
	, newOffer: {}
    , programRates: []
    , maxTerm: ''
	, amount: ''
	, term: ''
	, commission: ''
	, paymentAmount: ''
	, contractInformation: {}
	, offerReady: true
	, contractReady: false
	, contractSigned: false
	, submission: {}
	, signURL: ''
}

// Get Initial Data
export const getGenerateOfferInitialData = (state = INITIAL_STATE, action) => {
    let submissionUuid = action.initialData.submissionUuid;
    let account = action.initialData.account;
    // let contacts = action.initialData.contacts;
    let offer = action.initialData.offer;
    let newOffer = {...offer};
    return {
        ...state
        , submissionUuid: submissionUuid
        , account: account
        // , contacts: contacts
        , offer: offer
        , newOffer: newOffer
        , amount: action.initialData.offer.fundingAmount
        , term: action.initialData.offer.term
        , maxTerm: action.initialData.offer.term
        , commission: action.initialData.offer.commissionRate
        , paymentAmount: action.initialData.offer.paymentAmount
        , offerReady: true
    }
}

// On Funding Amount Change
export const onFundingAmountChange = (state = INITIAL_STATE, action) => {
    return {
        ...state
        , newOffer: action.newProperties.newOffer
        , amount: action.newProperties.amount
        , paymentAmount: action.newProperties.paymentAmount
    }
}

// On Term Change
export const onTermChange = (state = INITIAL_STATE, action) => {
    return {
        ...state
        , newOffer: action.newProperties.newOffer
        , term: action.newProperties.term
        , paymentAmount: action.newProperties.paymentAmount
    }
}

// On Commission Change
export const onCommissionChange = (state = INITIAL_STATE, action) => {
    return {
        ...state
        , newOffer: action.newProperties.newOffer
        , commission: action.newProperties.commission
        , paymentAmount: action.newProperties.paymentAmount
    }
}

// Get Program Rates
export const getProgramRates = (state = INITIAL_STATE, action) => {
    return {
        ...state
        , programRates: action.programRates
    }
}

// On Payment Amount Change
export const onPaymentAmountChange = (state = INITIAL_STATE, action) => {
    return {
        ...state
        , newOffer: action.newProperties.newOffer
        , amount: action.newProperties.amount
        , paymentAmount: action.newProperties.paymentAmount
    }
}

// Reset Offer
export const resetOffer = (state = INITIAL_STATE) => {
    return {
        ...state
        , newOffer: {...state.offer}
        , amount: state.offer.fundingAmount
        , term: state.offer.term
        , commission: state.offer.commissionRate
        , paymentAmount: state.offer.paymentAmount
    }
}

// map our action types to our reducer functions
export const HANDLERS = {
  [Types.GET_GENERATE_OFFER_INITIAL_DATA]: getGenerateOfferInitialData,
  [Types.ON_FUNDING_AMOUNT_CHANGE]: onFundingAmountChange,
  [Types.ON_TERM_CHANGE]: onTermChange,
  [Types.ON_COMMISSION_CHANGE]: onCommissionChange,
  [Types.ON_PAYMENT_AMOUNT_CHANGE]: onPaymentAmountChange,
  [Types.GET_PROGRAM_RATES]: getProgramRates,
  [Types.RESET_OFFER]: resetOffer,
}

export default createReducer(INITIAL_STATE, HANDLERS)