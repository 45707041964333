import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types'

import CurrencyInput from 'react-currency-input';
import { toCurrencyFormat } from '../../../lib/util';
import { currencyFormatReverse } from '../../../lib/util';

import Slider, { createSliderWithTooltip } from 'rc-slider';
import './GenerateOffer.css';
const SliderWithTooltip = createSliderWithTooltip(Slider);

class FundingAmountComponent extends Component {

	constructor(props) {
		super(props);
		this.state = {
			amount: this.props.amount
			, paymentAmount: this.props.paymentAmount
			, minFundingAmount: 5000
			, step: 500
		};
	}

	formatCash = n => {
		if (n < 1e3) return n;
		if (n >= 1e3) return +(n / 1e3).toFixed(1) + 'k';
	};

	onChange = (e) => {
		// let newAmount = e.target.value || 0;
		let newAmount = currencyFormatReverse(e.target.value || 0);
		this.setState({
			amount: newAmount
		});
	}

	onBlur = (e, newOffer, amount) => {
		let { min, max } = e.target;
		let newAmount = Math.max(Number(min), Math.min(Number(max), Number(amount)));
		this.setState({
			amount: parseFloat(newAmount || 0)
		});
		this.props.onFundingAmountChange(newAmount, newOffer);
	}

	componentDidUpdate() {
		if (this.state.paymentAmount !== this.props.paymentAmount) {
			this.setState({
				amount: this.props.amount
				, paymentAmount: this.props.paymentAmount
			});
		}
	}

	createMarks = () => {
		let marks = {};
		marks[this.state.minFundingAmount] = <strong>{this.formatCash(this.state.minFundingAmount)}</strong>;
		marks[this.props.offer.fundingAmount] = <strong>{this.formatCash(this.props.offer.fundingAmount)}</strong>;
		return marks;
	}

	render() {
		let these = this;
		return (
			<div className='container mb-5 mb-md-0'>
				<div className="row">
					<div className="form-group col-12 col-lg-3">
						<label> Funding Amount</label>
						<div className='d-none d-lg-block'>
							<div className='input-group'>
								<div className="input-group-prepend">
									<span className="input-group-text "><i class="fa fa-usd" aria-hidden="true"></i></span>
								</div>
								<CurrencyInput type="text" min={this.state.minFundingAmount} max={this.props.offer.fundingAmount} precision="2" className="form-control" name="amount" value={this.state.amount} onChangeEvent={(e) => this.onChange(e)} onBlur={(e) => this.onBlur(e, this.props.newOffer, this.state.amount)} />
							</div>
						</div>
					</div>
					<div className="col-12 col-lg-9 d-flex align-items-center">
						<SliderWithTooltip
							min={this.state.minFundingAmount}
							max={this.props.offer.fundingAmount}
							defaultValue={this.state.amount}
							value={this.state.amount}
							step={this.state.step}
							marks={this.createMarks()}
							allowCross={true}
							dots={false}
							trackStyle={{ backgroundColor: '#7d4fee' }}
							handleStyle={{ borderColor: '#e6e6ff', backgroundColor: '#927dfe', color: '#f2f' }}
							railStyle={{ backgroundColor: '#e6e6ff' }}
							dotStyle={{ borderColor: '#927dfe', backgroundColor: '#927dfe' }}
							tipFormatter={value => <div style={{ marginLeft: "5px", marginRight: "5px", color: "#fff" }}>{toCurrencyFormat(value)}</div>}
							tipProps={{ visible: false }}
							onChange={function (value) {
								let newAmount = currencyFormatReverse(value);
								these.setState({
									amount: newAmount
								});
							}}
							onAfterChange={function () {
								these.props.onFundingAmountChange(these.state.amount, these.props.newOffer);
							}}
						/>
					</div>
				</div>

			</div >
		);
	}
}

//PropTypes
FundingAmountComponent.propTypes = {
	amount: PropTypes.number.isRequired,
}

const mapStateToProps = state => ({
	newOffer: state.generateOffer.newOffer
	, amount: state.generateOffer.amount
	, paymentAmount: state.generateOffer.paymentAmount
	, offer: state.generateOffer.offer
});

export default connect(mapStateToProps, {})(FundingAmountComponent);