import React from "react";
import "./HeaderSimple.css"

export default function HeaderSimple() {
   return (
        <header id='headerSimple' className='headerSimple'>
            <div className="container">
                <div className="d-flex flex-row justify-content-between">
                    <div className='logo_header_3_desktop logo_header_3_mobile ml-2' data-anime='{"from":{"opacity":0,"translateY":"-130px"},"to":{"opacity":1,"translateY":0,"delay":0,"duration":1500,"easing":"easeOutQuart"}}' style={{ opacity: 1, transform: "translateY(0px)" }} >
                        <svg className='' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 287.282 35.932' >
                                <defs>
                                    <linearGradient id='logo_grad_a' x1='1.044' y1='-.044' x2='.092' y2='.908' gradientUnits='objectBoundingBox'>
                                        <stop offset='.672' stopColor='#927dff'></stop>
                                        <stop offset='.716' stopColor='#8e7afa'></stop>
                                        <stop offset='.764' stopColor='#8671ed'></stop>
                                        <stop offset='.814' stopColor='#7762d7'></stop>
                                        <stop offset='.865' stopColor='#624db9'></stop>
                                        <stop offset='.917' stopColor='#473392'></stop>
                                        <stop offset='.969' stopColor='#271363'></stop>
                                        <stop offset='.997' stopColor='#140046'></stop>
                                    </linearGradient>
                                    <linearGradient id='logo_grad_b' x1='.961' y1='.046' x2='.009' y2='1.225' gradientUnits='objectBoundingBox'>
                                        <stop offset='0' stopColor='#927dff'></stop>
                                        <stop offset='1' stopColor='#927dff'></stop>
                                    </linearGradient>
                                </defs>
                                <g>
                                    <g>
                                        <path
                                            d='M477.088 476.526h-14.45a10.131 10.131 0 0 0-10.12 10.12V497.2a4.765 4.765 0 0 0 4.767 4.671 4.809 4.809 0 0 0 .575-.036l.468-.056v-15.13a4.315 4.315 0 0 1 4.31-4.31h14.45a4.315 4.315 0 0 1 4.31 4.31V501.1a4.315 4.315 0 0 1-4.31 4.31h-11.17l-5.81 5.81h16.98a10.131 10.131 0 0 0 10.12-10.12v-14.45a10.131 10.131 0 0 0-10.12-10.124z'
                                            transform='translate(-452.518 -476.526)'
                                            fill='url(#logo_grad_a)'
                                        ></path>
                                        <path
                                            d='M28.88 10.12v14.45a4.315 4.315 0 0 1-4.31 4.31H13.4l-5.81 5.81h16.98a10.131 10.131 0 0 0 10.12-10.12V10.12'
                                            fill='#927dff'
                                        ></path>
                                        <path
                                            d='M462.017 511.535l4.251 4.256a1.719 1.719 0 0 0 1.225.508h.929l-8.539 8.538a3.725 3.725 0 0 1-2.6 1.064c-3.657 0-4.767-4.963-4.767-4.963v4.092a6.47 6.47 0 0 0 .062.89 6.125 6.125 0 0 0 9.3 4.742 6.392 6.392 0 0 0 1.518-1.125l9.1-9.1v.868a1.719 1.719 0 0 0 .506 1.223l4.3 4.3v-15.3z'
                                            transform='translate(-452.518 -501.62)'
                                            fill='url(#logo_grad_b)'
                                        ></path>
                                    </g>
                                    <path
                                        d='M65.861 35.861V10.946h4.746a3.982 3.982 0 0 1-.014.419 3.79 3.79 0 0 0 0 .759l.033.264.218-.151a10.094 10.094 0 0 1 5.811-1.91 10.621 10.621 0 0 1 2.447.3 8.675 8.675 0 0 1 4.98 3.352 10.437 10.437 0 0 1-.231 12.721 8.351 8.351 0 0 1-6.615 3.244 14.885 14.885 0 0 1-6.463-1.766l-.232-.115v7.8zm10.243-21.49a5.584 5.584 0 0 0-5.583 5.793 5.87 5.87 0 0 0 1.67 4.213 5.467 5.467 0 0 0 3.908 1.605c3.219-.007 5.46-2.386 5.461-5.786s-2.226-5.8-5.416-5.825z'
                                        fill='#140046'
                                    ></path>
                                    <path
                                        d='M208.4 35.859V10.982h4.588l.173 1.4.219-.15a10.172 10.172 0 0 1 5.807-1.908 10.48 10.48 0 0 1 2.614.344 8.722 8.722 0 0 1 4.918 3.443 10.526 10.526 0 0 1-.379 12.654 9.213 9.213 0 0 1-7.018 3.232 11.432 11.432 0 0 1-6.039-1.863l-.246-.152v7.875zm10.241-21.487a5.812 5.812 0 0 0-.09 11.611h.073a5.38 5.38 0 0 0 5.472-5.689 6.079 6.079 0 0 0-1.607-4.385 5.252 5.252 0 0 0-3.8-1.537z'
                                        fill='#beb9cc'
                                    ></path>
                                    <path
                                        d='M192.53 30.067c-3.123 0-5.727-1.663-7.739-4.942a10.214 10.214 0 0 1 1.518-12.029 9.124 9.124 0 0 1 6.6-2.746 10.8 10.8 0 0 1 5.967 1.892l.231.151.093-1.43h4.6v18.425h-4.6l-.093-1.249-.217.112a14.149 14.149 0 0 1-6.36 1.816zm1.048-15.691c-3.2 0-5.46 2.369-5.5 5.761-.037 3.408 2.182 5.813 5.394 5.849h.066a5.811 5.811 0 0 0 .122-11.61z'
                                        fill='#beb9cc'
                                    ></path>
                                    <path
                                        d='M267.017 30.027a10.306 10.306 0 0 1-2.823-.408 8.794 8.794 0 0 1-4.836-3.552 10.225 10.225 0 0 1-1.758-6.477 10.339 10.339 0 0 1 2.494-6.225 9.131 9.131 0 0 1 6.863-3.018 10.68 10.68 0 0 1 5.922 1.884l.224.15.138-1.415h4.59v18.453h-4.591l-.111-1.46-.229.153a10.529 10.529 0 0 1-5.883 1.915zm.591-15.655c-3.227 0-5.486 2.389-5.492 5.811-.006 3.4 2.233 5.785 5.444 5.8h.028a5.5 5.5 0 0 0 3.921-1.623 5.844 5.844 0 0 0 1.685-4.182 5.655 5.655 0 0 0-5.546-5.806z'
                                        fill='#beb9cc'
                                    ></path>
                                    <path
                                        d='M102.299 35.932l2.126-4.838a5.17 5.17 0 0 0-.04-4.246l-6.913-15.99h4.591a1 1 0 0 1 .926.62c.888 2.157 3.074 7.465 4.2 10.161.088.213.186.423.3.667.059.128.124.267.2.422l.142.312.149-.309.206-.422c.121-.244.225-.455.318-.668 1.177-2.705 3.449-8.031 4.372-10.195a1 1 0 0 1 .921-.609l4.578.014-10.359 24.142a1.545 1.545 0 0 1-1.422.937z'
                                        fill='#140046'
                                    ></path>
                                    <path
                                        d='M49.947 30.033c-4.23 0-7.223-3.138-7.28-7.63-.033-2.571-.025-5.186-.018-7.715q0-1.651.007-3.3c0-.112.007-.225.014-.356v-.066h4.7v3.116c0 2.171 0 4.416.007 6.624a8.555 8.555 0 0 0 .137 1.735 3.751 3.751 0 0 0 3.375 3.281 5.287 5.287 0 0 0 .672.043 3.961 3.961 0 0 0 3.821-2.39 10.642 10.642 0 0 0 .8-3.535c.084-1.885.072-3.8.06-5.66 0-.764-.01-1.529-.008-2.294v-.933h4.67v18.44h-4.639l-.063-1.251-.209.082c-.441.174-.886.373-1.317.567a14.045 14.045 0 0 1-2.912 1.057 9.3 9.3 0 0 1-1.817.185z'
                                        fill='#140046'
                                    ></path>
                                    <path
                                        d='M171.147 30.019c-.353 0-.712-.017-1.065-.052a9.836 9.836 0 0 1-.2-19.559 10.751 10.751 0 0 1 1.2-.068c4.53 0 8.447 2.806 9.379 6.695l-4.428 1.244a4.971 4.971 0 0 0-2.833-3.334 5.646 5.646 0 0 0-2-.368 6.213 6.213 0 0 0-1.648.231 5.3 5.3 0 0 0-3.8 5.539 5.2 5.2 0 0 0 4.031 5.251 7.171 7.171 0 0 0 1.554.185c2.057 0 3.569-1.126 4.893-3.646l4.25 1.068c-.931 4.021-4.745 6.814-9.333 6.814z'
                                        fill='#beb9cc'
                                    ></path>
                                    <path
                                        d='M122.848 29.395V14.654h-2.822v-3.748h2.832l-.007-.168a11.138 11.138 0 0 1 .616-4.775 6.045 6.045 0 0 1 3.346-3.287 8.236 8.236 0 0 1 3.251-.64 7.7 7.7 0 0 1 3.321.7l-1.037 3.692a8.383 8.383 0 0 0-1.879-.288 2.881 2.881 0 0 0-2.04.766 3.6 3.6 0 0 0-.851 3.076c.008.25.018.509.015.763v.162h5.314v3.748h-5.299v14.741z'
                                        fill='#140046'
                                    ></path>
                                    <path
                                        d='M145.602 30.013a7.945 7.945 0 0 1-4.354-1.163 5.979 5.979 0 0 1-2.68-5.007c-.114-2.641-.068-7.978-.068-8.032v-1.158h-4.038v-3.748h4v-5.69h4.7v5.69h5.628v3.748h-5.645v1.248s.013 3.856.025 5.784a10.716 10.716 0 0 0 .113 1.552 2.53 2.53 0 0 0 2.6 2.411c.1 0 .207 0 .313-.013a12.983 12.983 0 0 0 2.15-.43c.286-.074.581-.15.882-.22l1.27 3.63a8.629 8.629 0 0 1-4.896 1.398z'
                                        fill='#140046'
                                    ></path>
                                    <path
                                        d='M251.494 30.013a7.945 7.945 0 0 1-4.354-1.163 5.979 5.979 0 0 1-2.68-5.007c-.114-2.642-.068-7.978-.068-8.032v-1.158h-4.038v-3.748h4v-5.69h4.7v5.69h5.624v3.748h-5.644v1.248s.013 3.856.025 5.784a10.745 10.745 0 0 0 .114 1.552 2.53 2.53 0 0 0 2.6 2.411c.1 0 .207 0 .313-.013a12.984 12.984 0 0 0 2.15-.43c.286-.074.582-.15.883-.22l1.27 3.63a8.63 8.63 0 0 1-4.895 1.398z'
                                        fill='#beb9cc'
                                    ></path>
                                    <path fill='#140046' d='M89.676 2.573h4.595v26.824h-4.595z'></path>
                                    <path fill='#beb9cc' d='M282.723 2.547h4.559V29.39h-4.559z'></path>
                                    <path fill='#beb9cc' d='M232.482 10.945h4.577v18.451h-4.577z'></path>
                                    <path
                                        d='M234.771 7.837a2.766 2.766 0 0 1-2.877-2.728 2.843 2.843 0 0 1 2.819-2.841h.052a2.836 2.836 0 0 1 2.9 2.8 2.762 2.762 0 0 1-2.862 2.774z'
                                        fill='#beb9cc'
                                    ></path>
                                </g>
                            </svg>
                    </div>
                    <div >
                        <a href='tel:800 515-7531'>
                            <span className="menuLinkMobile" style={{color: 'rgb(20, 0, 70)'}}>
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="phone" className="svg-inline--fa fa-phone fa-w-16 w-1 mr-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path fill="currentColor" d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z">
                                    </path>
                                </svg>
                                800 515-7531
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </header>
    );
}
