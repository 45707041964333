import React, { Component } from 'react';

class OfferSummaryComponent extends Component {
	render() {
		return (
			<div className="offer-summary">
				<div className='card-mode'>
					<div className="row">
						{this.props.items.map((item, i) => {
							return (
								<div key={i} className='form-group col px-2'>
									<div className="card text-white mb-3" >
										<div className="card-header" style={{background: '#fff', color: 'rgb(154, 86, 255)', textAlign: 'center'}}>
											<p style={{ marginBottom: '0.2rem'}}>{item.fai} </p>
											<h5 style={item.contentStyle}><strong>{item.name}</strong></h5>
										</div>
										<div className="card-body" style={{ background: 'linear-gradient(135deg, #927dff 0px, rgb(158, 108, 227) 239%)' }}>
											<h5 style={{ margin: 'auto 0', padding: '0.5em 0' ,fontSize:'16px', textAlign: 'center' }}><strong>{item.content}</strong></h5>
										</div>
									</div>
								</div>
							)
						})}
					</div>
				</div>
				<div className="table-mode mb-3 shadowBox" >
					<table className='table' >
						{this.props.items.map((item, i) => {
							return (
								<tr key={i} className="p-2">
									<td className='pt-1 pb-1' style={{ color: "#fff", border: "none" }}>{item.name}</td>
									<td className='pt-1 pb-1' style={{ color: "#fff", border: "none" }}><strong>{item.content}</strong></td>
								</tr>
							)
						})}
					</table>
				</div>
			</div>

		);
	}
}

//PropTypes
OfferSummaryComponent.propTypes = {
}

export default OfferSummaryComponent;
