import React, { useState, useContext, useEffect } from "react";
import {  useHistory } from "react-router-dom";
import { MerchantStateContext } from '../../application/context/MerchantStateContext';



export default function PreApproval() {
    // const [redirect, setRedirect] = useState(false);
    const history = useHistory();
    const [overlay, setOverlay] = useState({ offer:false, spinner: true });
    const [partnerLink, setPartnerLink] = useState({ partnerLinkHref: 'https://loanpioneer.com/?uid=62CD940CCBA19&clickid=landingPage&pub_id1=A' })
    const { mainState,lang, data } = useContext(MerchantStateContext);
    const { opportunity__requestedAmount, opportunity__revenue, contact__creditScoreRatio, account__startDate, contact__debt, campaignVariation } = mainState
    const { is_spanish } = lang ;
    const { campaign} = data // we get app data from state
    let preApproved = {};
    preApproved.message = 'Congratulations!'; //
    preApproved.amount = 'You qualify for our personal loan program.'
    let parsedRequestedAmount = parseInt(opportunity__requestedAmount.replace('$','').replace(/,/g,''));
    let parsedMonthlyRevenue = parseInt(opportunity__revenue.replace('$','').replace(/,/g,''));
    // calculate Time in Business greater than 6 months
    let today = new Date()
    let diff = (today.getTime() - new Date(account__startDate).getTime()) / 1000;
    diff /= (60 * 60 * 24 * 7 * 4); 
    let tib =  ( Math.abs(Math.round(diff)) > 6 ? true : false); //tib = time in business

    setTimeout(() => { setOverlay({ ...overlay, spinner: false }) }, 500 );

    if (tib){
        if (parsedRequestedAmount <= parsedMonthlyRevenue * 1.5 ){
            let approvedAmount = (parsedMonthlyRevenue * ( parseFloat(contact__creditScoreRatio) + parseFloat(contact__debt)))/2;
            preApproved.amount = `Pre-approval amount: $${approvedAmount.toLocaleString()}`;
        };
    };

  
    useEffect(
        function pickPartnerLink() {
            let randomLink = Math.random() < 0.5 
            ?
            
            'https://gigglefinance.com/land-uplyft/'
            : 
            
            'https://gigglefinance.com/land-uplyft/'
            setPartnerLink({ partnerLinkHref: randomLink })
        }
    , [])
    
    const submitPreApproval = e => {
		e.preventDefault();
        sessionStorage.setItem("mainState", JSON.stringify(mainState));
        history.push('/onboarding/merchant/business');
    };
    
    return (
        <>
            {!overlay.spinner ? 
                <div id='preApproved' className='p-2'>
                    <div className='preApproved_header'>
                        {/* <i className="fa fa-check-circle-o" aria-hidden="true"></i> */}
                        <img src="/png/checkmark_darker_001.png" alt="information icon" />
 
                        {is_spanish ? 
                        <h4 className='pb-5 mb-5'><b>Felicitaciones!</b></h4>
                        :
                        <h4 className='pb-5 mb-5' style={{ fontSize: 36 }}><b>Congratulations!</b></h4>}
                    </div>
                    <div className='row'>
                        <h2 className='col-12' style={{ fontSize: 22 }}>

                            {is_spanish ? 
                            'Usted califica para nuestros Prestamos Personales!'
                            :
                            'You qualify for our instant funding program. Simply connect your bank account and get approved in under 8 minutes.'}
                        </h2>
                        <div className='col-12 mt-5'>
                            {/* <a id="btnPrimaryPreApprove" target="_blank" href="https://www.kqzyfj.com/click-100476924-13904365" className="btn w-60 btn-primary inside-form d-flex" > */}
                            <a id="btnPrimaryPreApprove" target="_blank" href={partnerLink.partnerLinkHref} className="btn w-60 btn-primary inside-form d-flex justify-content-center" >
                            {is_spanish  ?
                            'Seleccione aquí para su pre-aprobación'
                            :
                            'Click here to get your funds'}
                                <span className="mobile-none">
                                    <i className="fa fa-paper-plane btn-add-on"></i>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
                :
                <>
                    {/* <h4>We are want to give you the best offer..</h4> 	 */}
                    <div style={{position:'relative'}} className='spinner'>
                        <div className='loader'>
                            {is_spanish  ?
                        'Cargando...'
                        :
                        'Loading...'}</div>
                    </div>
                </>
            }
        </>
    );
};
