import React, { Component } from "react";
import "../Upload/progress/progress.css";

class Progress extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      // <div className='ProgressBar'>
      //   <div className='Progress' style={{ width: this.props.progress + "%" }} />
      // </div>
      <div className="progress">
        <div className="progress-bar progress-bar-striped bg-success" role="progressbar" style={{ width: this.props.progress + "%" }} >{this.props.progress}%</div>
      </div>
    );
  }
}

export default Progress;
