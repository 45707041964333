import axios from 'axios';
import actions from './actions';
import config from '../../../../config/index';

const getGenerateOfferInitialDataAction = actions.Creators.getGenerateOfferInitialData;
const onFundingAmountChangeAction = actions.Creators.onFundingAmountChange;
const onTermChangeAction = actions.Creators.onTermChange;
const onCommissionChangeAction = actions.Creators.onCommissionChange;
const onPaymentAmountChangeAction = actions.Creators.onPaymentAmountChange;
const resetOfferAction = actions.Creators.resetOffer;
const getProgramRatesAction = actions.Creators.getProgramRates;

const getGenerateOfferInitialData = (submissionUuid) => async dispatch => {
	try {
		const response = await axios.get(config.apiUrl + '/fundingPlatformPublic/generateOffer/getInitialData/'+ submissionUuid);
		let account = response.data.account;
		let offer = response.data.offer;
		dispatch(getGenerateOfferInitialDataAction({submissionUuid, account, offer}));
	}
	catch(error) {
		console.error(error);
		dispatch(error);
	}
	return;
}
const onFundingAmountChange = (newAmount, newOffer) => async dispatch => {
	let params = {};
	params.amount = newAmount;
	params.paymentsCount = newOffer.paymentsCount;
	params.commission = newOffer.commissionRate;
	params.buyRate = newOffer.buyRate;
	params.buyRateAdjustment = newOffer.buyRateAdjustment;

	let newHoldbackPercentage = await recalculateHoldbackPercentage({amount: newAmount, factorRate: newOffer.factorRate, term: newOffer.term, holdbackPercentage: newOffer.holdbackPercentage, paybackAmount: newOffer.paybackAmount});
	let newPaymentAmount = await recalculatePaymentAmount(params);
	let newPaybackAmount = await recalculatePaybackAmount({amount: newAmount, buyRate: newOffer.buyRate, buyRateAdjustment: newOffer.buyRateAdjustment, commission: newOffer.commissionRate});
	// let newPaymentsCount = await recalculatePaymentsCount({paybackAmount : newPaybackAmount, paymentAmount: newPaymentAmount});
	let newCommissionAmount = await recalculateCommissionAmount({amount: newAmount, commission: newOffer.commissionRate});

	let newProperties = {};
	newProperties.newOffer = newOffer;
	newProperties.newOffer.fundingAmount = newAmount;
	newProperties.newOffer.holdbackPercentage = newHoldbackPercentage;
	newProperties.newOffer.paymentAmount = newPaymentAmount;
	newProperties.newOffer.paybackAmount = newPaybackAmount;
	// newProperties.newOffer.paymentsCount = newPaymentsCount;
	newProperties.newOffer.lastPayment = newPaymentAmount;
	newProperties.newOffer.commissionAmount = newCommissionAmount;
	newProperties.amount = parseInt(newAmount);
	newProperties.paymentAmount = newPaymentAmount;
	dispatch(onFundingAmountChangeAction(newProperties));
	return;
}

const onTermChange = (newTerm, newOffer, programRates) => async dispatch => {
	//get Buy Rate based on the new term
	let newBuyRate = newOffer.buyRate;
	programRates.forEach(programRate => {
		if(newTerm === programRate.months){
			newBuyRate = programRate.buyRate;
		}
	});

	let newPaymentsCount = await recalculatePaymentsCount({term: newTerm, paymentFrequency: newOffer.paymentFrequency});

	let params = {};
	params.amount = newOffer.fundingAmount;
	
	params.term = newTerm;
	params.paymentFrequency = newOffer.paymentFrequency;
	params.paymentsCount = newPaymentsCount;
	params.commission = newOffer.commissionRate;
	params.buyRate = newBuyRate;
	params.buyRateAdjustment = newOffer.buyRateAdjustment;

	let newPaybackAmount = await recalculatePaybackAmount({amount: newOffer.fundingAmount, buyRate: newBuyRate, buyRateAdjustment: newOffer.buyRateAdjustment, commission: newOffer.commissionRate});
	let newPaymentAmount = await recalculatePaymentAmount(params);

	let newProperties = {};
	newProperties.newOffer = newOffer;
	newProperties.newOffer.term = newTerm;
	newProperties.newOffer.paymentAmount = newPaymentAmount;
	newProperties.newOffer.paybackAmount = newPaybackAmount;
	newProperties.newOffer.paymentsCount = newPaymentsCount;
	newProperties.newOffer.lastPayment = newPaymentAmount;
	newProperties.newOffer.buyRate = newBuyRate;
	newProperties.newOffer.factorRate = (newOffer.commissionRate / 100) + newBuyRate + newOffer.buyRateAdjustment;
	newProperties.term = newTerm;
    newProperties.paymentAmount = newPaymentAmount;
    dispatch(onTermChangeAction(newProperties));
	return;
}

const onCommissionChange = (newCommission, newOffer) => async dispatch => {
	let params = {};
	params.amount = newOffer.fundingAmount;
	params.paymentsCount = newOffer.paymentsCount;
	params.commission = newCommission;
	params.buyRate = newOffer.buyRate;
	params.buyRateAdjustment = newOffer.buyRateAdjustment;
	
	let newPaymentAmount = await recalculatePaymentAmount(params);
	let newPaybackAmount = await recalculatePaybackAmount({amount: newOffer.fundingAmount, buyRate: newOffer.buyRate, buyRateAdjustment: newOffer.buyRateAdjustment, commission: newCommission});
	let newCommissionAmount = await recalculateCommissionAmount({amount: newOffer.fundingAmount, commission: newCommission});

	let newProperties = {};
	newProperties.newOffer = newOffer;
	newProperties.newOffer.commissionRate = newCommission;
	newProperties.newOffer.factorRate = (newCommission / 100) + newOffer.buyRate + newOffer.buyRateAdjustment;
	newProperties.newOffer.paymentAmount = newPaymentAmount;
	newProperties.newOffer.paybackAmount = newPaybackAmount;
	newProperties.newOffer.lastPayment = newPaymentAmount;
	newProperties.newOffer.commissionAmount = newCommissionAmount;
	newProperties.commission = newCommission;
	newProperties.paymentAmount = newPaymentAmount;
	dispatch(onCommissionChangeAction(newProperties));
	return;
}

const onPaymentAmountChange = (newPaymentAmount, newOffer) => async dispatch => {
	let params = {};
	params.paymentAmount = newPaymentAmount;
	params.paymentsCount = newOffer.paymentsCount;
	params.commission = newOffer.commissionRate;
	params.buyRate = newOffer.buyRate;
	params.buyRateAdjustment = newOffer.buyRateAdjustment;
	
	let newAmount = await recalculateAmount(params);
	let newPaybackAmount = await recalculatePaybackAmount({amount: newAmount, buyRate: newOffer.buyRate, buyRateAdjustment: newOffer.buyRateAdjustment, commission: newOffer.commissionRate });
	let newHoldbackPercentage = await recalculateHoldbackPercentage({amount: newAmount, factorRate: newOffer.factorRate, term: newOffer.term, holdbackPercentage: newOffer.holdbackPercentage, paybackAmount: newOffer.paybackAmount});
	let newCommissionAmount = await recalculateCommissionAmount({amount: newAmount, commission: newOffer.commissionRate});

	let newProperties = {};
	newProperties.newOffer = newOffer;
	newProperties.newOffer.paymentAmount = newPaymentAmount;
	newProperties.newOffer.fundingAmount = newAmount;
	newProperties.newOffer.holdbackPercentage = newHoldbackPercentage;
	newProperties.newOffer.paybackAmount = newPaybackAmount;
	newProperties.newOffer.lastPayment = newPaymentAmount;
	newProperties.newOffer.commissionAmount = newCommissionAmount;
	newProperties.paymentAmount = newPaymentAmount;
	newProperties.amount = newAmount;
	dispatch(onPaymentAmountChangeAction(newProperties));
	return;
}

const calculatePaymentAmountRange = async (minFundingAmount, maxFundingAmount, commission, buyRate, buyRateAdjustment, paymentsCount)  => {
	let params = {};
	params.minFundingAmount = minFundingAmount;
	params.maxFundingAmount = maxFundingAmount;
	params.commission = commission;
	params.buyRate = buyRate;
	params.buyRateAdjustment = buyRateAdjustment;
	params.paymentsCount = paymentsCount;
    try {
        let paymentAmountRange = await axios.post(config.apiUrl + '/fundingPlatformPublic/generateOffer/calculatePaymentAmountRange', params);
        return paymentAmountRange.data; //return { minPaymentAmount: 0, maxPaymentAmount: 1000};	
    } 
    catch(error) {
        console.log(error);
    }																
}

const getProgramRates = (fundingProgramName) => async dispatch => {
	let params = {};
	params.fundingProgramName = fundingProgramName;
    let programRatesResponse = await axios.post(config.apiUrl + '/fundingPlatformPublic/generateOffer/getProgramRates', params);
    dispatch(getProgramRatesAction(programRatesResponse.data));
	return;
}

const resetOffer= () => async dispatch => {
	dispatch(resetOfferAction());
	return;																	
}

const selectOffer = async (newOffer, submissionUuid)  => {
	try {
		let savedStatus = await saveOffer(newOffer, submissionUuid);
		if( savedStatus.status === 0){
			console.log("Offer not saved");
		} else {
			console.log("Offer saved");
		}
	}
	catch(error) {
		console.log(error);
	}
	return;
}

export const sendMerchantsContract = async (account, offer, submissionUuid) => {
	let params = {};
	params.account = account;
	params.offer = offer;
	params.submission = {submissionUuid};
	await axios.post(config.apiUrl + '/fundingPlatformPublic/generateOffer/sendMerchantsContract', params);
    return;
}

const recalculatePaymentAmount = async(params) => {
	const newPaymentAmount = await axios.post(config.apiUrl + '/fundingPlatformPublic/generateOffer/recalculatePaymentAmount', params);
	return newPaymentAmount.data;
}

const recalculatePaymentsCount = async(params) => {
	const newPaymentsCount = await axios.post(config.apiUrl + '/fundingPlatformPublic/generateOffer/recalculatePaymentsCount', params);
	return newPaymentsCount.data;
}

const recalculatePaybackAmount = async(params) => {
	const newPaybackAmount = await axios.post(config.apiUrl + '/fundingPlatformPublic/generateOffer/recalculatePaybackAmount', params);
	return newPaybackAmount.data;
}

const recalculateAmount = async(params) => {
	const newAmount = await axios.post(config.apiUrl + '/fundingPlatformPublic/generateOffer/recalculateFundingAmount', params);
	return newAmount.data;
}

const recalculateHoldbackPercentage = async(params) => {
	const newHoldbackPercentage = await axios.post(config.apiUrl + '/fundingPlatformPublic/generateOffer/recalculateHoldbackPercentage', params);
	return newHoldbackPercentage.data;
}

const recalculateCommissionAmount = async(params) => {
	const newCommissionAmount = await axios.post(config.apiUrl + '/fundingPlatformPublic/generateOffer/recalculateCommissionAmount', params);
	return newCommissionAmount.data;
}

const saveOffer = async(newOffer, submissionUuid) => {
	let savedStatus = await axios.post(config.apiUrl + '/fundingPlatformPublic/generateOffer/saveOffer', {newOffer, submissionUuid});
	return savedStatus.data;
}


export default {
    getGenerateOfferInitialData,
    onFundingAmountChange,
    onTermChange,
    onCommissionChange,
    onPaymentAmountChange,
    calculatePaymentAmountRange,
    getProgramRates,
    resetOffer,
	selectOffer,
	sendMerchantsContract
  }





