import React, {useState, useEffect, useContext, setMainState } from 'react';
import { useLocation } from 'react-router-dom';
import config from '../../../../config';
import axios from 'axios';
import { MerchantStateContext } from '../../application/context/MerchantStateContext';

import LandingA from '../../campaign_variation/var-A/Landing';
import LandingB from '../../campaign_variation/var-B/Landing';
import LandingC from '../../campaign_variation/var-C/Landing';
import LandingD from '../../campaign_variation/var-D/Landing';
import LandingE from '../../campaign_variation/var-E/Landing';



export default function PickVariation(props) {
    const location = useLocation();
	const [landingVar, setLandingVar] = useState();
	const pathNameArray = ['/onboarding/merchant/landing'];
	const { setMainState, mainState, handleChange } = useContext(MerchantStateContext);
	

    const pickVariation =(props, variation) => {

        // variation = 'B';
		switch (variation) {
			case 'A': setLandingVar( <LandingA {...props} variant={variation}/>);
				break;
			case 'B': setLandingVar( <LandingB {...props} variant={variation} />);
				break;
			case 'C': setLandingVar( <LandingC {...props} variant={variation} />);
				break;
			case 'D': setLandingVar( <LandingD {...props} variant={variation} />);
				break;
			case 'E': setLandingVar( <LandingE {...props} variant={variation} />);
				break;
			default:
				console.log('Something went wrong!')
		};
	};



	useEffect((props) => {

		if(sessionStorage['variation'] ){
			let landingVar = JSON.parse(sessionStorage.getItem("variation"));
			pickVariation(props, landingVar );
		} else {
			axios.get(`${config.apiUrl}/api/uplyftOnboarding/abTesting`)
				.then(res => {
					if (res.data.error){ 
						console.error(res.data.error); 
					} else {
						if (pathNameArray.includes(location.pathname)){
							sessionStorage.setItem("variation", JSON.stringify(res.data));
							pickVariation(props, res.data )
						};
					};
				});
		};
    },[]);


    return ( 
        <>
            {landingVar}
        </>
    );
};
