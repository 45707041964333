import React, { useState, useContext } from "react";
import { Redirect } from "react-router-dom";
// import axios from 'axios';
// import config from '../../../config';
import Layout from "./Slayout";
// import Cleave from 'cleave.js/react'
import MaskedInput from 'react-text-mask'
import { StateContext } from '../context/StateContext'
import StepProgressBar from "../../application/components/StepProgressBar";

export default function Contact(props) {
    const [redirect, setRedirect] = useState(false);
    const [formError, setFormError] = useState({});

    // const { state } = useContext(StateContext);
    const { state, handleChange } = useContext(StateContext);
    const { contact__firstName, contact__lastName, contact__email, contact__mobilePhone } = state;
    const dots = [
        { name: "Contact", status: 1 },
        { name: "Address", status: 0 },
        { name: "Done", status: 0 }
    ];

    const formValidation = (e) => {
        let form = e.target
        let isValid = form.checkValidity()
        let formData = new FormData(form)
        let validationMessages = Array.from(formData.keys()).reduce((acc, key) => {
            acc[key] = form.elements[key].validationMessage
            return acc
        }, {})

        setFormError(validationMessages)
        return isValid
    }

    const onInput = (e) => {
        const input = e.target
        // const isValid = input.checkValidity
        setFormError({ ...formError, [input.name]: input.validationMessage })
    }

    const getError = (field) => formError[field]

    const clickSubmit = e => {
        e.preventDefault();
        let isValid = formValidation(e)
        if (isValid) {
            // sendPostRequest(state)
            setRedirect({ redirect: true })
        }
    };

    // const sendPostRequest = (state) => {
    //     axios.post(`${config.apiUrl}/api/uplyftOnboarding/syndicator`, state)
    //         .then(res => {
    //             console.log(res.data)
    //         })
    //         .catch(error => {
    //             console.log('error', error)
    //         })
    // };

    const personalForm = () => (
        <div id='syndicator-contact'>
            <div className='row'>
                <div className='form-group col-12 col-lg-6'>
                    <label className='pb-2'>First Name</label>
                    <input
                        onInput={onInput}
                        onChange={handleChange("contact__firstName")}
                        type='text'
                        name='contact__firstName'
                        className='form-control form-elegant text-muted'
                        placeholder='First'
                        value={contact__firstName}
                        required
                    />
                    <small className='text-danger'>{getError('contact__firstName')}</small>
                </div>
                <div className='form-group col-12 col-lg-6'>
                    <label className='pb-2'>Last Name</label>
                    <input
                        onInput={onInput}
                        onChange={handleChange("contact__lastName")}
                        type='text'
                        name='contact__lastName'
                        className='form-control form-elegant text-muted'
                        placeholder='Last'
                        value={contact__lastName}
                        required
                    />
                    <small className='text-danger'>{getError('contact__lastName')}</small>
                </div>
                <div className='form-group col-12 col-lg-6'>
                    <label className='pb-2'>Email</label>
                    <input
                        onInput={onInput}
                        onChange={handleChange("contact__email")}
                        type='email'
                        name='contact__email'
                        className='form-control form-elegant text-muted'
                        placeholder='owner@ourbusiness.com'
                        pattern='^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$' title='Please match the requested pattern: owner@ourbusiness.com'
                        value={contact__email}
                        required
                    />
                    <small className='text-danger'>{getError('contact__email')}</small>
                </div>
                <div className='form-group col-12 col-lg-6'>
                    <label className='pb-2'>Cell Phone</label>
                    <MaskedInput
                        onInput={onInput}
                        onChange={handleChange("contact__mobilePhone")}
                        name='contact__mobilePhone'
                        // options={{ numericOnly: true, delimiters: ['(', ') ', '-'], blocks: [0, 3, 3, 4] }}
                        mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                        guide={true}
                        keepCharPositions={true}
                        className='form-control form-elegant text-muted'
                        pattern='((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}' title='Please match the requested pattern: (555) 555 -5555'
                        placeholder='(555) 555 -5555'
                        value={contact__mobilePhone}
                        required
                    />
                    <small className='text-danger'>{getError('contact__mobilePhone')}</small>
                </div>
            </div>

        </div>
    );

    return (
        <>
            {redirect && <Redirect to='/onboarding/syndicator/account' />}
            <StepProgressBar dots={dots} />
            <Layout
                title='Send us a message'
                htmlTitle='Contact'
                preRoute=''
                linkBtnDisplay='none'
                showPreButton='hidden'
                preRouteClass='btn btn-light'
                className='p-5'
                invokeFunction={clickSubmit}
            >
                {personalForm()}
            </Layout>
        </>
    );
}
