import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
// import { getTermValues } from '../actions/generateOfferActions'

import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

class TermComponent extends Component {

	constructor(props) {
		super(props);

		this.state = {
			term: this.props.maxTerm
			, paymentAmount: this.props.paymentAmount
		};
	}

	componentDidUpdate() {
		if (this.state.paymentAmount !== this.props.paymentAmount) {
			this.setState({
				paymentAmount: this.props.paymentAmount
				, term: this.props.term
			});
		}
	}

	onChange = (e) => {
		let { value } = e.target;
		this.setState({
			term: parseFloat(value || 0)
		});
	}

	onBlur = (e, newOffer, term, programRates) => {
		let { value, min, max } = e.target;
		value = Math.max(Number(min), Math.min(Number(max), Number(value)));
		this.setState({
			term: parseFloat(value || 0)
		});
		this.props.onTermChange(parseFloat(value || 0), newOffer, programRates);
	}

	createMarks = (maxTerm) => {
		let marks = {};
		this.props.programRates.forEach(programRate => {
			if (programRate.months <= maxTerm) {
				marks[programRate.months] = <strong>{programRate.months}</strong>;
			}
		});
		return marks;
	}

	render() {
		let these = this;
		return (
			<div className='container mb-5 mb-md-0'>
				<div className="row">
					<div className="form-group col-12 col-lg-3">
						<label> Term (M)</label>
						<div className='d-none d-lg-block'>
							<div className='input-group'>
								<input type="text" min={this.props.programRates[0].months} max={this.props.maxTerm} className="form-control" name="term" value={this.state.term} onChange={(e) => this.onChange(e)} onBlur={(e) => this.onBlur(e, this.props.newOffer, this.state.term, this.props.programRates)} />
								<div className="input-group-append">
									<span className="input-group-text"><i class="fa fa-calendar" aria-hidden="true"></i></span>
								</div>
							</div>
						</div>
					</div>
					<div className="col-12 col-lg-9 d-flex align-items-center">
						<Slider
							min={this.props.programRates[0].months}
							max={this.props.maxTerm}
							defaultValue={this.state.term}
							value={this.state.term}
							step={0.5}
							marks={this.createMarks(this.props.maxTerm)}
							allowCross={true}
							dots={false}
							trackStyle={{ backgroundColor: '#7d4fee' }}
							handleStyle={{ borderColor: '#e6e6ff', backgroundColor: '#927dfe', color: '#f2f' }}
							railStyle={{ backgroundColor: '#e6e6ff' }}
							dotStyle={{ borderColor: '#927dfe', backgroundColor: '#927dfe' }}
							onChange={function (value) {
								let newTerm = parseFloat(value);
								these.setState({
									term: newTerm
								});
							}}
							onAfterChange={function () {
								these.props.onTermChange(these.state.term, these.props.newOffer, these.props.programRates);
							}}
						/>
					</div>
				</div>
			</div>
		)
	}
}

//PropTypes
TermComponent.propTypes = {
	term: PropTypes.number.isRequired,
}

const mapStateToProps = state => ({
	newOffer: state.generateOffer.newOffer
	, maxTerm: state.generateOffer.maxTerm
	, term: state.generateOffer.term
	, programRates: state.generateOffer.programRates
	, paymentAmount: state.generateOffer.paymentAmount
});

export default connect(mapStateToProps, {})(TermComponent);