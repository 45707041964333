import React, {useState,useEffect,useContext} from "react";
import { useHistory } from "react-router-dom";
import Select from 'react-select';
import Cleave from 'cleave.js/react'
import Layout from "./Layout";
import { MerchantStateContext } from '../context/MerchantStateContext'

import { returnTrueIsSpanishIsPrefered, translateIfPreferedLanguageIsSpanish } from '../../../../lib/util';

export default function BusinessType(props) {
	const [formError, setFormError] = useState({});
	const { setMainState,  mainState, natureOfBusiness, corporateStructures, handleChange, data } = useContext(MerchantStateContext);
	const { account__name, contact__ownership, account__natureOfBusiness, account__natureOfBusinessOther, account__startDate, account__dba, account__corporateStructure } = mainState;

	const history = useHistory();

	useEffect(() => {
		// Prevent from merchants to land directly on the page w/out filling the previous pages
		if (!sessionStorage['mainState']) history.push('/onboarding/merchant/');
		//persist state => pull state from session storage everything the page reload
		if (sessionStorage['mainState'] ){
			let tempState = JSON.parse(sessionStorage.getItem("mainState"));
			// incase of attempt to "jump" pages using urls in favorite or saved link etc, without going through the form workflow
			// inside the "if statement" we are 'sampling' one property from each previous component to see if exist, if one is missing,
			// we send the merchant to the beginning of the app
			if (!tempState.opportunity__requestedAmount) history.push('/onboarding/merchant/');
			setMainState({
				...tempState
				,account__name: tempState.account__name
				,account__dba: tempState.account__dba
				,contact__ownership: tempState.contact__ownership
				,account__natureOfBusiness: tempState.account__natureOfBusiness
				,account__natureOfBusinessOther: tempState.account__natureOfBusinessOther
				,account__corporateStructure: tempState.account__corporateStructure
				,account__startDate: tempState.account__startDate
			});
		};
	}, []);

	const isOtherNatureOfBusiness = mainState.account__natureOfBusiness === "Other"? true : false;


	const dots = [
		
		{ name: "Funding", status: 1 },
		{ name: "Business", status: 0 },
		{ name: "", status: 0 },
		{ name: "Personal", status: 0 },
		{ name: "", status: 0 },
		{ name: "Signature", status: 0 },
		{ name: "Upload", status: 0 }
	];

	const dotsInSpanish = [
		{ name: "Financiamiento", status: 1 },
		{ name: "Negocio", status: 0 },
		{ name: "", status: 0 },
		{ name: "Personal", status: 0 },
		{ name: "", status: 0 },
		{ name: "Firma", status: 0 },
		{ name: "Documentos", status: 0 }
	];

	const formValidation = (e) => {
		let form = e.target;
		let isValid = form.checkValidity();
		let formData = new FormData(form);
		let validationMessages = Array.from(formData.keys()).reduce((acc, key) => {
			acc[key] = form.elements[key].validationMessage;
			return acc;
		}, {});
		setFormError( validationMessages );
		return isValid;
	};

	const onInput = (e) => {
		const input = e.target;
		// const isValid = input.checkValidity
		setFormError({ ...formError, [input.name]: input.validationMessage });
	};

	const onInputChange = ({action}) => {
		//if(account__natureOfBusiness) { setFormError({...formError, 'account__natureOfBusiness': ''}); return; }
		//if(account__corporateStructure) { setFormError({...formError, 'account__corporateStructure': ''}); return; }
		switch (action) {
			case 'set-value':
				setFormError({ ...formError, 'account__natureOfBusiness': '' });
				setFormError({ ...formError, 'account__corporateStructure': '' });
				return;
			default:
				return;
		}
	};

	const getError = (field) => formError[field];

	const clickSubmit = e => {
		e.preventDefault();
		let today = new Date();
		let userDate = new Date(account__startDate);
		let isValid = formValidation(e);
		if(account__startDate !== "" && userDate > today) { setFormError({ ...formError, 'account__startDate': 'Please enter valid date'}); isValid = false; }
		if(parseInt(account__startDate.substring(6,10)) < 1900) { setFormError({ ...formError, 'account__startDate':'Date can\'t be before 1900' }); isValid = false; } 
		if(account__natureOfBusiness === '') { setFormError({ ...formError, 'account__natureOfBusiness': 'Please select an item in the list.' }); isValid = false; } // custom error validation needed for this field
		if(account__natureOfBusiness === 'Other' && account__natureOfBusinessOther === '') { setFormError({...formError, 'account__natureOfBusinessOther': 'Please enter the nature of your business.'})}
		if(account__corporateStructure === '') { setFormError({ ...formError, 'account__corporateStructure': 'Please select an item in the list.' }); isValid = false; }
		if (isValid)
		{
			sessionStorage.setItem("mainState", JSON.stringify(mainState));
			history.push('/onboarding/merchant/address');
		}
	};

	const customStyles = {
		option: (provider) => ({
			...provider,
			borderStyle: 'none',
				fontSize: '0.8rem',
				color:'#140046'
		}),
		control: (provider) => ({
			...provider,
			borderStyle: 'none',
			borderColor: 'none',
			fontSize: '1.2rem',
		})
	};

	const theme = (theme) => ({
		...theme,
		colors: {
			...theme.colors,
			primary25: 'rgba(230, 227, 252, 1)',
			primary: '#6610f2',
		},
	});



	const businessForm = () => (
		<div id='business' className='p-2'>
			<div className='row'>
				<div className='form-group col-12 col-lg-6'>
					<label className=''>{translateIfPreferedLanguageIsSpanish(mainState.contact__preferred__language,'Business Legal Name', 'Nombre registrado del negocio')}</label>
					<input
						onInput={onInput}
						onChange={handleChange("account__name")}
						name='account__name'
						type='text'
						className='form-control form-elegant text-muted'
						placeholder='Your Company Name Here'
						value={account__name}
						pattern="^\s*[a-zA-Z0-9]\s*(?:\S\s*){2,}"
						required
					/>
					<small className='text-danger'>{getError('account__name')}</small>
				</div>
				<div className='form-group col-12 col-lg-6'>
					<label className=''>{translateIfPreferedLanguageIsSpanish(mainState.contact__preferred__language,'d/b/a', 'Nombre bajo el que opera el negocio')}</label>
					<input
						onInput={onInput}
						onChange={handleChange("account__dba")}
						name='account__dba'
						type='text'
						className='form-control form-elegant text-muted'
						placeholder=''
						value={account__dba}
						pattern="^\s*[a-zA-Z0-9]\s*(?:\S\s*){2,}"
					/>
					<small className='text-danger'>{getError('account__dba')}</small>
				</div>
			</div>
			<div className='row'>
				<div className='form-group col-12 col-lg-6'>
					<label className=''>{translateIfPreferedLanguageIsSpanish(mainState.contact__preferred__language,'Corporate Structure', 'Estructura corporativa')}</label>
					<select
						onChange={onInput}
						name='account__corporateStructure'
						className='form-control form-elegant text-muted'
						onInput={handleChange("account__corporateStructure")}
						placeholder = "select an option"
						value={account__corporateStructure}
						required
					>
						<option value='' defaultValue className='text-muted'>Select an Option</option>
						{corporateStructures.map((value, i) => (
							<option key={i}>{value}</option>
						))}
					</select>
					<small className='text-danger'>{getError('account__corporateStructure')}</small>
				</div>
				<div className='form-group col-12 col-lg-6'>
					<label className=''> {translateIfPreferedLanguageIsSpanish(mainState.contact__preferred__language,'Your Ownership %', '¿Qué porcentaje posees del negocio?')}</label>
					<div className='input-group'>
						<Cleave
							onInput={onInput}
							onChange={handleChange("contact__ownership")}
							options={{ numericOnly: true, blocks: [3] }} //added masking for input length (limit to 3 dig)
							name='contact__ownership'
							type='number'
							className='form-control form-elegant text-muted'
							placeholder='0-100%'
							value={contact__ownership}
							min='1'
							max='100'
							required
						/>
						<div className="input-group-append">
							<span style={{ background: '#927dff', color: '#fff' }} className="input-group-text">%</span>
						</div>
					</div>
					<small className='text-danger'>{getError('contact__ownership')}</small>
				</div>
			</div>
			<div className='row'>
				<div className='form-group col-12 col-lg-6'>
					<label className=''>{translateIfPreferedLanguageIsSpanish(mainState.contact__preferred__language,'What does the business do?', 'Sector del negocio')}</label>
					<Select
						name='account__natureOfBusiness'
						onInputChange={onInputChange}
						options={natureOfBusiness}
						theme = {theme}
						value={{ label: account__natureOfBusiness }}
						styles={customStyles}
						className='form-elegant text-muted'
						onChange={handleChange("account__natureOfBusinessId")}
						placeholder = "select an option"
					/>
					<small className='text-danger'>{getError('account__natureOfBusiness')}</small>
				</div>
				<div className='form-group col-12 col-lg-6'>
				{isOtherNatureOfBusiness ? ( 
					<>
						<label className=''>{translateIfPreferedLanguageIsSpanish(mainState.contact__preferred__language,'(Other) What does the business do?', '¿Otro, que hace tu negocio?')}</label>
						<input
							onInput={onInput}
							onChange={handleChange("account__natureOfBusinessOther")}
							name='account__natureOfBusinessOther'
							type='text'
							className='form-control form-elegant text-muted'
							placeholder='What Your Business Does Here'
							value={account__natureOfBusinessOther}
							pattern="^\s*[a-zA-Z0-9]\s*(?:\S\s*){2,}"
							required
						/>
						<small className='text-danger'>{getError('account__natureOfBusinessOther')}</small>
					</>
				):(
					<>
						<label className=''>{translateIfPreferedLanguageIsSpanish(mainState.contact__preferred__language,'Business Registration Date', 'Fecha de registro empresarial')}</label>
						<Cleave
							onInput={onInput}
							onChange={handleChange("account__startDate")}
							options={{ date: true, delimiter: '/', datePattern: ['m','d','Y'] }}
							name='account__startDate'
							className='form-control form-elegant text-muted'
							placeholder='mm / dd / yyyy'
							value={account__startDate}
							required
						/>
						<small className='text-danger'>{getError('account__startDate')}</small>
					</>
				)}
				</div>
			</div>
			{isOtherNatureOfBusiness && ( 
				<div className='row'>
					<div className='form-group col-12 col-lg-6'>
						<label className=''>{translateIfPreferedLanguageIsSpanish(mainState.contact__preferred__language,'Business Registration Date', 'Fecha de registro empresarial')}</label>
						<Cleave
							onInput={onInput}
							onChange={handleChange("account__startDate")}
							options={{ date: true, delimiter: '/', datePattern: ['m','d','Y'] }}
							name='account__startDate'
							className='form-control form-elegant text-muted'
							placeholder='mm / dd / yyyy'
							value={account__startDate}
							required
						/>
						<small className='text-danger'>{getError('account__startDate')}</small>
					</div>
				</div>
			)}
			
		</div>
	);

	return (
		<Layout
			title= {translateIfPreferedLanguageIsSpanish(mainState.contact__preferred__language,'Tell us about your business', 'Cuéntanos acerca de tu negocio')}
			preRouteText = {translateIfPreferedLanguageIsSpanish(mainState.contact__preferred__language,'Back', 'Atras')}
			btnContinueText = {translateIfPreferedLanguageIsSpanish(mainState.contact__preferred__language,'Continue', 'Continuar')}
			htmlTitle='Business'
			preRoute=''
			showPreButton= {data.isLocalHostIP ? 'visible' :  'hidden'}
			preRouteClass='btn btn-light'
			className=''
			invokeFunction={clickSubmit}
			btnContinueId='businessCBtn'
			dots = {returnTrueIsSpanishIsPrefered(mainState.contact__preferred__language) ? dotsInSpanish :dots }
		>
			{businessForm()}
		</Layout>
	);
}
