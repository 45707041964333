import { createReducer } from 'reduxsauce'
import Types from './types'

// The initial state of this reducer
export const INITIAL_STATE = {
    verifyContractStatus: {}
}

// Prepare Contract
export const prepareContract = (state = INITIAL_STATE, action) => {
    return {
        ...state
        , verifyContractStatus: action.statuses.verifyContractStatus
    }
}

//On Signing Event
export const onVerificationEvent = (state = INITIAL_STATE, action) => {
    console.log(action.contractVerificationStatus.verifyContractStatus, 'action.contractVerificationStatus');
    return {
        ...state
        , verifyContractStatus: action.contractVerificationStatus.verifyContractStatus
    }
}

// map our action types to our reducer functions
export const HANDLERS = {
    [Types.PREPARE_CONTRACT]: prepareContract,
    [Types.ON_VERIFICATION_EVENT]: onVerificationEvent,
    // [Types.HANDLE_SIGN_CONTRACT_ERROR]: handleSignContractError,
  }
  
  export default createReducer(INITIAL_STATE, HANDLERS)