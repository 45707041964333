import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from "react-helmet";
import './SignContract.css';
import FundingProgressBarComponent from '../common/FundingProgressBarComponent';
import HelloSignEmbeddedComponent from '../common/HelloSignEmbeddedComponent';
import SignContractDoneComponent from './SignContractDoneComponent';
import signContractOperations from './duck/operations'

// const _ = require('lodash');
class SignContractContainer extends Component {

	constructor(props) {
		super(props);
		this.state = {
			submissionUuid: ''
			, contactUuid: ''
			, signingStatus: ''
			, urlStatus: ''
			, contactSigningStatus: {}
		};
	}
	async componentDidMount() {
		let submissionUuid = this.props.match.params.submissionUuid;
		let contactUuid = this.props.match.params.contactUuid;
		await this.props.prepareContract(contactUuid, submissionUuid);
		this.setState({ submissionUuid: submissionUuid, contactUuid: contactUuid, contactSigningStatus: this.props.contactSigningStatus, signingStatus: this.props.signingStatus, urlStatus: this.props.urlStatus });
	}

	render() {
		if (this.state.contactSigningStatus.signURL) {
			let clientId = process.env.REACT_APP_SIGN_CONTRACT_CLIENT_ID_LEGACY;
			return (
				<>
					<Helmet>
                        <title>Uplyft Capital Funding | Sign-Contract</title>
                    </Helmet>
					<div id='helloSign' className="layoutFading space">
						<FundingProgressBarComponent dots={[{ name: "Contract Review", status: 1 }, { name: "Sign Contract", status: 1 }, { name: "Bank Verification", status: 0 }, { name: "Upload Docs", status: 0 }, { name: "Done", status: 0 }]} />
						<div className='container pt-2 space'>
							{this.props.eventListener ?
								<div id="signContractDone">
									<SignContractDoneComponent submissionUuid={this.state.submissionUuid} handleSignContractError={this.props.handleSignContractError} />
								</div>
								:
								<div className='shadow p-5 mb-2 bg-white roundCorners'>
									<div id="signContract">
										<HelloSignEmbeddedComponent
											// clientId='3c781ac6f277a9789cd0dc6da95c31db'
											clientId={clientId}
											signatureId={this.state.contactSigningStatus.signatureId}
											url={this.state.contactSigningStatus.signURL.url}
											onSigningEvent={this.props.onSigningEvent}
											signingEventParams={{ submissionUuid: this.state.submissionUuid, contactUuid: this.state.contactUuid }}
											uxVersion={2}
											inContainer={true}
										/>
									</div>
								</div>
							}
						</div>
					</div>
				</>
			);
		} else {
			return (
				<div className='spinner'>
					<div className='loader'>Loading...</div>
				</div>
			);
		}
	}
}

SignContractContainer.propTypes = {
	offer: PropTypes.object
};

const mapStateToProps = state => ({
	contactsSigningStatus: state.signContract.contactsSigningStatus,
	contactSigningStatus: state.signContract.contactSigningStatus,
	eventListener: state.signContract.contactSigningStatus.eventListener,
	signingStatus: state.signContract.contactSigningStatus.status,
	urlStatus: state.signContract.contactSigningStatus.signURL ? state.signContract.contactSigningStatus.signURL.status : '',
});

const mapDispatchToProps = dispatch => {
	const prepareContract = (contactUuid, submissionUuid) => dispatch(signContractOperations.prepareContract(contactUuid, submissionUuid));
	const handleSignContractError = () => dispatch(signContractOperations.handleSignContractError());
	const onSigningEvent = (params) => dispatch(signContractOperations.onSigningEvent(params));

	return {
		prepareContract,
		onSigningEvent,
		handleSignContractError
	}
};

export default connect(mapStateToProps, mapDispatchToProps)(SignContractContainer);