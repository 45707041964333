import React from 'react';
import { Popover, OverlayTrigger } from 'react-bootstrap';

export default function MessagePopover({message}) {

    const popover = (
        <Popover id="messagePopover">
          <Popover.Title as="h3" style={{backgroundColor: '#927dff', color:'#fff'}} >{message.title}</Popover.Title>
          <Popover.Content>
              <Message message={message} />
          </Popover.Content>
        </Popover>
      );
      
      const MessagePopover = () => (
        <OverlayTrigger trigger="click" placement="right" overlay={popover}>
          <i className = "fa fa-question-circle"></i>
        </OverlayTrigger>
      );
      
    return <MessagePopover />
};


const Message = ({message}) => {
  return (
    <div>
      <p>{message.body}</p>
      {message.comments.map( comment => (
        <p className='mb-0'><b>*** {comment}</b></p>
      ))}
    </div>
  );
};

