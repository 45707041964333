import React, { Component } from 'react';
class ContactSupportComponent extends Component {
    render() {
        return (
            <div className='row contact-support'>
                <div className="container" style={{ 
                    width: 80 + '%',
                    textAlign: 'center',
                    lineHeight: 'normal',
                    marginTop: 40 + 'px'
                    }}>
                    <small>link at least one business bank account or as many as you want to speed up your application, call our support line  <a href="tel:8005157531">800 515-7531</a> if you have questions</small>
                </div>
            </div>
        );
    }
}

ContactSupportComponent.propTypes = {
};

export default ContactSupportComponent