import React, { Component } from 'react';
import { connect } from 'react-redux';
import { history } from '../../../store';
import { Helmet } from "react-helmet";
// import PlaidLink from 'react-plaid-link'
import PropTypes from 'prop-types';
import PlaidEmbeddedComponent from '../common/PlaidEmbeddedComponent';
import FundingProgressBarComponent from '../common/FundingProgressBarComponent';
import AddBankAccountContactSupportComponent from '../common/AddBankAccountContactSupportComponent';

import { DeleteForever } from '@material-ui/icons';
import bankVerificationOperations from './duck/operations'

import './BankVerification.css';
const _ = require('lodash');

class BankVerificationContainer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      metadata: {}
      , contactUuid: ''
      , token: ''
      , linkedBanks: []
      , plaidLinkOpen: ''
    };
    this.handleOnSuccess = this.handleOnSuccess.bind(this);
    this.handleOnExit = this.handleOnExit.bind(this);
  }

  async componentDidMount() {
    this.setState({
      plaidLinkOpen: true
    });
    let submissionUuid = this.props.match.params.submissionUuid;
    let contactUuid = this.props.match.params.contactUuid;
    this.setState({
      contactUuid: contactUuid
    });
    await this.props.getBankVerificationInitialData(submissionUuid);
  }

  async handleOnSuccess(token, metadata) {
    this.setState({
      plaidLinkOpen: false
    });
    let linkedBank = { token: token, metadata: metadata };
    let linkedBanks = this.state.linkedBanks;
    let indexedLinkedBanksToken = _.keyBy(linkedBanks, 'token');
    let institutionsIds = linkedBanks.map(linkedBank => { return linkedBank.metadata.institution.institution_id });
    if (!indexedLinkedBanksToken[token]) {
      if (institutionsIds.indexOf(linkedBank.metadata.institution.institution_id !== -1)) {
        let newLinkedBanks = _.remove(linkedBanks, function (linkedBankItem) {
          return linkedBank.metadata.institution.institution_id !== linkedBankItem.metadata.institution.institution_id;
        });
        newLinkedBanks.push(linkedBank);
        this.setState({
          linkedBanks: newLinkedBanks
        });
      } else {
        linkedBanks.push(linkedBank);
        this.setState({
          linkedBanks: linkedBanks
        });
      }
    }
  }
  handleOnExit() {
    this.setState({
      plaidLinkOpen: false
    });
    // handle the case when your user exits Link
  }

  handlePlaidLinkOpen() {
    this.setState({
      plaidLinkOpen: true
    });
  }

  handleDelete(linkedBankParam) {
    let linkedBanks = this.state.linkedBanks;
    let newLinkedBanks = _.remove(linkedBanks, function (linkedBank) {
      return linkedBankParam.token !== linkedBank.token;
    });
    this.setState({
      linkedBanks: newLinkedBanks
    });
  }

  async handleGetBankInformation(linkedBanks) {
    await this.props.getBankData(linkedBanks, this.props.account.uuid, this.props.opportunity.uuid);
    history.push('/funding/upload-docs/' + this.props.submissionUuid + '/' + this.state.contactUuid);
  }

  controlButtons = () => (
    <>
      <div className='container d-flex justify-content-between pt-2'>
        <button className='btn btn-add-purple display' style={{ visibility: "visible" }} onClick={() => this.handlePlaidLinkOpen()}>
          {this.state.linkedBanks.length ? 'Link Another Bank Account(s)' : 'Link Your Bank Account(s)'}
        </button>
        <button type="submit" className='btn btn-primary display' style={{ visibility: "visible" }} onClick={() => this.handleGetBankInformation(this.state.linkedBanks)}>
          Finish
        </button>
      </div>
      <div>

        <button className='btn btn-add-purple isDone p-2  mt-3' onClick={() => this.handlePlaidLinkOpen()}>
          {this.state.linkedBanks.length ? 'Link Another Bank Account(s)' : 'Link Your Bank Account(s)'}
        </button>

      </div>
      <div>
        <button type="submit" className='btn btn-primary isDone p-2 mt-3' onClick={() => this.handleGetBankInformation(this.state.linkedBanks)}>
          Finish
        </button>

      </div>
    </>
  );

  	render() {
    	return (
			<>
				<Helmet>
               <title>Uplyft Capital Funding | Bank-Verification</title>
            </Helmet>
				<div className="layoutFading space">
					<FundingProgressBarComponent dots={[{ name: "Contract Review", status: 1 }, { name: "Sign Contract", status: 1 }, { name: "Bank Verification", status: 1 }, { name: "Upload Docs", status: 0 }, { name: "Done", status: 0 }]} />
					<div id="bankVerificationContainer" className='container pt-3'>
						<div>
							<h2 className='pb-3 title page-title'> Linked Bank Account(s) </h2>
						</div>
						<div className='shadow p-5 mb-3 bg-white roundCorners'>
							<table id='bankVerification' className='table table-striped table-purple'>
								<tbody>
									{this.state.linkedBanks.length > 0 ?
									this.state.linkedBanks.map((linkedBank, i) =>
									<tr key={i}>
										<td className=''>
                        {linkedBank.metadata.institution.name}
                    </td>
										<td className=''>
											{linkedBank.metadata.accounts.map((account, j) =>
											<p key={j} className='content'>{account.name + " ..." + account.mask}</p>
											)}
										</td>
										<td className='text-right'>
                      <a onClick={() => this.handleDelete(linkedBank)} className="btn btn-delete-linked-account">
											  <DeleteForever color="error" />
                        remove account
											</a>
										</td>
									</tr>) :
									<tr>
									<td className='col-md-12'>
										<p>No Banks Account(s) Linked</p>
									</td>
									</tr>
								}
							</tbody>
							</table>
						{this.controlButtons()}
              {/* <div className='display'><ContactSupportComponent /></div> */}
              <div className='display'><AddBankAccountContactSupportComponent /></div>
						</div>
						{/* <div className='isDone pt-3'><ContactSupportComponent /></div> */}
						<div className='isDone pt-3'><AddBankAccountContactSupportComponent /></div>
					</div>
					<div>
						{this.state.plaidLinkOpen ?
							<PlaidEmbeddedComponent
							clientName="Uplyft Capital Verification"
              env="production"
              // env="sandbox"
							product={["auth", "transactions", "assets"]}
              publicKey={process.env.REACT_APP_PLAID_PUBLIC_KEY}
                //  publicKey={REACT_APP_PLAID_PUBLIC_KEY_SANDBOX}
              // webhook = "https://stagingcrm.uplyftcapital.com/webhooks/plaid/eventListener"
							onExit={this.handleOnExit}
							onSuccess={this.handleOnSuccess}>
							</PlaidEmbeddedComponent>
							: ''
						}
					</div>
				</div>
			</>
    	);
  	}
}

BankVerificationContainer.propTypes = {
  getBankData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  submissionUuid: state.bankVerification.submissionUuid
  , account: state.bankVerification.account
  , contacts: state.bankVerification.contacts
  , opportunity: state.bankVerification.opportunity
});

const mapDispatchToProps = dispatch => {
  const getBankVerificationInitialData = (submissionUuid) => dispatch(bankVerificationOperations.getBankVerificationInitialData(submissionUuid));

  const getBankData = (linkedBanks, accountUuid, opportunityUuid) => bankVerificationOperations.getBankData(linkedBanks, accountUuid, opportunityUuid);
  return {
    getBankVerificationInitialData,
    getBankData,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(BankVerificationContainer);