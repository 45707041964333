import React, { useState, useEffect, useContext} from "react";
import { Helmet } from 'react-helmet';
import HeaderWave from '../../layout/HeaderWave';
import UplyftPhoneAppInfo from './UplyftPhoneAppInfo';
import UplyftInfoLanding from "./UplyftInfoLanding";
import TrustBox from "./TrustBox";
import UplyftPhoneAppInfo_esp from './UplyftPhoneAppInfo_esp';
import UplyftInfoLanding_esp from "./UplyftInfoLanding_esp";
import TrustBox_esp from "./TrustBox_esp";
import './Landing.css';
import { MerchantStateContext } from '../application/context/MerchantStateContext';
import ServerError from "../application/components/ServerError";

export default function Layout({
    title = "Title",
    htmlTitle,
    className,
    children,
    invokeFunction,
    props
}) {
    const { setMainState, mainState, handleChange, lang } = useContext(MerchantStateContext);
    const { is_spanish } = lang ;
    const [serverError, setServerError] = useState(false);

	useEffect(() => {
		let sessionData = sessionStorage['data']? JSON.parse(sessionStorage.getItem("data")):'';
		if (sessionData.app){
			setServerError({message:<> Your signed application has already been submitted. <br/>If you need to make changes or have received this message in error, please contact one of our funding advisors at the email below.<br/><br/><a href="mailto:sales@uplyftcapital.com"><p style={{color:"#6b1cf5"}}>Click here to email us: sales@uplyftcapital.com</p></a></> })
		};
    }, []);

    const clickSubmit = e => { invokeFunction(e); };

    return (
        serverError ? <ServerError error={serverError} /> :
        <>
            <HeaderWave />
            <Helmet>
                <title>{`Uplyft Capital Onboarding Merchant | ${htmlTitle}`}</title>
           
            </Helmet>
            <main id='merchant_LandingPage' >
                <section className='container content layoutFading'>
                    <div className={className}>
                        <div className="row">
                            <div className="col-12 col-lg-4">
                                {is_spanish ?
                                    <div className='container text-left display'>
                                        <h1 className="h1-title mt-3">Préstamos de negocios</h1>
                                        <h2>es lo que hacemos!</h2>
                                        <h5>Aprobado y financiado el mismo día.</h5>
                                                
                                                
                                            
                                        <div className="row">
                                            <div className="col-6 text-left">
                                                <div className="trustpilot-widget" data-locale="en-US" data-template-id="5613c9cde69ddc09340c6beb" data-businessunit-id="5dcdc4803509e40001dc2f77" data-style-height="100%" data-style-width="100%" data-theme="light">
                                                <a href="https://www.trustpilot.com/review/uplyftcapital.com" target="_blank" rel="noopener noreferrer">Trustpilot</a>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <img src="/png/ratings-bbb.png" alt="information icon" />
                                            </div>
                                        </div>
                                    </div>
                                    :
                                        <div className='container text-left display'>
                                            <h1 className="h1-title mt-3">Business Loans </h1>
                                            <h2>is what we do!</h2>
                                            <h5>Approved and funded the same day.</h5>
                                            <div className="row">
                                                <div className="col-6 text-left">
                                                    <div className="trustpilot-widget" data-locale="en-US" data-template-id="5613c9cde69ddc09340c6beb" data-businessunit-id="5dcdc4803509e40001dc2f77" data-style-height="100%" data-style-width="100%" data-theme="light">
                                                        <a href="https://www.trustpilot.com/review/uplyftcapital.com" target="_blank" rel="noopener noreferrer">Trustpilot</a>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <img src="/png/ratings-bbb.png" alt="information icon" />
                                                </div>
                                            </div>
                                        </div>
                                    
                                }
                            </div>
                            <div className="col-12 col-lg-7 offset-lg-1 ">
                                <form noValidate onSubmit={(e) => clickSubmit(e)} className='pt-5-lg'>
                                    {/* <div>
                                        <h2 className='pb-3'> {title} </h2>
                                    </div> */}
                                    <div id="shadowBox" className="shadow shadowMobile px-4 py-4 mb-2 xs-mx-1 bg-white roundCorners">
                                        {children}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    {is_spanish 
                    ?
                    <>
                        <div className='mt-8rem mt-4rem-mobile'><UplyftPhoneAppInfo_esp /></div>
                            <div className="trustpilot-widget mt-8rem mt-4rem-mobile" data-locale="en-US" data-template-id="54ad5defc6454f065c28af8b" data-businessunit-id="5dcdc4803509e40001dc2f77" data-style-height="240px" data-style-width="100%" data-theme="light" data-stars="4,5" data-review-languages="es">
                                <a href="https://www.trustpilot.com/review/uplyftcapital.com" target="_blank" rel="noopener">Trustpilot</a>
                            </div>              
                        <div className='mt-8rem mt-4rem-mobile'><UplyftInfoLanding_esp /></div>
                    </>
                    
                    :
                    <>
                        <div className='mt-8rem mt-4rem-mobile'><UplyftPhoneAppInfo /></div>
                                {/* <!-- TrustBox widget - Slider --> */}
                                <div className="trustpilot-widget mt-8rem mt-4rem-mobile" data-locale="en-US" data-template-id="54ad5defc6454f065c28af8b" data-businessunit-id="5dcdc4803509e40001dc2f77" data-style-height="240px" data-style-width="100%" data-theme="light" data-stars="4,5" data-review-languages="en">
                                    <a href="https://www.trustpilot.com/review/uplyftcapital.com" target="_blank" rel="noopener">Trustpilot</a>
                                </div>
                                {/* <!-- End TrustBox widget --> */}
                        <div className='mt-8rem mt-4rem-mobile'><UplyftInfoLanding /></div>
                    </>
                    }
                </section>

            </main>
        </>
    );
}
