import React, { useState, useEffect, useContext } from "react";
import { useLocation , useHistory, Redirect} from "react-router-dom";
import Layout from "../LandingLayout";
import Cleave from 'cleave.js/react';
import { MerchantStateContext } from '../../application/context/MerchantStateContext';
//npm pack => https://www.npmjs.com/package/query-string
import QueryString from 'query-string'

export default function Landing({variant = ''}) {
	const [formError, setFormError] = useState({})
	const { setMainState, setData, mainState, data, handleChange, prePopulateMainState, populateCampaignVariation } = useContext(MerchantStateContext);
	const { opportunity__requestedAmount, opportunity__revenue, opportunity__purposeOfFunding, opportunity__bankAccountType } = mainState;

	const history = useHistory();
	const location = useLocation();

	const referrerCampaign = (location) => {
		const params = QueryString.parse(location.search);
		if (variant) setData({...data, campaignVariation: variant  })
		if (params) {
			// handleDataChange(params)
			populateCampaignVariation(params);
			if (params.ac && params.co && params.op) {
				prePopulateMainState(params)
			} else if(params.campaign){
				setData({...data, campaign:params.campaign, campaignVariation: variant  })
			};
		};
		sessionStorage.setItem("data", JSON.stringify({ ...data, campaign: params.campaign, campaignVariation: variant }));
	};

	useEffect(() => { referrerCampaign(location); }, [location]);

	useEffect(() => {
		if(sessionStorage['mainState'] ){
			let tempState = JSON.parse(sessionStorage.getItem("mainState"));
			setMainState({
				...tempState
				,opportunity__requestedAmount: tempState.opportunity__requestedAmount
				,opportunity__revenue: tempState.opportunity__revenue
				,opportunity__purposeOfFunding: tempState.opportunity__purposeOfFunding
			})
		}
	}, []);

	const formValidation = (e) => {
		let form = e.target
		let isValid = form.checkValidity()
		let formData = new FormData(form)
		let validationMessages = Array.from(formData.keys()).reduce((acc, key) => {
			acc[key] = form.elements[key].validationMessage
			return acc
		}, {})
		setFormError(validationMessages)
		return isValid
	}

	const onInput = (e) => { setFormError({ ...formError, [e.target.name]: e.target.validationMessage }) }

	const getError = (field) => formError[field]


	const clickSubmit = e => {
		e.preventDefault();
		let isValid = formValidation(e)
        if (isValid){
			sessionStorage.setItem("mainState", JSON.stringify(mainState));
			history.push('/onboarding/merchant/business');
		};
    };

	const purposeFunding = [
		"Expansion",
		"Funding Existing Project",
		"Payroll",
		"Paying Outstanding Debt",
		"Consolidation",
		"Starting a New Venture",
		"Improving Cash Flow",
		"Adding Inventory"
	];

	const bankAccountType = [
		"Business",
		"Investment",
		"Personal"
	];

	const fundingForm = () => (
		<div id='funding-LP' className='p-2'>
			<div className='row'>
				<div className='form-group col-12 col-lg-6'>
					<label className='pb-2'>Requested Amount</label>
					<Cleave
						onInput={onInput}
						name='opportunity__requestedAmount'
						type='text'
						maxLength='10'
						placeholder='Amount ($5-$500k)'
						// mask={amountNumberMask}
						options={{ numeral: true, numeralThousandsGroupStyle: 'thousand', noImmediatePrefix: true, prefix: '$' }}
						onChange={handleChange("opportunity__requestedAmount")}
						className='form-control form-elegant text-muted'
						value={opportunity__requestedAmount}
						required
					/>
					<small className='text-danger'>{getError('opportunity__requestedAmount')}</small>
				</div>

				<div className='form-group col-12 col-lg-6 '>
					<label className='pb-2'>Purpose of funding?</label>
					<select
						onChange={onInput}
						name='opportunity__purposeOfFunding'
						value={opportunity__purposeOfFunding}
						type='text'
						onInput={handleChange("opportunity__purposeOfFunding")}
						className='form-control form-elegant text-muted'
						required
					>
						<option value='' defaultValue className='text-muted'>
							Select an Option
						</option>
						{purposeFunding.map((funding, i) => (
							<option key={i}>{funding}</option>
						))}
					</select>
					<small className='text-danger'>{getError('opportunity__purposeOfFunding')}</small>
				</div>
			</div>

			<div className='row'>
				<div className='form-group col-12 col-lg-6'>
					<label className='pb-2'>Monthly business revenue</label>
					<Cleave
						onInput={onInput}
						name='opportunity__revenue'
						placeholder='Monthly Revenue'
						// mask={amountNumberMask}
						maxLength='10'
						options={{ numeral: true, numeralThousandsGroupStyle: 'thousand', noImmediatePrefix: true, prefix: '$' }}
						onChange={handleChange("opportunity__revenue")}
						className='form-control form-elegant text-muted'
						value={opportunity__revenue}
						required
					/>
					<small className='text-danger'>{getError('opportunity__revenue')}</small>
				</div>

				<div className='form-group col-12 col-lg-6'>
					<label className='pb-2'>Bank Account type?</label>
					<select
						onChange={onInput}
						name='opportunity__bankAccountType'
						value={opportunity__bankAccountType}
						type='text'
						onInput={handleChange("opportunity__bankAccountType")}
						className='form-control form-elegant text-muted'
						required
					>
						<option value='' defaultValue className='text-muted'>
							Select an Option
						</option>
						{bankAccountType.map((funding, i) => (
							<option key={i}>{funding}</option>
						))}
					</select>
					<small className='text-danger'>{getError('opportunity__bankAccountType')}</small>
                </div>
			</div>
			<div className='row pt-3'>
				<div className='form-group col-12 col-lg-6 d-flex align-items-end'>
					<button
						id="btnPrimaryLanding"
						type="submit"
						className="btn btn-primary inside-form d-flex align-items-center justify-content-between w-100"
					>
						Get pre approved today

						<span className="mobile-none">
							<i className="fa fa-paper-plane btn-add-on"></i>
						</span>
					</button>
				</div>
			</div>

		</div>
	);

	return (
		<>
			<Layout
				title=''
				htmlTitle='Funding'
				preRoute=''
				showPreButton='hidden'
				linkBtnDisplay='none'
				preRouteClass=''
				className=''
				invokeFunction={(e) => clickSubmit(e)}
				btnContinueId = 'fundingCBtn'
			>
				{fundingForm()}
			</Layout>
		</>
	);
}