import React, { useState, useEffect, useContext } from "react";
import config from '../../../../../config';
import axios from 'axios';
import { Link, useHistory, useParams } from "react-router-dom";
import SignaturePad from "react-signature-canvas";
import "./eSign.style.css";
import StepProgressBar from "../StepProgressBar";
import { MerchantStateContext } from '../../context/MerchantStateContext';
import { Helmet } from "react-helmet";
import ServerError from "../ServerError";

import { returnTrueIsSpanishIsPrefered , translateIfPreferedLanguageIsSpanish } from '../../../../../lib/util';

export default function ESign(props) {
	const [disableButton, setDisableButton] = useState('');
	const [loading, setLoading] = useState(false);
	const [serverError, setServerError] = useState(false);
	const [formError, setFormError] = useState({
		signature:false,
		terms: false
	});
	const [signatureData, setSignatureData] = useState({
		userSignature: '',
		termsAgreements: false,
	});

	const { mainState, setMainState, setData, data, initializeState, gtm_DataLayer } = useContext(MerchantStateContext);
	const { contact__firstName, contact__lastName, account__name, contact__ownership } = mainState;
	const { termsAgreements } = signatureData;

	const dots = [
		{ name: "Funding", status: 1 },
		{ name: "Business", status: 1 },
		{ name: "", status: 1 },
		{ name: "Personal", status: 1 },
		{ name: "", status: 1 },
		{ name: "Signature", status: 1 },
		{ name: "Upload", status: 0 }
	];

	const dotsInSpanish = [
		{ name: "Financiamiento", status: 1 },
		{ name: "Negocio", status: 1 },
		{ name: "", status: 1 },
		{ name: "Personal", status: 1 },
		{ name: "", status: 1 },
		{ name: "Firma", status: 1 },
		{ name: "Documentos", status: 0 }
	];

	const signatureConditionsText = {
		signatureStatement: {
			english:'I understand that by clicking on the I AGREE button immediately following this notice, I am providing "written instructions" to Uplyft capital under the Fair Credit Reporting Act authorizing Uplyft capital to obtain information from your personal credit report or other information from Experian. I authorize Uplyft capital to obtain such information solely to conduct a pre-qualification for credit.',
			spanish:'Entiendo que al hacer clic en el botón ACEPTO inmediatamente después de este aviso, estoy proporcionando "instrucciones escritas" a Uplyft capital según la Ley de Informe Justo de Crédito que autoriza a Uplyft capital a obtener información de su informe de crédito personal u otra información de Experian. Autorizo ​​a Uplyft capital a obtener dicha información únicamente para realizar una precalificación para el crédito.',
		}

	}

	const params = useParams();
	const history = useHistory();

	const clearHistoryAndPush = (path) => {
			history.entries = [];
			history.index = -1;
			history.push(path);
	};

	useEffect(() => {
		let sessionData = sessionStorage['data']? JSON.parse(sessionStorage.getItem("data")):'';
		if (sessionData.app){
			setServerError({message:<> Your signed application has already been submitted. <br/>If you need to make changes or have received this message in error, please contact one of our funding advisors at the email below.<br/><br/><a href="mailto:sales@uplyftcapital.com"><p style={{color:"#6b1cf5"}}>Click here to email us: sales@uplyftcapital.com</p></a></> })
		} else if (params.accountUuid && params.contactUuid && params.opportunityUuid){
			setLoading(true);
			let url = `${config.apiUrl}/api/uplyftOnboarding/${params.accountUuid}/accountValidate/${params.contactUuid}/${params.opportunityUuid}`
				axios.get(url)
					.then((res) => setLoading(false) )
					.catch( error => {
						clearHistoryAndPush('/404');
						console.error(error);
					});
		};
	},[]);

	useEffect(() => {
		if (sessionStorage['mainState'] ){
			let tempState = JSON.parse(sessionStorage.getItem("mainState"));
			setMainState({...tempState});
		};
		if (!sessionStorage['data'] ) clearHistoryAndPush('/404') ;
	}, []);


	let sigPad = {};

	const clear = () => {
		sigPad.clear();
		setSignatureData({ ...signatureData, userSignature: '' });
		setFormError({...formError, signature:false, terms: false });
	};

	const confirmSign = async e => {
		e.preventDefault();
		setDisableButton('disabled');
		if (!termsAgreements) {
			setFormError({...formError, terms:true });
			setDisableButton('');
		} else {
			// emptyString = catching empty signature data
			const emptyString = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAC0lEQVQYV2NgAAIAAAUAAarVyFEAAAAASUVORK5CYII='
			let userSignatureAsync = await sigPad.getTrimmedCanvas().toDataURL("image/svg + xml");
			setSignatureData({ ...signatureData, userSignature: userSignatureAsync });
			if (!userSignatureAsync || userSignatureAsync === emptyString ||userSignatureAsync.length < 5000 ) {
				setFormError({...formError, signature:true });
				setDisableButton('');
			} else {
				gtm_DataLayer(0.1, 0.08 );
				setLoading(true);
				await sendPostRequest(userSignatureAsync, termsAgreements);
			};
		};
	};

	const saveAccountData = (account, contact, opportunity ) => {
        let accountData = {
            accountUuid:   account.uuid || ''
            , app: true || ''
            , contactUuid:  contact.uuid || ''
            , opportunityUuid:  opportunity.uuid || ''
            , campaign: account.campaign || ''
            , campaignVariation: account.campaignVariation || ''
			, campaignRecordId: account.campaignRecordId || ''
            , contactName:  contact.name || ''
            , contactFirstName:  contact.firstName || ''
            , contactLastName:  contact.lastName || ''
            , contactEmail:  contact.email || ''
            , isLocalHostIP: opportunity.isLocalHostIP || false
            // , isLocalHostIP: false //testing only
            , referrer: opportunity.referrer || ''
            , r: opportunity.r || data.r || ''
        }
        setData({...data, ...accountData })
        sessionStorage.setItem("data", JSON.stringify(accountData));
    };

	const uploadFilesReminder = (account, contact, opportunity) => {
		axios.post(`${config.apiUrl}/api/uplyftOnboarding/uploadFiles/later`, { account, contact, opportunity })
		.then(res => {
			console.log(res.data.emailStatus);
		})
		.catch(error => {
			console.log('error', error);
		})
	};

	const sendPostRequest = async ( userSignatureAsync = null, termsAgreements = null) => {
		try{
			let axiosRes = await axios.post(`${config.apiUrl}/api/uplyftOnboarding/index`, { mainState, data, signData: userSignatureAsync, termsAgreements })
			if (axiosRes.status === 200){
				const {account, contact, opportunity} = axiosRes.data;
				uploadFilesReminder(account, contact, opportunity);
				await saveAccountData( account, contact, opportunity);
				let role = data.r ? `?r=${data.r}`:'' ;
				initializeState()
				clearHistoryAndPush( `/onboarding/merchant/${account.uuid}/upload/${contact.uuid}/${opportunity.uuid}${role && role}` )
			};
		} catch(error){
			console.error('error', error);
			setServerError({message:<> Something went wrong while saving your application, please contact one of our funding advisors<br/><br/><a href="mailto:sales@uplyftcapital.com"><p style={{color:"#6b1cf5"}}>Click Here To email us: sales@uplyftcapital.com</p></a></> })
		};
	};

	const handleAgreementChange = () => {
		setFormError({...formError, terms: false });
		setSignatureData({ ...signatureData, termsAgreements: !termsAgreements });
	};

	const electronicSignatureForm = () => (
		<main id='esign'>
			<div className='container layoutFading'>
				<Link to={`/onboarding/merchant/${params.accountUuid}/personalInfo/${params.contactUuid}/${params.opportunityUuid}`} className='btn form__btn-back-mobile isDone' data-form-back=''>
					<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'>
						<g transform='translate(-16 -36)'>
							<path
								d='M10,0,8.182,1.818,15.065,8.7H0v2.6H15.065L8.182,18.182,10,20,20,10Z'
								transform='translate(36 56) rotate(180)'
								fill='#927dfe'
							></path>
						</g>
					</svg>
				</Link>
				<div className='shadow p-5 mb-3 bg-white roundCorners'>
					<div>
						<h2 className='pb-3'>
							{translateIfPreferedLanguageIsSpanish(mainState.contact__preferred__language,'Please e-Sign Below', 'Por favor complete la firma electrónica')}


						</h2>
					</div>
					<div className='relativePos disable'>
						<SignaturePad
							className='bg-light'
							canvasProps={{ className: "sigPad" }}
							ref={ref => {sigPad = ref;}}
							clearOnResize = {false}
							onEnd={() => {
								const imageData = sigPad.getTrimmedCanvas().toDataURL("image/svg + xml");
								setSignatureData({ ...signatureData, userSignature: imageData })
							  }}
						/>
						{ formError.signature &&
							<div className='d-flex justify-content-center mb-2' >
								<p style={{textAlign:'center', fontSize: '0.7rem'}} className='text-danger'><b>We couldn't read your signature, it might be too short or empty. Please click "Clear" and try again.</b></p>
							</div>
						}
						<div className='d-flex justify-content-start' >
							<p className='exp_terms'>

								{returnTrueIsSpanishIsPrefered(mainState.contact__preferred__language)
								?  `Al firmar arriba yo, ** ${contact__firstName} ${contact__lastName} **, Reconozco que soy el dueñ(o/a) de ${contact__ownership}% de ${account__name} y soy personalmente responsable de ${account__name}.`
								: `By signing above I, ** ${contact__firstName} ${contact__lastName} **, acknowledge that I am the ${contact__ownership}% owner of ${account__name} and I'm personally liable for ${account__name}.`
								}

							</p>
						</div>
						<div className='mt-2'>
							<p className='exp_terms'>
								{translateIfPreferedLanguageIsSpanish(mainState.contact__preferred__language, signatureConditionsText.signatureStatement.english , signatureConditionsText.signatureStatement.spanish)}
							</p>
						</div>
						<div id='checkBtn' className='d-flex justify-content-center'>
							<input id='checkMarkBtn' type='checkbox' onClick={handleAgreementChange} />
							<label htmlFor='checkMarkBtn'>
								<span className='position-relative'></span>
								<small>
									<>{translateIfPreferedLanguageIsSpanish(mainState.contact__preferred__language,'I Agree and Accept all ', 'Estoy de acuerdo y acepto todos los ')} </>
									<a href="https://uplyftcapital.com/terms-of-use/" target="_blank" rel="noopener noreferrer"><span>

										{translateIfPreferedLanguageIsSpanish(mainState.contact__preferred__language,'Uplyft Terms and Conditions', 'Términos y condiciones de Uplyft Capital')}
										</span>
									</a>
								</small>
							</label>
						</div>
						{ formError.terms &&
							<div className='d-flex justify-content-center' >
								<p style={{textAlign:'center', fontSize: '0.7rem'}} className='text-danger'><b>
									{translateIfPreferedLanguageIsSpanish(mainState.contact__preferred__language,'Please agree to terms and conditions', 'Por favor acepta los términos y condiciones')}
									</b>
								</p>
							</div>
						}
						<div id='clearBtn' className='btn-pos'><button type='button' className='btn btn-sm btn-clear' onClick={() => clear()}>
							{translateIfPreferedLanguageIsSpanish(mainState.contact__preferred__language,'Clear', 'Borrar')}
							</button></div>
					</div>
				</div>
			</div>
			<div id='controlBtn' className='container d-flex justify-content-between pt-2'>
				<Link to={`/onboarding/merchant/${params.accountUuid}/personalInfo/${params.contactUuid}/${params.opportunityUuid}`} className='btn btn-light display'>
					{translateIfPreferedLanguageIsSpanish(mainState.contact__preferred__language,'Back', 'Atras')}
				</Link>
				{/* Button id participate in GTM tracking! DO NOT CHANGE WITHOUT UPDATING GTM */}
				<button id='confirmSignBtn' type='submit' onClick={(e) => confirmSign(e)} className='btn btn-primary' disabled={disableButton}>
					{translateIfPreferedLanguageIsSpanish(mainState.contact__preferred__language,'Continue', 'Continuar')}
				</button>
			</div>
		</main>
	);

	return (
		serverError ? <ServerError error={serverError} /> :
			<>
				<Helmet>
					<title>Uplyft Capital Onboarding Merchant | Electronic-Signature</title>
				</Helmet>
				{ loading && <div className='spinner'><div className='loader'>submitting...</div></div> }
				<div style={ loading ? {position: 'relative', opacity: 0.3, zIndex: -10} : {} }>
					<StepProgressBar dots = {returnTrueIsSpanishIsPrefered(mainState.contact__preferred__language) ? dotsInSpanish : dots}/>
					<div>{electronicSignatureForm()}</div>
				</div>
			</>
	);
}
