import React, { Component } from 'react';
// import { connect } from 'react-redux';
// import { history } from '../../../store';
// import ContactSupportComponent from '../common/ContactSupportComponent';
import config from '../../../../config';
import axios from 'axios';

export default function ContractReview(props) {
// class ContractReview extends Component {
    // async componentDidMount() {
    // }

    const  handleSignContractSuccess = (e) => {
        e.preventDefault();
        // history.push('/funding/bank-verification/' + this.props.submissionUuid + '/' + this.props.contactSigningStatus.contactUuid);
        // axios.post(config.apiUrl + '/fundingPlatformPublic/generateOffer/merchantContractWasSigned', { 'submissionUuid': this.props.submissionUuid, 'contactUuid': this.props.contactSigningStatus.contactUuid });
    }

    const  handleSignContractError = (e) => {
        e.preventDefault();
        // props.handleSignContractError();
        // history.push('/funding/contract-review/' + this.props.submissionUuid + '/' + this.props.contactSigningStatus.contactUuid);
    }

    // const  controlButtons = () => (
    //     <div className='container d-flex justify-content-between pt-2'>
    //         <button className='btn btn-primary display' style={{ visibility: "hidden" }}></button>
    //         {(props.eventListener === 'Signed' || props.contactSigningStatus.status === 'Signed') ?
    //             <button type="button" className='btn btn-primary display' style={{ visibility: "visible" }} onClick={(e) => handleSignContractSuccess(e)}> Link Bank Account</button> :
    //             <button type="button" className='btn btn-primary display' style={{ visibility: "visible" }} onClick={(e) => handleSignContractError(e)}> Go Back</button>
    //         }
    //         {(props.eventListener === 'Signed' || props.contactSigningStatus.status === 'Signed') ?
    //             <button type="button" className='btn btn-primary isDone' style={{ visibility: "visible" }} onClick={(e) => handleSignContractSuccess(e)}> Link Bank Account</button> :
    //             <button type="button" className='btn btn-primary isDone' style={{ visibility: "visible" }} onClick={(e) => handleSignContractError(e)}> Go Back</button>
    //         }
    //     </div>
    // );

    const createMessage = (event) => {
        let message = {};
        switch (event) {
            case 'Signed': {
                message.title = 'Thanks';
                message.description = 'Please continue the funding process by linking your Financial account(s)';
                break;
            }
            case 'Canceled': {
                message.title = 'You Canceled the Signing process';
                message.description = 'Please complete it!';
                break;
            }
            case 'Error': {
                if (props.contactSigningStatus.status === 'Signed') {
                    message.title = 'You already signed this contract';
                    message.description = 'Please continue the funding process by linking your Financial account(s)';
                } else {
                    message.title = 'Oops, an error occurred';
                    message.description = 'Please Try again';
                }
                break;
            }

            default:
                message.title = 'Oops, an error occurred';
                message.description = 'Please Try again';
        }
        return message;
    }

    
        let message = createMessage(props.eventListener);
        return (
            <div className="layoutFading">
                <div className='shadow pt-5 pb-3 pr-5 pl-5 mb-2 bg-white roundCorners'>
                    <div className="form__header pb-3">
                        <h2 className="form__title is-done" style={{ opacity: 1 }}>{message.title}</h2>
                        <h3 className="form__subtitle is-done" style={{ opacity: 1 }}>{message.description}</h3>
                    </div>
                    {/* <div className='display'><ContactSupportComponent /></div> */}
                </div>
                {/* {controlButtons()} */}
                {/* <div className='isDone pt-3'><ContactSupportComponent /></div> */}
            </div>
        );
    
}

//PropTypes
// ContractReview.propTypes = {
// }

// const mapStateToProps = state => ({
//     contactsSigningStatus: state.signContract.contactsSigningStatus,
//     contactSigningStatus: state.signContract.contactSigningStatus,
//     eventListener: state.signContract.contactSigningStatus.eventListener
// });

// export default connect(mapStateToProps, {})(ContractReview);