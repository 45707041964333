import { createTypes } from 'reduxsauce'
 
export default createTypes(`
    GET_GENERATE_OFFER_INITIAL_DATA
    ON_FUNDING_AMOUNT_CHANGE
    ON_TERM_CHANGE
    ON_COMMISSION_CHANGE
    ON_PAYMENT_AMOUNT_CHANGE
    RESET_OFFER
    SELECT_OFFER
    GET_PROGRAM_RATES
    CALCULATE_PAYMENT_AMOUNT_RANGE
`, {}) // options - the 2nd parameter is optional