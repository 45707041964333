// actions.js
import { createActions } from 'reduxsauce';

const { Creators, Types } = createActions({
    getSignContractInitialData: ['initialData'],
    prepareContract: ['statuses'],
    onSigningEvent: ['contactsSigningStatus'],
    handleSignContractError: null,
});

export default { Creators, Types };