import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types'

import Slider, { createSliderWithTooltip } from 'rc-slider';

import { toCurrencyFormat } from '../../../lib/util';

const SliderWithTooltip = createSliderWithTooltip(Slider);

class CommissionComponent extends Component {

	constructor(props) {
		super(props);
		this.state = {
			commission: this.props.commission
			, paymentAmount: this.props.paymentAmount
			, minCommissionValue: 0
			, maxCommissionValue: 15
		};
	}

	componentDidUpdate() {
		if (this.state.paymentAmount !== this.props.paymentAmount) {
			this.setState({
				paymentAmount: this.props.paymentAmount
				, commission: this.props.commission
			});
		}
	}

	onChange = (e) => {
		let { value } = e.target;
		this.setState({
			commission: parseInt(value || 0)
		});
	}
	onBlur = (e, newOffer, commission) => {
		let { value, min, max } = e.target;
		value = Math.max(Number(min), Math.min(Number(max), Number(value)));
		this.setState({
			commission: parseInt(value || 0)
		});
		this.props.onCommissionChange(parseInt(value || 0), newOffer);
	}

	createMarks = () => {
		let marks = {};
		for (let i = this.state.minCommissionValue; i <= this.state.maxCommissionValue; i++) {
			marks[i] = <strong>{i + '%'}</strong>;
		}
		return marks;
	}

	render() {
		let these = this;

		return (
			<div className='container mb-5 mb-md-0'>
				<div className="row">
					<div className="form-group col-12 col-lg-3">
						<label> Commission</label>
						<div className='d-none d-lg-block'>
							<div className="input-group">
								<input type="text" min={this.state.minCommissionValue} max={this.state.maxCommissionValue} className="form-control" name="commission" value={this.state.commission} onChange={(e) => this.onChange(e)} onBlur={(e) => this.onBlur(e, this.props.newOffer, this.state.commission)} />
								<div className="input-group-append">
									<span className="input-group-text">%</span>
								</div>
							</div>
						</div>
					</div>
					<div className="col-12 col-lg-9 d-flex align-items-center">
						<SliderWithTooltip
							min={this.state.minCommissionValue}
							max={this.state.maxCommissionValue}
							defaultValue={this.state.commission}
							value={this.state.commission}
							step={1}
							marks={this.createMarks()}
							allowCross={true}
							trackStyle={{ backgroundColor: '#7d4fee' }}
							handleStyle={{ borderColor: '#e6e6ff', backgroundColor: '#927dfe', color: '#f2f' }}
							railStyle={{ backgroundColor: '#e6e6ff' }}
							dotStyle={{ borderColor: '#927dfe', backgroundColor: '#927dfe' }}
							tipFormatter={value => <div style={{ marginLeft: "5px", marginRight: "5px", color: "#fff" }}>{toCurrencyFormat(this.props.newOffer.fundingAmount * value / 100)}</div>}
							tipProps={{ visible: false }}
							onChange={function (value) {
								let newCommission = parseInt(value);
								these.setState({
									commission: newCommission
								});
							}}
							onAfterChange={function () {
								these.props.onCommissionChange(these.state.commission, these.props.newOffer);
							}}
						/>
					</div>
				</div>
			</div>
		);
	}
}

//PropTypes
CommissionComponent.propTypes = {
	commission: PropTypes.number.isRequired,
}

const mapStateToProps = state => ({
	newOffer: state.generateOffer.newOffer
	, commission: state.generateOffer.commission
	, paymentAmount: state.generateOffer.paymentAmount
});

export default connect(mapStateToProps, {})(CommissionComponent);