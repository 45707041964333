import React, { Component } from 'react';
class ContactSupportComponent extends Component {
    render() {
        return (
            <div className='row contact-support'>
                <div className = "container">
                    {/* <a href="tel:12396874286"><small>Click Here To Call Support 1-239-687-4286</small></a> */}
                </div>
            </div>
        );
    }
}

ContactSupportComponent.propTypes = {
};

export default ContactSupportComponent