import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { history } from '../../../store';
import { Helmet } from "react-helmet";
import './VerifyContract.css';
import FundingProgressBarComponent from '../common/FundingProgressBarComponent';
import HelloSignEmbeddedComponent from '../common/HelloSignEmbeddedComponent';
import verifyContractOperations from '../verifyContract/duck/operations'

// const _ = require('lodash');
class VerifyContractContainer extends Component {

	constructor(props) {
		super(props);
		this.state = {
			submissionUuid: ''
			, verifierUuid: ''
			, verifyContractStatus: ''
			, eventListener: ''
		};
	}
	async componentDidMount() {
		let submissionUuid = this.props.match.params.submissionUuid;
		let verifierUuid = this.props.match.params.verifierUuid
		await this.props.prepareContract(submissionUuid);
        this.setState({ submissionUuid: submissionUuid, verifierUuid: verifierUuid, verifyContractStatus: this.props.verifyContractStatus, eventListener: this.props.eventListener});
	}

	async componentDidUpdate() {
		if (this.state.eventListener !== this.props.eventListener) {
			history.push('/funding/verify-contract-done/' + this.state.submissionUuid + '/' + this.state.verifierUuid);
		}
	}

	render() {
		if (this.state.verifyContractStatus.signURL && this.props.eventListener === '') {
			let clientId = process.env.REACT_APP_SIGN_CONTRACT_CLIENT_ID_LEGACY;
			return (
				<>
					<Helmet>
                    	<title>Uplyft Capital Funding | Verify-Contract</title>
                	</Helmet>
					<div id='helloSign' className="layoutFading space">
						<FundingProgressBarComponent dots={[{ name: "Contract Verification", status: 1 }, { name: "Done", status: 0 }]} />
						<div className='container pt-2 space'>
							<div className='shadow p-5 mb-2 bg-white roundCorners'>
								<div id="signContract">
									<HelloSignEmbeddedComponent
										clientId={clientId}
										signatureId={this.state.verifyContractStatus.signatureId}
										url={this.state.verifyContractStatus.signURL.url}
										onSigningEvent={this.props.onVerificationEvent}
										signingEventParams={{ submissionUuid: this.state.submissionUuid}}
										uxVersion={2}
										inContainer={true}
									/>
								</div>
							</div>
						</div>
					</div>
				</>
			);
		} else {
			return (
				<div className='spinner'>
					<div className='loader'>Loading...</div>
				</div>
			);
		}
	}
}

VerifyContractContainer.propTypes = {
	offer: PropTypes.object
};

const mapStateToProps = state => ({
	verifyContractStatus: state.verifyContract.verifyContractStatus,
	eventListener: state.verifyContract.verifyContractStatus.eventListener || '',
	signingStatus: state.verifyContract.verifyContractStatus.status,
});

const mapDispatchToProps = dispatch => {
	const prepareContract = (submissionUuid) => dispatch(verifyContractOperations.prepareContract(submissionUuid));
	const onVerificationEvent = (params) => dispatch(verifyContractOperations.onVerificationEvent(params));
	return {
		prepareContract,
		onVerificationEvent,
	}
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyContractContainer);