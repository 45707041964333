import React, { useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import axios from 'axios'
import SignaturePad from "react-signature-canvas";
import "../Esign/eSign.style.css";
import config from '../../../../../config';
import { Helmet } from "react-helmet";

export default function SignPad({data, authorization}) {
    const { account, contact } = data
    const [error, setError] = useState({
		signature:false,
		terms: false
	});
    const [loading, setLoading] = useState(false);
    const [signatureData, setSignatureData] = useState({
        userSignature: '',
        termsAgreements: false,
    });
    const [redirect, setRedirect] = useState({ next: false, url: '' });
    const { termsAgreements } = signatureData;
    const params = useParams();

    let sigPad = {};

    const clear = () => {
        sigPad.clear();
        setSignatureData({ ...signatureData, userSignature: null });
        setError({...error, signature:false, terms: false });
    };

    const confirmSign = async e => {
        e.preventDefault();
        if (!termsAgreements) {
            setError({...error, terms:true });
        } else {
            // emptyString = catching empty signature data
            const emptyString = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAC0lEQVQYV2NgAAIAAAUAAarVyFEAAAAASUVORK5CYII='
            let userSignatureAsync = await sigPad.getTrimmedCanvas().toDataURL("image/svg + xml");
            setSignatureData({ ...signatureData, userSignature: userSignatureAsync });
            if (!userSignatureAsync || userSignatureAsync === emptyString ||userSignatureAsync.length < 5000) {
                setError({...error, signature:true });
            } else {
                setLoading(true)
                sendPostRequest(userSignatureAsync, termsAgreements)
            }
        }
    };

    const handleAgreementChange = () => {
        setError({...error, terms: false });
        setSignatureData({ ...signatureData, termsAgreements: !termsAgreements });
    };

    const electronicSignatureForm = () => (
        <main id='reviewSignature'>
            <div className='container'>
                <div className='shadow p-5 mb-3 bg-white roundCorners'>
                    <div>
						<h2 className='pb-3'>Please e-Sign Below</h2>
                    </div>
                    <div className='relativePos'>
                    <SignaturePad
							className='bg-light'
							canvasProps={{ className: "sigPad" }}
							ref={ref => {sigPad = ref;}}
							clearOnResize = {false}
							onEnd={() => {
								const imageData = sigPad.getTrimmedCanvas().toDataURL("image/svg + xml");
								setSignatureData({ ...signatureData, userSignature: imageData })
							  }}
						/>
                        { error.signature &&
							<div className='d-flex justify-content-center mb-2' >
								<p style={{textAlign:'center', fontSize: '0.7rem'}} className='text-danger'><b>We couldn't read your signature, it might be too short or empty. Please click "Clear" and try again.</b></p>
							</div>
						}
                       	<div className='d-flex justify-content-start' >
							<p style={{ fontSize: '0.7rem'}}>
								By signing above I, <b>{contact.firstName} {contact.lastName}</b>, acknowledge that I am the <b>{contact.ownership}%</b> owner of <b>{account.name}</b> and I'm personally liable for <b>{account.name}</b>.
							</p>
						</div>
						<div className='mt-2'>
							<p style={{ fontSize: '0.7rem'}}>I understand that by clicking on the I AGREE button immediately following this notice, I am providing ‘written instructions’ to Uplyft capital under the Fair Credit Reporting Act
							authorizing Uplyft capital to obtain information from your personal credit report or other information from Experian.
							I authorize Uplyft capital to obtain such information solely to conduct a pre-qualification for credit.</p>
						</div>
						<div id='checkBtn' className='d-flex justify-content-center'>
							<input id='checkMarkBtn' type='checkbox' onClick={handleAgreementChange} />
							<label htmlFor='checkMarkBtn'>
								<span className='position-relative'></span>
								<small>
									<>I Agree and Accept all </>
									<a href="https://uplyftcapital.com/terms-of-use/" target="_blank" rel="noopener noreferrer"><span>Uplyft Terms and Conditions</span></a>
								</small>
							</label>
						</div>
                        { error.terms &&
							<div className='d-flex justify-content-center' >
								<p style={{textAlign:'center', fontSize: '0.7rem'}} className='text-danger'><b>Please agree to terms and conditions</b></p>
							</div>
						}
                        <div className='btn-pos'><button className='btn btn-sm btn-clear' onClick={clear}>Clear</button></div>
                    </div>
                </div>
            </div>
            <div id='controlBtn' className='container d-flex justify-content-end pt-2'>
                <button onClick={confirmSign} className='btn btn-primary isDone'>Sign</button>
                <button onClick={confirmSign} className='btn btn-primary display'>Sign</button>
            </div>
        </main>
    );

    const sendPostRequest = (userSignatureAsync, termsAgreements) => {
        axios.defaults.withCredentials = true;
        axios.post(`${config.apiUrl}/api/uplyftOnboarding/index/${params.accountUuid}/${params.contactUuid}/${params.opportunityUuid}`, { signData: userSignatureAsync, termsAgreements, authorization })
            .then(res => {
                //console.log(res);
                setRedirect({
                    next: true,
                    //url:'/onboarding/merchant/finished'
                    url:`/onboarding/merchant/${res.data.accountUuid}/upload/${res.data.contactUuid}/${res.data.opportunityUuid}`
                });
            })
            .catch(error => { console.log('error', error) })
    };

    return (
        <>
            <Helmet>
                <title>Uplyft Capital Onboarding Merchant | Update-Signature</title>
            </Helmet>
            {redirect.next && <Redirect to={redirect.url} />}
            { loading && <div className='spinner'><div className='loader'>submitting...</div></div> }
            <div style={ loading ? {position: 'relative', opacity: 0.3, zIndex: -10} : {} }>
                <div>{electronicSignatureForm()}</div>
            </div>
        </>
    );
}
