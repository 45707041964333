import React, { Component } from 'react';
import FundingProgressBarComponent from './FundingProgressBarComponent';
import ContactSupportComponent from './ContactSupportComponent';

class ProcessDoneComponent extends Component {
    render() {
        return (
            <div className="layoutFading space">
                <FundingProgressBarComponent dots={[{ name: "Contract Review", status: 1 }, { name: "Sign Contract", status: 1 }, { name: "Bank Verification", status: 1 }, { name: "Upload Docs", status: 1 }, { name: "Done", status: 1 }]} />
                <div id="processDone" className='container'>
                    <div className='mb-5 relativePos'>
                        <div className='shadow pt-5 pb-3 pr-5 pl-5 mb-2 bg-white roundCorners'>
                            <div className="form__header pb-3">
                                <h2 className="form__title is-done" style={{ opacity: 1 }}>Thanks,</h2>
                                <h3 className="form__subtitle is-done" style={{ opacity: 1 }}>The process was completed successfully</h3>
                            </div>
                            <div className='display'><ContactSupportComponent /></div>
                            <div className='isDone pt-3'><ContactSupportComponent /></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

ProcessDoneComponent.propTypes = {
};

export default ProcessDoneComponent;