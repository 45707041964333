import React, { useState, createContext } from 'react'

export const StateContext = createContext();

export default function StateContextProvider(props) {

    const textareaMessage = `-How familiar are you with the MCA & Marketplace Lending?\n-Are you already syndicating and if so, how much monthly and with which funders?\n-What is your expected monthly output?`

    const [state, setState] = useState({
        contact__firstName: '',
        contact__lastName: '',
        contact__email: '',
        contact__mobilePhone: '',
        account__name: '',
        account__address__address1: '',
        account__address__address2: '',
        account__address__city: '',
        account__address__state: '',
        account__address__zip: '',
        account__message: textareaMessage

    })

    const handleChange = name => event => {
        const value = event.target.value;
        setState({ ...state, [name]: value });
    };

    return (
        <StateContext.Provider value={{ state, handleChange }}>
            {props.children}
        </StateContext.Provider>
    )
}