import React from 'react';
// import Layout from "./Layout";
import { Helmet } from "react-helmet";


export default function ServerError({ error }) {
    if (!error) {
        return;
    }
    return (
        <>
            <Helmet>
				<title>{`Uplyft Capital Onboarding Merchant | Error`}</title>
			</Helmet>
			<main className='container content layoutFading'>
				<div className='mt-5'>
						<div>
							<h2 className='pb-3'> {error.title || "We're Sorry..."} </h2>
						</div>
						<div className='shadow p-5 mb-2 bg-white roundCorners'>
                            {error instanceof window.Response ? (
                                <p>
                                    <b>{error.status}</b> on <b>{error.url}</b>
                                    <br />
                                    <small>{error.statusText}</small>
                                </p>
                            ) : (
                                <>
                                    {error.message ? <div>{error.message}</div> : <div>{error.toString()}</div>}
                                </>
                            )}
						</div>
				</div>
			</main>
        </>
    );
};
