import React, { Component } from 'react';

class GenerateOfferDoneComponent extends Component {
	render(){
        return (
            <div className= "layoutFading space">
                <div className='container'>
                    <div className='mb-5 relativePos'>
                        <div className='shadow p-5 mb-2 bg-white roundCorners'>
                            <div className="form__header">
                                <h2 className="form__title is-done" style={{opacity: 1}}>Thank you</h2>
                                <h3 className="form__subtitle is-done" style={{opacity: 1}}>Contract Sent!</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

GenerateOfferDoneComponent.propTypes = {
};

export default GenerateOfferDoneComponent;