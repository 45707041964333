import React, { Component } from 'react';
import { connect } from 'react-redux';
import { history } from '../../../store';
import { Helmet } from "react-helmet";
import FundingProgressBarComponent from '../common/FundingProgressBarComponent';
import ContactSupportComponent from '../common/ContactSupportComponent';
import UploadItemComponent from '../common/UploadItemComponent';
import uploadDocsOperations from './duck/operations'
import config from '../../../config/index';
import './UploadDocs.css';

class UploadDocsContainerbyOpp extends Component {

    constructor(props) {
        super(props);

        this.state = {
            contactUuid: ''
            , itemsProperties: []
            , allRequiredDocsUploaded: false
            , nextUrl: ''
        };
    }

    async componentDidMount() {
		const query = new URLSearchParams(this.props.location.search);
		let nexturl = query.get('next');
		if(nexturl) this.setState({nextUrl: nexturl});

        let itemsProperties = [
            { name: "Driver license", description: '', required: true, allowMultiple: false }
            , { name: "Voided Check", description: '', required: true, allowMultiple: false }
            , { name: "Proof of ownership", description: "(Ein Letter or Schedule C or K-1)", required: true, allowMultiple: false }
        ];
        let opportunityUuid = this.props.match.params.opportunityUuid;
        let contactUuid = this.props.match.params.contactUuid;
        await this.props.getUploadDocsInitialData(opportunityUuid);
        this.props.initializeRequiredDocs(itemsProperties);
        this.setState({
            contactUuid: contactUuid
            , itemsProperties: itemsProperties
        });
    }

    componentDidUpdate() {
        if (this.state.allRequiredDocsUploaded !== this.props.allRequiredDocsUploaded) {
            this.setState({
                allRequiredDocsUploaded: this.props.allRequiredDocsUploaded
            });
        }
    }

    handleOnClick() {
        this.props.sendVerifyContractNotification(this.props.match.params.opportunityUuid);
        if(this.state.nextUrl) history.push(this.state.nextUrl);
        else history.push('/funding/process-done');
    }

    handleCompleteLater() {
        this.props.sendUploadDocsNotification(this.props.match.params.opportunityUuid, this.state.contactUuid);
        if(this.state.nextUrl) history.push(this.state.nextUrl);
        else history.push('/funding/process-done');
    }

    controlButtons = () => (
        <>
            <div className='container d-flex justify-content-end controlBtnPos display fading'>
                {this.state.allRequiredDocsUploaded ? <button type="submit" className='btn btn-primary fadingOut ' onClick={(e) => this.handleOnClick(e)}> Finish </button> :
                    <div id='uploadLater' className='container d-flex justify-content-center justify-content-lg-end pt-2'>
                        <button type="submit" className='btn btn-clear fadingOut' onClick={(e) => this.handleCompleteLater(e)} >Complete later</button>
                    </div>
                }
            </div>
            <div className='isDone'>
                {this.state.allRequiredDocsUploaded ? <button type="submit" className='btn btn-primary fadingOut ' onClick={(e) => this.handleOnClick(e)}> Finish </button> :
                <div id='uploadLater' className='container d-flex justify-content-center justify-content-lg-end pt-2'>
                    <button type="submit" className='btn btn-clear fadingOut' onClick={(e) => this.handleCompleteLater(e)} >Complete later</button>
                </div>
                }
            </div>
        </>
    );

    render() {
        return (
            <>
                <Helmet>
                    <title>Uplyft Capital Funding | Upload-Docs</title>
                </Helmet>
                <div className="layoutFading space">
                    <div id="uploadDocs" className='container pt-3'>
                        <div className='mb-5 relativePos'>
                            <div className='shadow p-5 mb-2 bg-white roundCorners'>
                                <div>
                                    <h2 className='pb-3 page-title'> Required Docs </h2>
                                </div>
                                <div className=''>
                                    <div id='filePond' className="row">
                                        {this.state.itemsProperties.map((item, i) =>
                                            <div key={i} className='form-group col-sm-12 col-md-4'>
                                                <label className='pb-2'> {item.name}</label>
                                                <UploadItemComponent
                                                    itemName={item.name}
                                                    itemDescription={item.description}
                                                    url={config.apiUrl + '/fundingPlatformPublic/uploadDocs/uploadFile?itemName=' + item.name + '&accountUuid=' + this.props.account.uuid + '&contactUuid=' + this.state.contactUuid + '&opportunityUuid=' + this.props.opportunity.uuid}
                                                    onRemoveFile={this.props.removeFile} // function required
                                                    afterRemoveFile={this.props.updateRequiredDocsState} // function optional
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div className='display'><ContactSupportComponent /></div>
                                    {this.controlButtons()}
                                    <div className='isDone pt-3'><ContactSupportComponent /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

//PropTypes
UploadDocsContainerbyOpp.propTypes = {
}

const mapStateToProps = state => ({
    allRequiredDocsUploaded: state.uploadDocs.allRequiredDocsUploaded
    , opportunityUuid: state.uploadDocs.opportunityUuid
    , account: state.uploadDocs.account
    , opportunity: state.uploadDocs.opportunity
});

const mapDispatchToProps = dispatch => {
    const getUploadDocsInitialData = (opportunityUuid) => dispatch(uploadDocsOperations.getUploadDocsInitialData(opportunityUuid));
    const sendVerifyContractNotification = (opportunityUuid) => dispatch(uploadDocsOperations.sendVerifyContractNotification(opportunityUuid));
    const sendUploadDocsNotification = (opportunityUuid, contactUuid) => dispatch(uploadDocsOperations.sendUploadDocsNotification(opportunityUuid, contactUuid));
    const initializeRequiredDocs = (itemsProperties) => dispatch(uploadDocsOperations.initializeRequiredDocs(itemsProperties));
    const updateRequiredDocsState = (docKey, actionType) => dispatch(uploadDocsOperations.updateRequiredDocsState(docKey, actionType));

    const removeFile = (fileKey) => uploadDocsOperations.removeFile(fileKey);
    return {
        getUploadDocsInitialData,
        sendVerifyContractNotification,
        sendUploadDocsNotification,
        initializeRequiredDocs,
        updateRequiredDocsState,
        removeFile
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadDocsContainerbyOpp);