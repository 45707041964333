import axios from 'axios';
import actions from './actions';

import config from '../../../../config/index';

const getContractReviewInitialDataAction = actions.Creators.getContractReviewInitialData;

const getContractReviewInitialData = (submissionUuid, contactUuid) => async dispatch => {
	try {
	const response = await axios.get(config.apiUrl + '/fundingPlatformPublic/contractReview/getInitialData/'+ submissionUuid + '/' + contactUuid);
	let account = response.data.account;
	let contacts = response.data.contacts;
	let offer = response.data.offer;
	let contractStatus = response.data.contractStatus;
	let currentContactSigned = response.data.currentContactSigned;
    dispatch(getContractReviewInitialDataAction({submissionUuid, account, contacts, offer, contractStatus, currentContactSigned}));
	}
	catch(error) {
		dispatch(error);
	}
	return;
}

const updateEntities = async (entities, contactUuid) => {
	try {
		await axios.post(config.apiUrl + '/fundingPlatformPublic/contractReview/updateEntities', {entities, contactUuid});
		return;
	}
	catch(error) {
		console.log(error);
	}
	return;
}

export default {
    getContractReviewInitialData,
	updateEntities,
  }